<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium pb-1">
            Command Line Generator
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="text-h5 font-weight-medium pb-1">
            Download
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text--primary text-body-1">
            <p>Download the command line generator using the link below:</p>
            <p>
              <v-icon medium>mdi-download</v-icon>
              <a
                href="https://airhdl-cl.s3.eu-central-1.amazonaws.com/2023.07.1/airhdl-cl.jar"
              >
                airhdl-cl version 2023.07.1</a
              >
              <br />
              <v-icon medium>mdi-download</v-icon>
              <a
                href="https://airhdl-cl.s3.eu-central-1.amazonaws.com/airhdl-cl+Release+Notes.pdf"
                target="_blank"
              >
                airhdl-cl release notes</a
              >
              <br />
              <v-icon medium>mdi-download</v-icon>
              <a
                href="https://airhdl-cl.s3.eu-central-1.amazonaws.com/airhdl-cl-eula.pdf"
                target="_blank"
              >
                airhdl-cl end-user license agreement
              </a>
            </p>
            <p>
              Please refer to the
              <a
                href="/docs/user-guide/views/cl-view"
                target="_blank"
                >user guide</a
              >
              for instructions on how to use the command line generator.
            </p>
          </v-card-text>
          <v-card-title class="text-h5 font-weight-medium pb-1">
            Licenses
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-alert
            dense
            type="info"
            dismissible
            outlined
            v-if="user.activeLicenses >= user.maxActiveLicenses"
            class="mx-4 mt-4"
          >
            You have reached the maximum number of active licenses for the
            {{ subscriptionPlanMasked }} plan. You will not be able to create
            additional licenses before one of the active licenses has expired.
          </v-alert>
          <v-card-text>
            <v-data-table
              :headers="licenseTableHeaders"
              :items="licenses"
              class="elevation-1 text-body-1 text--primary"
              hide-default-footer
              :loading="licensesLoading"
              loading-text="Loading..."
              no-data-text="You haven't created any licenses yet."
              disable-pagination
            >
              <template v-slot:item.hardwareId="{ item }">
                <pre>{{ item.hardwareId }}</pre>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  dense
                  class="mr-2"
                  @click.stop="onDownloadLicense(item)"
                >
                  mdi-download
                </v-icon>
              </template>
              <template v-slot:item.expirationDate="{ item }">
                {{ formatDate(item.expirationDate) }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  class="ma-2"
                  color="success"
                  small
                  label
                  v-if="item.status === 'active'"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  class="ma-2"
                  small
                  label
                  v-if="item.status !== 'active'"
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="mt-4 mb-10 mx-2">
            <v-spacer></v-spacer>
            <v-tooltip bottom v-model="showNewLicenseTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  dark
                  fab
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="onNewLicense"
                  :disabled="user.activeLicenses >= user.maxActiveLicenses"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Create new License</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <LicenseDialog ref="licenseDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import LicenseDialog from '@/components/LicenseDialog.vue'
import MessageSnack from '@/components/MessageSnack.vue'
import { mapGetters } from 'vuex'

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/
  components: {
    LicenseDialog,
    MessageSnack,
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {
    ...mapGetters([
      'allowCommandLineGenerator',
      'user',
      'subscriptionPlanMasked',
    ]),
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      licensesLoading: false,
      licenses: [],
      showNewLicenseTooltip: false,

      message: {
        show: false,
        text: '',
      },

      licenseTableHeaders: [
        {
          text: 'Hardware ID',
          align: 'start',
          sortable: false,
          value: 'hardwareId',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '50%',
        },
        {
          text: 'Expiration Date',
          align: 'start',
          sortable: false,
          value: 'expirationDate',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em',
        },
      ],
    }
  },

  /************************************************************************************************
   * Created Hook
   ************************************************************************************************/
  created() {
    if (!this.allowCommandLineGenerator) {
      console.log(
        "Generator: user doesn't have access to command line generator; redirecting to dashboard..."
      )
      this.$router.push({ name: 'Dashboard' })
    } else {
      this.loadLicenses()
    }
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    onDownloadLicense(license) {
      api.generator
        .downloadLicense(license.id)
        .then(function (response) {
          // Instruct the browser to download the file
          const blob = new Blob([response], {
            type: 'application/octet-stream',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'license.xml'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    expirationDateComparator(a, b) {
      var d1 = new Date(a.expirationDate)
      var d2 = new Date(b.expirationDate)
      if (d1 < d2) {
        return 1
      } else if (d1 === d2) {
        return 0
      } else {
        return -1
      }
    },

    loadLicenses() {
      this.licensesLoading = true
      api.generator
        .getLicenses()
        .then((response) => {
          let sortedLicenses = response
          sortedLicenses.sort(this.expirationDateComparator)
          this.licenses = sortedLicenses
          this.licensesLoading = false
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.licensesLoading = false
          this.showErrorMessage(error.body.message)
        })
    },

    hideNewLicenseTooltip() {
      setTimeout(() => {
        this.showNewLicenseTooltip = false
      }, 0)
    },

    onNewLicense() {
      this.$refs.licenseDialog
        .open(null)
        .then((result) => {
          this.hideNewLicenseTooltip()
          api.generator
            .createLicense(result)
            .then(() => {
              this.loadLicenses()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
          this.hideNewLicenseTooltip()
        })
    },

    formatDate(timestamp) {
      var d = new Date(timestamp)
      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table-header {
  background-color: #f5f5f5;
}
</style>
