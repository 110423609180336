
export function passwordStrength(password) {
  // 1 lowercase, 1 uppercase, 1 digit, 1 special character, at least 8 characters long
  let strongPassword = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  )
  // 1 character, 1 digit, 1 special character, at least 8 characters long
  let mediumPassword = new RegExp(
    '((?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))'
  )

  if (strongPassword.test(password)) {
    return 'strong'
  } else if (mediumPassword.test(password)) {
    return 'medium'
  } else {
    return 'weak'
  }
}

export function passwordStrengthColor(password) {
  let strength = passwordStrength(password)
  if (strength === 'strong') {
    return 'success--text'
  } else if (strength === 'medium') {
    return 'warning--text'
  } else {
    return 'error--text'
  }
}
