<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h4 font-weight-medium pl-0">
              Linear-Feedback Shift Registers
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-divider class="mx-4 mb-4"></v-divider>
          <v-data-table
            :headers="lfsrTableHeaders"
            :items="lfsrs"
            :search="search"
            class="elevation-1 mx-4 mt-4 text-body-1 black--text"
            hide-default-footer
            :loading="lfsrsLoading"
            loading-text="Loading..."
            @click:row="onLfsrTableRowClick"
            no-data-text="You haven't created any LFSRs yet."
            show-select
            v-model="selectedLfsrs"
            disable-pagination
          >
            <!-- name column -->
            <template v-slot:item.name="{ item }">
              <span class="font-weight-medium">{{ item.name }}</span>
            </template>

            <template v-slot:item.description="{ item }">
              {{ shorten(item.description || '&ndash;') }}
            </template>

            <!-- lastModified column -->
            <!--
            <template v-slot:item.lastModified="{ item }">
              <span v-html="formatDate(item.lastModified)"></span>
            </template>
            -->

            <!-- Actions column -->
            <template v-slot:item.actions="{ item }">
              <v-icon dense class="mr-4" @click.stop="onEditLfsr(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                dense
                class="mr-4"
                @click.stop="onDuplicateLfsr(item)"
                :disabled="!allowNewLfsr"
              >
                mdi-content-copy
              </v-icon>
              <v-icon dense @click.stop="onDeleteLfsr(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>

          <v-card-actions class="mt-4 mb-10 mx-2">
            <v-btn
              small
              v-show="selectedLfsrs.length > 0"
              @click="onDeleteSelectedLfsrs"
              >Delete selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-tooltip v-model="showNewLfsrTooltip" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  dark
                  fab
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="onNewLfsr"
                  :disabled="!allowNewLfsr"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Create new LFSR</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirmationDialog" />
    <LfsrDialog ref="lfsrDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/global/utils.js'
import global from '@/global/index.js'
import api from '@/services/ApiService.js'
import settings from '@/json/settings.json'
import LfsrDialog from '@/components/LfsrDialog.vue'
import MessageSnack from '@/components/MessageSnack.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  /************************************************************************************************/
  components: {
    ConfirmDialog,
    LfsrDialog,
    MessageSnack,
  },
  /************************************************************************************************/
  data() {
    return {
      search: '',
      lfsrs: [],
      reloadTimer: null,
      lfsrsLoading: false,
      selectedLfsrs: [],
      showNewLfsrTooltip: false,

      lfsrTableHeaders: [
        /*
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },*/
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Length',
          align: 'start',
          sortable: false,
          value: 'length',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '40%',
        },
        /*
        {
          text: 'Last modified',
          align: 'start',
          sortable: false,
          value: 'lastModified',
        },*/
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em', // make sure icons don't wrap
        },
      ],

      message: {
        show: false,
        text: '',
      },

      defaultLfsr: {
        name: '',
        description: '',
        length: 4,
      },

      form: {
        disabled: true,
        lfsr: Object.assign({}, this.defaultLfsr),
      },
    }
  },
  /************************************************************************************************/
  created() {
    this.loadLfsrs(true)
    this.reloadTimer = setInterval(
      this.loadLfsrs,
      settings.autoReloadPeriodSec * 1000
    )
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************/
  methods: {
    shorten(description) {
      return utils.shorten(description, settings.maxDescriptionLength)
    },

    deleteLfsr(lfsr) {
      return api.lfsrs.deleteLfsr(lfsr.id).catch((error) => {
        if (error.status === 403) {
          this.$store.dispatch('logout')
        }
        this.showErrorMessage(error.body.message)
      })
    },

    async onDeleteSelectedLfsrs() {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the selected LFSR(s)? This action cannot be undone.`
        )
      ) {
        let results = []
        for (var i = 0; i < this.selectedLfsrs.length; i++) {
          results.push(this.deleteLfsr(this.selectedLfsrs[i]))
        }
        Promise.all(results)
          .then(() => {
            this.loadLfsrs(false)
          })
          .catch((error) => {
            this.showErrorMessage(error.body.message)
          })
        this.selectedLfsrs = []
      }
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    onLfsrTableRowClick(item) {
      this.$router.push({
        name: 'Lfsr',
        params: { lfsrId: item.id },
      })
    },

    formatDate(timestamp) {
      if (timestamp == null) {
        return '&ndash;'
      }
      var d = new Date(timestamp)
      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
    },

    loadLfsrs(showProgress) {
      if (showProgress) {
        this.lfsrsLoading = true
      }
      api.lfsrs
        .getLfsrs()
        .then((response) => {
          this.lfsrsLoading = false
          this.lfsrs = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.lfsrsLoading = false
          this.showErrorMessage(error.body.message)
        })
    },

    hideNewLfsrTooltip() {
      setTimeout(() => {
        this.showNewLfsrTooltip = false
      }, 0)
    },

    // Clicked the "New LFSR" dialog
    onNewLfsr() {
      this.$refs.lfsrDialog
        .open(null)
        .then((result) => {
          this.hideNewLfsrTooltip()
          api.lfsrs
            .createLfsr(result)
            .then(() => {
              this.loadLfsrs(false)
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
          this.hideNewLfsrTooltip()
        })
    },

    // Clicked the "edit LFSR" icon
    onEditLfsr(item) {
      this.$refs.lfsrDialog
        .open(item)
        .then((lfsr) => {
          // Transmit only the properties that can be modified in the dialog
          // NOTE: update onEditLfsr in Lfsr.vue if changing
          // anything in the DTO.
          var lfsrDto = {
            id: lfsr.id,
            name: lfsr.name,
            description: lfsr.description,
            length: lfsr.length,
          }
          api.lfsrs
            .saveLfsr(item.id, lfsrDto)
            .then(() => {
              this.loadLfsrs()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    // Clicked the "Duplicate LFSR icon"
    onDuplicateLfsr(item) {
      api.lfsrs
        .getLfsr(item.id)
        .then((response) => {
          var lfsrDto = response
          lfsrDto.name += ' - COPY'
          lfsrDto.permissions = [] // don't take over the permissions of the source LFSR
          api.lfsrs
            .createLfsr(lfsrDto)
            .then(() => {
              this.loadLfsrs(false)
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked on the "Delete LFSR" icon
    async onDeleteLfsr(item) {
      const message = `Are you sure you want to delete the LFSR <code>${item.name}</code>? This action cannot be undone.`
      if (await this.$refs.confirmationDialog.open('Confirm', message)) {
        api.lfsrs
          .deleteLfsr(item.id)
          .then(() => {
            this.loadLfsrs(false)
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },
  },
  /************************************************************************************************/
  computed: {
    ...mapGetters(['maxLfsrs', 'subscriptionPlanMasked']),

    objectNameRules() {
      return global.objectNameRules
    },

    lfsrDescriptionRules() {
      return global.lfsrDescriptionRules
    },

    allowNewLfsr() {
      //return this.ownLfsrsCount < this.maxLfsrs
      return true
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table-header {
  background-color: #f5f5f5;
}
</style>
