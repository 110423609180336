"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _LicenseDto = _interopRequireDefault(require("../model/LicenseDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* License service.
* @module api/LicenseApi
* @version 1.0.0
*/
class LicenseApi {
  /**
  * Constructs a new LicenseApi. 
  * @alias module:api/LicenseApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new license
   * @param {String} licenseToken 
   * @param {module:model/LicenseDto} licenseDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link String} and HTTP response
   */
  createLicenseByTokenWithHttpInfo(licenseToken, licenseDto) {
    let postBody = licenseDto;
    // verify the required parameter 'licenseToken' is set
    if (licenseToken === undefined || licenseToken === null) {
      throw new Error("Missing the required parameter 'licenseToken' when calling createLicenseByToken");
    }
    // verify the required parameter 'licenseDto' is set
    if (licenseDto === undefined || licenseDto === null) {
      throw new Error("Missing the required parameter 'licenseDto' when calling createLicenseByToken");
    }
    let pathParams = {
      'licenseToken': licenseToken
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['text/plain; charset=utf-8'];
    let returnType = 'String';
    return this.apiClient.callApi('/license/{licenseToken}', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new license
   * @param {String} licenseToken 
   * @param {module:model/LicenseDto} licenseDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link String}
   */
  createLicenseByToken(licenseToken, licenseDto) {
    return this.createLicenseByTokenWithHttpInfo(licenseToken, licenseDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * get a license by token
   * @param {String} licenseToken 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link String} and HTTP response
   */
  getLicenseByTokenWithHttpInfo(licenseToken) {
    let postBody = null;
    // verify the required parameter 'licenseToken' is set
    if (licenseToken === undefined || licenseToken === null) {
      throw new Error("Missing the required parameter 'licenseToken' when calling getLicenseByToken");
    }
    let pathParams = {
      'licenseToken': licenseToken
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['text/plain; charset=utf-8'];
    let returnType = 'String';
    return this.apiClient.callApi('/license/{licenseToken}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * get a license by token
   * @param {String} licenseToken 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link String}
   */
  getLicenseByToken(licenseToken) {
    return this.getLicenseByTokenWithHttpInfo(licenseToken).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = LicenseApi;