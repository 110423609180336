<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="800" class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            Terms and Conditions
          </v-card-title>
          <v-card-text class="body-1 text--primary">
            <div v-html="terms" class="md"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import txt from 'raw-loader!@/md/terms.md'
import pagedown from '@/libs/pagedown/Markdown.Converter.js'

export default {
  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      terms: '',
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {
    var converter = new pagedown.Converter()
    this.terms = converter.makeHtml(txt)
  },
}
</script>

<style>
h2 {
  margin-bottom: 0.5em;
}

</style>
