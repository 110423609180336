// Format date in YYYY-MM-DDDD format
export function formatDate(date) {
  var mm = date.getUTCMonth() + 1 // getMonth() is zero-based
  var dd = date.getUTCDate()
  return [
    date.getUTCFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
  ].join('-')
}

export function formatDateDDMMMYYYY(date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  var day = date.getUTCDate()
  var month = months[date.getUTCMonth()]
  var year = date.getUTCFullYear()
  return [day, month.toUpperCase(), year].join('-')
}
