<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="1024" class="mx-auto" elevation="0">
          <v-card-title>
            <span class="text-h4 font-weight-medium">Downloads</span>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>
            <span class="text-h5 font-weight-medium pb-1"
              >Command Line Generator</span
            >
          </v-card-title>
          <v-card-text class="text-body-1 black--text">
            <p>
              <v-icon medium>mdi-download</v-icon>
              <a
                href="https://airhdl-cl.s3.eu-central-1.amazonaws.com/2022.03.1/airhdl-cl.jar"
              >
                airhdl-cl version 2022.03.1</a
              >
              <br />
              <v-icon medium>mdi-download</v-icon>
              <a
                href="https://airhdl-cl.s3.eu-central-1.amazonaws.com/airhdl-cl+Release+Notes.pdf"
              >
                airhdl-cl release notes</a
              >
              <br />
              <v-icon medium>mdi-download</v-icon>
              <a
                href="https://airhdl-cl.s3.eu-central-1.amazonaws.com/airhdl-cl-eula.pdf"
                target="_blank"
              >
                airhdl-cl end-user license agreement
              </a>
            </p>
            <p>
              Please refer to the
              <a
                href="/docs/views/command_line_generator_view.html#using-the-command-line-generator"
                >user guide</a
              >
              for instructions on how to use the command line generator.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},

  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {}
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {},

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {},

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {},
}
</script>

<style></style>
