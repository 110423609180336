"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _HealthDto = _interopRequireDefault(require("../model/HealthDto"));
var _InfoDto = _interopRequireDefault(require("../model/InfoDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Actuator service.
* @module api/ActuatorApi
* @version 1.0.0
*/
class ActuatorApi {
  /**
  * Constructs a new ActuatorApi. 
  * @alias module:api/ActuatorApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Get application health
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/HealthDto} and HTTP response
   */
  getApplicationHealthWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _HealthDto.default;
    return this.apiClient.callApi('/actuator/health', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get application health
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/HealthDto}
   */
  getApplicationHealth() {
    return this.getApplicationHealthWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get application information
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InfoDto} and HTTP response
   */
  getApplicationInfoWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _InfoDto.default;
    return this.apiClient.callApi('/actuator/info', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get application information
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InfoDto}
   */
  getApplicationInfo() {
    return this.getApplicationInfoWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = ActuatorApi;