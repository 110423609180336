<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium pb-1">
            Admin
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="text-h5 font-weight-medium pb-2">
            Users<v-spacer></v-spacer>
            <v-text-field
              autocomplete="off"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="mt-4">
            <v-data-table
              dense
              :headers="headers"
              :items="users"
              :loading="usersLoading"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:item.planExpirationDate="{ item }">
                {{ formatDate(item.planExpirationDate) }}
              </template>
              <template v-slot:item.enabled="{ item }">
                <v-switch dense v-model="item.enabled" disabled></v-switch>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon dense class="mr-2" @click.stop="onEditUser(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MessageSnack v-model="message.show" :text="message.text" />
    <UserDialog ref="userDialog" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import * as dh from '@/helpers/dateHelpers.js'
import MessageSnack from '@/components/MessageSnack.vue'
import UserDialog from '@/components/UserDialog.vue'

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/
  components: {
    MessageSnack,
    UserDialog,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      usersLoading: false,
      search: '',

      message: {
        show: false,
        text: '',
      },

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Email', value: 'username' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Plan', value: 'plan' },
        { text: 'Expires', value: 'planExpirationDate' },
        { text: 'Last Login', value: 'lastLogin' },
        { text: 'Enabled', value: 'enabled' },
        { text: 'Actions', value: 'actions' },
      ],

      users: [],
    }
  },

  /************************************************************************************************
   * Created Hook
   ************************************************************************************************/
  created() {
    this.loadUsers()
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    loadUsers() {
      this.usersLoading = true
      api.users
        .getUsers()
        .then((response) => {
          this.users = response
          this.usersLoading = false
        })
        .catch((error) => {
          this.usersLoading = false
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    onEditUser(user) {
      this.$refs.userDialog
        .open(user)
        .then((editedUser) => {
          api.admin
            .adminSaveUser(editedUser.id, editedUser)
            .then(() => {
              this.loadUsers()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {})
    },

    formatDate(date) {
      return dh.formatDate(new Date(date))
    },
  },
}
</script>

<style>
div.v-data-table__wrapper table thead th {
  font-size: 1rem !important;
}
div.v-data-table__wrapper table tbody td {
  font-size: 1rem !important;
}
</style>
