<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-breadcrumbs
            :items="breadcrumbs"
            divider="/"
            class="pl-4 py-2 my-4"
            large
          ></v-breadcrumbs>

          <v-alert dense type="info" elevation="0" outlined v-if="isReadOnly">
            The owner has granted you permission to
            <strong>view</strong> this register map.
          </v-alert>

          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h4 font-weight-medium pl-0">
              <!-- ******************************************************************************* -->
              <!-- Name -->
              <!-- ******************************************************************************* -->
              <div>
                <span class="mr-4">{{ register.name }}</span
                ><span class="text-h5">{{ types[register.type] }}</span>
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- ******************************************************************************* -->
            <!-- Settings menu -->
            <!-- ******************************************************************************* -->
            <v-menu offset-y close-on-click v-model="showSettingsMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="elevation-0" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <template v-for="(item, index) in settingsMenuItems">
                    <v-divider
                      v-if="index === 1"
                      :key="index + 100"
                    ></v-divider>
                    <v-list-item
                      :key="index"
                      :value="item"
                      @click.stop="item.action"
                      :disabled="isReadOnly"
                    >
                      <template v-slot:default>
                        <v-list-item-content v-text="item.title">
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-app-bar>
          <v-divider class="mx-4"></v-divider>
          <!-- ******************************************************************************* -->
          <!-- Register properties -->
          <!-- ******************************************************************************* -->
          <v-card-text class="text-body-1 black--text mt-4">
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Description</v-col
              >
              <v-col cols="9" class="text-left"
                ><span>{{ register.description || '&ndash;' }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Address offset</v-col
              >
              <v-col cols="9" class="text-left"
                ><span>0x{{ register.addressOffset.toString(16) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Size</v-col>
              <v-col cols="9" class="text-left">{{ size }} bytes</v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Access</v-col>
              <v-col cols="9" class="text-left">{{ register.access }}</v-col>
            </v-row>
            <v-row v-if="register.type === 'Memory'">
              <v-col cols="3" class="text-left font-weight-bold">Depth</v-col>
              <v-col cols="9" class="text-left"
                >{{ register.depth }} element(s)</v-col
              >
            </v-row>
            <v-row v-if="register.type === 'Memory'">
              <v-col cols="3" class="text-left font-weight-bold"
                >Read latency</v-col
              >
              <v-col cols="9" class="text-left"
                >{{ register.readLatency }} cycles</v-col
              >
            </v-row>
            <v-row v-if="register.type === 'RegisterArray'">
              <v-col cols="3" class="text-left font-weight-bold"
                >Array length</v-col
              >
              <v-col cols="9" class="text-left"
                >{{ register.arrayLength }} element(s)</v-col
              >
            </v-row>
            <v-row v-if="codeGenerationOptions.length > 0">
              <v-col cols="3" class="text-left font-weight-bold"
                >Code generation options</v-col>
              <v-col cols="9" class="text-left">
                <v-chip
                label
                small
                class="mr-2"
                v-for="option in codeGenerationOptions"
                :key="option"
                >{{ option }}</v-chip>
              </v-col>
            </v-row>

          </v-card-text>

          <!-- ******************************************************************************* -->
          <!-- Overview -->
          <!-- ******************************************************************************* -->
          <v-card-title>
            <span class="text-h5 font-weight-medium pb-1">Overview</span>
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="onRegisterCalculator"
                  ><v-icon>mdi-calculator</v-icon></v-btn
                >
              </template>
              <span>Open the register calculator</span>
            </v-tooltip>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text v-html="svg" id="svg"></v-card-text>

          <!-- ******************************************************************************* -->
          <!-- Fields -->
          <!-- ******************************************************************************* -->
          <v-card-title class="text-h5 font-weight-medium pb-1">
            Fields
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-data-table
            :headers="fieldTableHeaders"
            :items="register.fields"
            class="elevation-1 mx-4 mt-4 text-body-1 black--text"
            hide-default-footer
            :loading="fieldsLoading"
            loading-text="Loading..."
            show-select
            v-model="selectedFields"
            disable-pagination
            no-data-text="This register does not contain any fields yet."
          >
            <!-- Name column -->
            <template v-slot:item.name="{ item }">
              <span class="font-weight-medium">{{ item.name }}</span>
            </template>
            <!-- Reset column -->
            <template v-slot:item.reset="{ item }">
              <pre>0x{{ item.reset.toString(16).toUpperCase() }}</pre>
            </template>
            <!-- Description column (render Markdown) -->
            <template v-slot:item.description="{ item }">
              <div
                class="markdown"
                v-html="htmlFromMarkdown(item.description)"
              ></div>
            </template>
            <!-- Values column -->
            <template v-slot:item.enumValues="{ item }">
              <v-chip
                label
                small
                class="mr-2"
                v-for="enumValue in item.enumValues"
                :key="enumValue.id"
                >{{ enumValue.name + ' = ' + enumValue.value }}</v-chip
              >
            </template>
            <!-- Options column -->
            <template v-slot:item.options="{ item }">
              <v-chip label small class="mr-2" v-if="item.selfClear"
                >self-clearing</v-chip
              >
              <v-chip label small class="mr-2" v-if="item.volatile"
                >volatile</v-chip
              >
            </template>
            <!-- Actions column -->
            <template v-slot:item.actions="{ item }">
              <v-icon
                dense
                class="mr-3"
                @click.stop="onEditField(item)"
                :disabled="isReadOnly"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                dense
                class="mr-3"
                @click.stop="onDuplicateField(item)"
                :disabled="isReadOnly"
              >
                mdi-content-copy
              </v-icon>
              <v-icon
                dense
                @click.stop="onDeleteField(item)"
                :disabled="isReadOnly"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>

          <v-card-actions class="mt-4 mb-10 mx-2">
            <v-btn
              small
              v-show="selectedFields.length > 0"
              @click="onDeleteSelectedFields"
              >Delete selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom v-model="showNewFieldTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  fab
                  dark
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="onNewField"
                  :disabled="isReadOnly"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Create new Field</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirmationDialog" />
    <FieldDialog ref="fieldDialog" />
    <RegisterDialog ref="registerDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
    <Calculator ref="calculator" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import global from '@/global/index.js'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import FieldDialog from '@/components/FieldDialog.vue'
import RegisterDialog from '@/components/RegisterDialog.vue'
import MessageSnack from '@/components/MessageSnack.vue'
import Calculator from '@/components/Calculator.vue'
import bitfield from '@/libs/bitfield/render.js'
import pagedown from '@/libs/pagedown/Markdown.Converter.js'
import settings from '@/json/settings.json'

var onml = require('onml')

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/

  components: {
    ConfirmDialog,
    FieldDialog,
    RegisterDialog,
    MessageSnack,
    Calculator,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data() {
    return {
      svg: '',
      registerId: 0,
      breadcrumbs: [],
      registerMapId: 0,
      registerMap: null,
      reloadTimer: null,
      selectedFields: [],
      fieldsLoading: false,
      registerLoading: false,
      showSettingsMenu: false,
      showNewFieldTooltip: false,

      message: {
        show: false,
        text: '',
      },

      types: {
        Register: 'register',
        RegisterArray: 'array',
        Memory: 'memory',
      },

      settingsMenuItems: [
        { title: 'Edit Register', action: this.editRegister },
        { title: 'Delete Register', action: this.deleteRegister },
      ],

      register: {
        access: 'READ_WRITE',
        addressOffset: 0,
        arrayLength: 1,
        depth: 1,
        description: '',
        fields: [],
        id: -1,
        name: '',
        readLatency: 1,
        registerMapId: -1,
        type: 'Register',
      },

      fieldTableHeaders: [
        /*
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },*/
        {
          text: 'Offset',
          align: 'start',
          sortable: false,
          value: 'bitOffset',
        },
        {
          text: 'Width',
          align: 'start',
          sortable: false,
          value: 'bitWidth',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '10em',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '100%',
        },
        {
          text: 'Values',
          align: 'start',
          sortable: false,
          value: 'enumValues',
        },
        {
          text: 'Options',
          align: 'start',
          sortable: false,
          value: 'options',
        },
        {
          text: 'Reset',
          align: 'end',
          sortable: false,
          value: 'reset',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em', // make sure icons don't wrap
        },
      ],
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/

  created() {
    this.registerMapId = this.$route.params.registerMapId
    this.registerId = this.$route.params.registerId
    this.loadRegister()
    this.reloadTimer = setInterval(
      this.loadRegister,
      settings.autoReloadPeriodSec * 1000
    )
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************
   * Watches
   ************************************************************************************************/

  watch: {
    registerMap() {
      if (this.registerMap != null && this.register != null) {
        this.initBreadCrumbs()
      }
    },

    register() {
      if (this.registerMap != null && this.register != null) {
        this.initBreadCrumbs()
      }
    },

    fields() {
      if(this.registerMap != null && this.fields != null) {
        var reg = []
        var bitOffset = 0
        for (var i = 0; i < this.fields.length; i++) {
          var field = this.fields[i]
          if (field.bitOffset > bitOffset) {
            var fillLength = field.bitOffset - bitOffset
            reg.push({
              bits: fillLength,
            })
            bitOffset += fillLength
          }
          reg.push({
            bits: field.bitWidth,
            name: field.name,
          })
          bitOffset += field.bitWidth
        }
        if (bitOffset < this.registerMap.width) {
          reg.push({
            bits: this.registerMap.width - bitOffset,
          })
        }

        const width = document.getElementById('svg').offsetWidth - 40
        // console.log('svg width is ' + width)

        var options = {
          bits: 32,
          lanes: 2,
          hspace: width,
          //hspace: 0,
          vspace: 80,
          //fontsize: 14,
        }
        var jsonml = bitfield.render(reg, options)
        this.svg = onml.stringify(jsonml)
      }
    },
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/

  computed: {
    isReadOnly() {
      if (this.registerMap != null) {
        return this.registerMap.permission === 'READ'
      }
      return false
    },

    nextBitOffset() {
      return global.nextBitOffset(this.register.fields, this.registerMap.width)
    },

    registerDescriptionRules() {
      return global.registerDescriptionRules
    },

    addressOffsetRules() {
      return global.addressOffsetRules
    },

    objectNameRules() {
      return global.objectNameRules
    },

    size() {
      if (this.registerMap != null && this.register != null) {
        return global.registerSizeBytes(this.register, this.registerMap.width)
      }
      return 0
    },

    fields() {
      return this.register.fields
    },

    codeGenerationOptions() {
      let result = []
      if(this.register.type === 'RegisterArray' && this.register.genericArrayLength) {
        result.push("generic-length")
      } 
      return result
    }

  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/

  methods: {
    onRegisterCalculator() {
      // Add a 'value' property to every field
      for (var i = 0; i < this.register.fields.length; i++) {
        this.register.fields[i].value = '0'
      }
      this.$refs.calculator.open(this.register)
    },

    loadFields(showProgress) {
      if (showProgress) {
        this.fieldsLoading = true
      }
      api.registerMaps
        .getFields(this.registerMapId, this.registerId)
        .then((response) => {
          this.fieldsLoading = false
          this.register.fields = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.fieldsLoading = false
          this.showErrorMessage(error.body.message)
        })
    },

    // Delete a single field
    deleteField(field) {
      return api.registerMaps
        .deleteField(this.registerMapId, this.registerId, field.id)
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    onDeleteSelectedFields() {
      let results = []
      for (var i = 0; i < this.selectedFields.length; i++) {
        results.push(this.deleteField(this.selectedFields[i]))
      }
      Promise.all(results)
        .then(() => {
          this.loadFields(false)
        })
        .catch((error) => {
          this.showErrorMessage(error.body.message)
        })
      this.selectedFields = []
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    htmlFromMarkdown(str) {
      if (str == null || str === '') {
        return '&ndash;'
      }
      var converter = new pagedown.Converter()
      var html = converter.makeHtml(str)
      return html
    },

    // Clicked the "edit field" icon
    onEditField(field) {
      this.$refs.fieldDialog
        .open(-1, field, this.register)
        .then((result) => {
          return api.registerMaps
            .saveField(this.registerMapId, this.registerId, field.id, result)
            .then(() => {
              this.loadRegister()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    // Clicked the "delete field" dialog
    onDeleteField(field) {
      api.registerMaps
        .deleteField(this.registerMapId, this.registerId, field.id)
        .then(() => {
          this.loadRegister()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked the "duplicate field" dialog
    onDuplicateField(field) {
      var newField = JSON.parse(JSON.stringify(field)) // deep copy including enumValues
      newField.name += ' - COPY'
      var newFields = [newField]
      api.registerMaps
        .createFields(this.registerMapId, this.registerId, newFields)
        .then(() => {
          this.loadRegister()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked "Edit Register" in the settings menu
    editRegister() {
      this.$refs.registerDialog
        .open(-1, this.register)
        .then((result) => {
          return api.registerMaps
            .saveRegister(this.registerMapId, this.register.id, result)
            .then(() => {
              this.loadRegister()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    initBreadCrumbs() {
      this.breadcrumbs = [
        {
          text: 'Register Maps',
          to: { name: 'RegisterMaps' },
          exact: true,
        },
        {
          text: this.registerMap.name,
          to: {
            name: 'RegisterMap',
            params: { registerMapId: this.registerMapId },
          },
          exact: true,
        },
        {
          text: this.register.name,
          exact: true,
        },
      ]
    },

    // Returns the dimension string for an array or a memory, e.g. '[10]'
    dimension(item) {
      if (item.type === 'RegisterArray') {
        return ` [${item.arrayLength}]`
      } else if (item.type === 'Memory') {
        return ` [${item.depth}]`
      }
      return ''
    },

    // Returns the type string for a register map element
    type(item) {
      if (item.type === 'RegisterArray') {
        return 'Array'
      }
      return item.type
    },

    loadRegister() {
      // console.log('register: load')
      this.registerLoading = true

      // Get register map (need its width property, and its permission for this user)
      api.registerMaps
        .getRegisterMap(this.registerMapId)
        .then((response) => {
          this.registerMap = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          } else if (error.status === 400) {
            // access denied
            this.$router.push({ name: 'Dashboard' })
          }
          this.showErrorMessage(error.body.message)
        })

      // Get register and fields
      var opts = { includeFields: true }
      api.registerMaps
        .getRegister(this.registerMapId, this.registerId, opts)
        .then((response) => {
          this.registerLoading = false
          this.register = response
          // console.log(this.register)
          // console.log(this.register.fields)
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          } else if (error.status === 400) {
            // access denied
            this.$router.push({ name: 'Dashboard' })
          }
          this.registerLoading = false
          this.showErrorMessage(error.body.message)
        })
    },

    hideNewFieldTooltip() {
      setTimeout(() => {
        this.showNewFieldTooltip = false
      }, 0)
    },

    // Clicked the "Create new field" button
    onNewField() {
      this.$refs.fieldDialog
        .open(this.nextBitOffset, null, this.register)
        .then((field) => {
          let newFields = []
          const match = field.name.match(global.newFieldPattern)
          if (match[3] && match[4]) {
            // Field name contains replication range like done[0:7]
            // * match[1] is the field base name, e.g. "done"
            // * match[3] is the low index, e.g. "0"
            // * match[4] is the high index, e.g. "7"
            const low = parseInt(match[3])
            const high = parseInt(match[4])
            let bitOffset = field.bitOffset
            for (let i = low; i <= high; i++) {
              let newField = JSON.parse(JSON.stringify(field)) // make deep copy, including enum values
              newField.bitOffset = bitOffset
              newField.name = `${match[1]}${i}`
              newFields.push(newField)
              bitOffset += newField.bitWidth
            }
          } else {
            // Field name does not contain replication range -> create single field
            newFields.push(field)
          }
          return api.registerMaps.createFields(
            this.registerMapId,
            this.registerId,
            newFields
          )
        })
        .catch(() => {
          /* Cancel */
        })
        .then(() => {
          this.loadRegister()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
        .finally(() => this.hideNewFieldTooltip())
    },

    async deleteRegister() {
      this.showSettingsMenu = false
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the register <code>${this.register.name}</code>? This action cannot be undone.`
        )
      ) {
        api.registerMaps
          .deleteRegister(this.registerMapId, this.registerId)
          .then(() => {
            this.$router.push({
              name: 'RegisterMap',
              params: { registerMapId: this.registerMapId },
            })
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table-header {
  background-color: #f5f5f5;
}
</style>

<style>
.col {
  padding-top: 4px;
  padding-bottom: 4px;
}

div.markdown p {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
