"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ClientErrorForbidden model module.
 * @module model/ClientErrorForbidden
 * @version 1.0.0
 */
class ClientErrorForbidden {
  /**
   * Constructs a new <code>ClientErrorForbidden</code>.
   * @alias module:model/ClientErrorForbidden
   */
  constructor() {
    ClientErrorForbidden.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>ClientErrorForbidden</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClientErrorForbidden} obj Optional instance to populate.
   * @return {module:model/ClientErrorForbidden} The populated <code>ClientErrorForbidden</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClientErrorForbidden();
      if (data.hasOwnProperty('timestamp')) {
        obj['timestamp'] = _ApiClient.default.convertToType(data['timestamp'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'Number');
      }
      if (data.hasOwnProperty('error')) {
        obj['error'] = _ApiClient.default.convertToType(data['error'], 'String');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = _ApiClient.default.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('path')) {
        obj['path'] = _ApiClient.default.convertToType(data['path'], 'String');
      }
    }
    return obj;
  }
}

/**
 * @member {String} timestamp
 */
ClientErrorForbidden.prototype['timestamp'] = undefined;

/**
 * @member {Number} status
 */
ClientErrorForbidden.prototype['status'] = undefined;

/**
 * @member {String} error
 */
ClientErrorForbidden.prototype['error'] = undefined;

/**
 * @member {String} message
 */
ClientErrorForbidden.prototype['message'] = undefined;

/**
 * @member {String} path
 */
ClientErrorForbidden.prototype['path'] = undefined;
var _default = ClientErrorForbidden;
exports.default = _default;