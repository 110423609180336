<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-breadcrumbs
            :items="breadcrumbs"
            divider="/"
            class="pl-4 py-2 my-4"
            large
          ></v-breadcrumbs>

          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h4 font-weight-medium pl-0">
              <!-- ******************************************************************************* -->
              <!-- Name -->
              <!-- ******************************************************************************* -->
              <div>
                <span class="mr-2">{{ lfsr.name }}</span>
                <span class="text-h5">linear-feedback shift register</span>
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- ******************************************************************************* -->
            <!-- Download menu -->
            <!-- ******************************************************************************* -->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  class="white--text mr-4"
                  v-bind="attrs"
                  v-on="on"
                  medium
                >
                  <v-icon left> mdi-cloud-download </v-icon>
                  Download
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <template v-for="(item, index) in downloadMenuItems">
                    <v-divider
                      v-if="index === 7"
                      :key="index + 100"
                    ></v-divider>
                    <v-list-item
                      :key="index"
                      :value="item"
                      @click="generate(item.type)"
                      :disabled="!isDownloadAllowed(item)"
                    >
                      <template v-slot:default>
                        <v-list-item-content v-text="item.title">
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <!-- ******************************************************************************* -->
            <!-- Settings menu -->
            <!-- ******************************************************************************* -->
            <v-menu offset-y close-on-click v-model="showSettingsMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="elevation-0" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <template v-for="(item, index) in settingsMenuItems">
                    <v-divider
                      v-if="index === 1"
                      :key="index + 100"
                    ></v-divider>
                    <v-list-item
                      :key="index"
                      :value="item"
                      @click.stop="item.action"
                    >
                      <template v-slot:default>
                        <v-list-item-content v-text="item.title">
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-app-bar>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text-body-1 black--text mt-4">
            <!-- ******************************************************************************* -->
            <!-- Description -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Description</v-col
              >
              <v-col cols="9" class="text-left"
                ><span>{{ lfsr.description || '&ndash;' }}</span>
              </v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Length -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Length</v-col>
              <v-col cols="9" class="text-left">{{ lfsr.length }} bits</v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Taps -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Taps</v-col>
              <v-col cols="9" class="text-left">{{
                lfsr.taps.join(', ')
              }}</v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Feedback -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Feedback type</v-col
              >
              <v-col cols="9" class="text-left"
                ><code>{{ lfsr.feedback }}</code></v-col
              >
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Initial value -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Initial value</v-col
              >
              <v-col cols="9" class="text-left">{{ initialValue(lfsr) }}</v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Period -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Period</v-col>
              <v-col cols="9" class="text-left"
                >{{ numberWithCommas(lfsr.period) }} cycles</v-col
              >
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Schematic -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Schematic</v-col
              >
              <v-col cols="9" class="text-left"
                ><v-card-text v-html="svg" id="svg" class="pl-0"></v-card-text
              ></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirmationDialog" />
    <LfsrDialog ref="lfsrDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import axios from 'axios'
import global from '@/global/index.js'
import api from '@/services/ApiService.js'
import MessageSnack from '@/components/MessageSnack.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import LfsrDialog from '@/components/LfsrDialog.vue'
import settings from '@/json/settings.json'
import render from '@/libs/lfsr/lfsr.js'

export default {
  /************************************************************************************************/
  components: {
    ConfirmDialog,
    LfsrDialog,
    MessageSnack,
  },
  /************************************************************************************************/
  data() {
    return {
      svg: '',
      showSettingsMenu: false,
      lfsrLoading: false,
      lfsrId: 0,
      timer: null,

      message: {
        show: false,
        text: '',
      },

      settingsMenuItems: [
        {
          name: 'editLfsr',
          title: 'Edit LFSR',
          action: this.onEditLfsr,
        },
        {
          name: 'deleteLfsr',
          title: 'Delete LFSR',
          action: this.deleteLfsr,
        },
      ],

      downloadMenuItems: [
        { title: 'C Model', type: 'cModel' },
        { title: 'Python Model', type: 'pyModel' },
        { title: 'SystemVerilog Package', type: 'svPackage' },
        { title: 'SystemVerilog Module', type: 'svModule' },
        { title: 'VHDL Package', type: 'vhdlPackage' },
        { title: 'VHDL Component', type: 'vhdlComponent' },
        { title: 'JSON', type: 'json' },
        { title: 'All Files', type: 'all' },
      ],

      lfsr: {
        id: 0,
        name: '',
        description: '',
        length: 0,
        taps: [],
        feedback: '',
      },

      lfsrTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '10em',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '100%',
        },
        {
          text: 'Length',
          align: 'start',
          sortable: false,
          value: 'length',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em', // make sure icons don't wrap
        },
      ],

      breadcrumbs: [
        {
          text: 'LFSRs',
          disabled: false,
          to: { name: 'LFSRs' },
        },
        {
          text: 'LFSR',
          disabled: false,
        },
      ],
    }
  },

  /************************************************************************************************/
  created() {
    // REVIEW: handle missing LFSR
    this.lfsrId = this.$route.params.lfsrId
    this.loadLfsr()
    this.reloadTimer = setInterval(
      this.onReloadTimer,
      settings.autoReloadPeriodSec * 1000
    )
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************
   * Watches
   ************************************************************************************************/

  watch: {
    lfsr: function () {
      this.initBreadCrumbs()
      this.svg = render(this.lfsr)
    },
  },

  /************************************************************************************************/
  computed: {
    lfsrDescriptionRules() {
      return global.lfsrDescriptionRules
    },

    baseAddressRules() {
      return global.baseAddressRules
    },

    objectNameRules() {
      return global.objectNameRules
    },
  },

  /************************************************************************************************/
  methods: {
    initBreadCrumbs() {
      this.breadcrumbs = [
        {
          text: 'LFSRs',
          to: { name: 'LFSRs' },
          exact: true,
        },
        {
          text: this.lfsr.name,
          to: {
            name: 'Lfsr',
            params: { lfsrId: this.lfsrId },
          },
          exact: true,
        },
      ]
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    // Clicked settings -> edit LFSR
    onEditLfsr() {
      this.$refs.lfsrDialog
        .open(this.lfsr)
        .then((lfsr) => {
          // Transmit only the properties that can be modified in the dialog
          // NOTE: update oneditLfsr in RegisterMaps.vue if changing
          // anything in the DTO.
          const lfsrDto = {
            id: lfsr.id,
            name: lfsr.name,
            description: lfsr.description,
            length: lfsr.length,
            feedback: lfsr.feedback,
          }
          api.lfsrs
            .saveLfsr(this.lfsrId, lfsrDto)
            .then(() => {
              this.loadLfsr()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    // Download a generated file of a given type (e.g. 'cCode')
    generate(type) {
      if (type === 'all') {
        // Use axios to download binary files as OpenAPI Generator client does not seem to work with them
        const token = api.getJWT()
        const basePath = api.getBasePath() // e.g. http://localhost:8082/api
        const config = {
          // Note: when adding application/json, the response.data becomes a string and the downloaded zip-file is corrupted.
          //responseType: 'arraybuffer; application/json',
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        axios
          .get(`${basePath}/lfsrs/${this.lfsrId}/download?type=all`, config)
          .then((response) => {
            // Extract filename from content-disposition header
            const contentDisposition = response.headers['content-disposition']
            // e.g. "attachment; filename=map4_regs_pkg.vhd"
            const pattern = /filename=([a-zA-Z0-9_.]+)/
            const match = pattern.exec(contentDisposition)
            const filename = match[1]
            // Note: blobs are file-like objects of immutable, raw data; they can be read as text or binary data.
            const blob = new Blob([response.data], {
              type: 'application/zip',
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = filename
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .catch((error) => {
            if (error.response) {
              // client received an error response (5xx, 4xx)
              if (error.response.status === 403) {
                this.$store.dispatch('logout')
              } else if (error.response.status === 400) {
                this.showErrorMessage(error.response.data.message)
              }
              this.showErrorMessage(error.response.data.message)
            } else if (error.request) {
              // client never received a response, or request never left
              this.showErrorMessage('download error')
            } else {
              // anything else
              this.showErrorMessage('download error')
            }
          })
      } else {
        // download a single file
        var opts = {
          type: type,
        }
        api.lfsrs
          .downloadLfsrWithHttpInfo(this.lfsrId, opts)
          .then(function (response_and_data) {
            // Extract filename from content-disposition header
            var contentDisposition =
              response_and_data.response.headers['content-disposition']
            // e.g. "attachment; filename=map4_regs_pkg.vhd"
            var pattern = /filename=([a-zA-Z0-9_.]+)/
            var match = pattern.exec(contentDisposition)
            var filename = match[1]
            // Instruct the browser to download the file
            const blob = new Blob([response_and_data.data], {
              type: 'application/octet-stream',
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = filename
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            } else if (error.status === 400) {
              console.log(error)
              if (error.body.error === 'DRC_ERROR') {
                this.showDrcs(false, error.body.failedDrcs)
                return // don't show error snackbar
              } else {
                this.showErrorMessage(error.body.message)
              }
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },

    // Load register map, registers and permissions from backend
    loadLfsr(/*showProgress*/) {
      // Load register map
      api.lfsrs
        .getLfsr(this.lfsrId, { includeRegisters: true })
        .then((response) => {
          this.lfsr = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          } else if (error.status === 400) {
            // access denied
            this.$router.push({ name: 'Dashboard' })
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked the "delete register map" menu item
    async deleteLfsr() {
      this.showSettingsMenu = false
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the LFSR <code>${this.lfsr.name}</code>? This action cannot be undone.`
        )
      ) {
        api.lfsrs
          .deleteLfsr(this.lfsr.id)
          .then(() => {
            this.$router.push({ name: 'LFSRs' })
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },

    // Returns true if the given download menu item is enabled (only false for C models of LFSRs
    // longer than 64 bits as the widest unsigned integer type is uint64_t in C).
    isDownloadAllowed(item) {
      if (item.type === 'cModel' && this.lfsr.length > 64) {
        return false
      }
      return true
    },

    initialValue(lfsr) {
      return lfsr.feedback === 'xor' ? '-1 (all ones)' : '0'
    },

    // from: https://stackoverflow.com/a/2901298/63730
    numberWithCommas(x) {
      if (x) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return ''
      }
    },
  },
}
</script>

<style>
.col {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
