<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ isNewLfsrDialog ? 'New' : 'Edit' }} LFSR</span
        >
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            autocomplete="off"
            ref="lfsrNameField"
            v-model="lfsr.name"
            label="Name"
            :rules="objectNameRules"
            outlined
            dense
            class="mb-4 mx-2"
            @input="validate"
          />
          <v-select
            :items="lengths"
            label="Length (bits)"
            v-model="lfsr.length"
            outlined
            dense
            class="mb-4 mx-2"
          ></v-select>
          <v-select
            :items="feedback"
            label="Feedback type"
            v-model="lfsr.feedback"
            outlined
            dense
            class="mb-4 mx-2"
          ></v-select>
          <v-textarea
            v-model="lfsr.description"
            label="Description"
            value=""
            outlined
            dense
            class="mb-4 mx-2"
            :rules="lfsrDescriptionRules"
            counter
            @input="validate"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-4"> Cancel </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mb-4 mr-4"
            type="submit"
            :disabled="!submitEnabled"
          >
            {{ isNewLfsrDialog ? 'Create LFSR' : 'Save LFSR' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      isNewLfsrDialog: true,
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,
      lengths: [],
      feedback: ['xor', 'xnor'],

      defaultLfsr: {
        name: '',
        description: '',
        length: 4,
        feedback: 'xor',
      },

      lfsr: null,
    }
  },

  created() {
    for (var i = 3; i <= 168; i++) {
      this.lengths.push(i)
    }
    this.lfsr = Object.assign({}, this.defaultLfsr)
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    //  - if LFSR is not null, show the "Edit LFSR" dialog
    //  - otherwise show the "New LFSR" dialog
    open(lfsr) {
      if (lfsr == null) {
        this.isNewLfsrDialog = true
        this.lfsr = Object.assign({}, this.defaultLfsr)
        this.submitEnabled = false
      } else {
        this.isNewLfsrDialog = false
        this.lfsr = Object.assign({}, lfsr)
        this.submitEnabled = true // assume that the register data is valid and enable the "Save register" button
      }
      this.dialog = true

      setTimeout(() => {
        this.$refs.form.resetValidation()
        this.$refs.lfsrNameField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.lfsr)
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    },
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {
    objectNameRules() {
      return global.objectNameRules
    },

    lfsrDescriptionRules() {
      return global.lfsrDescriptionRules
    },

    baseAddressRules() {
      return global.baseAddressRules
    },

    defaultAddressWidthRules() {
      return global.defaultAddressWidthRules
    },
  },
}
</script>

<style></style>
