"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ClientErrorBadRequest = _interopRequireDefault(require("../model/ClientErrorBadRequest"));
var _ClientErrorForbidden = _interopRequireDefault(require("../model/ClientErrorForbidden"));
var _DrcDto = _interopRequireDefault(require("../model/DrcDto"));
var _FieldDto = _interopRequireDefault(require("../model/FieldDto"));
var _InlineResponse = _interopRequireDefault(require("../model/InlineResponse500"));
var _PermissionDto = _interopRequireDefault(require("../model/PermissionDto"));
var _RegisterDto = _interopRequireDefault(require("../model/RegisterDto"));
var _RegisterMapDto = _interopRequireDefault(require("../model/RegisterMapDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Registermaps service.
* @module api/RegistermapsApi
* @version 1.0.0
*/
class RegistermapsApi {
  /**
  * Constructs a new RegistermapsApi. 
  * @alias module:api/RegistermapsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Auto-compute register offsets
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  computeRegisterOffsetsWithHttpInfo(registerMapId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling computeRegisterOffsets");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/compute-offsets', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Auto-compute register offsets
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  computeRegisterOffsets(registerMapId) {
    return this.computeRegisterOffsetsWithHttpInfo(registerMapId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create one or more register fields
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Array.<module:model/FieldDto>} fieldDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createFieldsWithHttpInfo(registerMapId, registerId, fieldDto) {
    let postBody = fieldDto;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling createFields");
    }
    // verify the required parameter 'registerId' is set
    if (registerId === undefined || registerId === null) {
      throw new Error("Missing the required parameter 'registerId' when calling createFields");
    }
    // verify the required parameter 'fieldDto' is set
    if (fieldDto === undefined || fieldDto === null) {
      throw new Error("Missing the required parameter 'fieldDto' when calling createFields");
    }
    let pathParams = {
      'registerMapId': registerMapId,
      'registerId': registerId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers/{registerId}/fields', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create one or more register fields
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Array.<module:model/FieldDto>} fieldDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createFields(registerMapId, registerId, fieldDto) {
    return this.createFieldsWithHttpInfo(registerMapId, registerId, fieldDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create new register in register map
   * @param {Number} registerMapId 
   * @param {module:model/RegisterDto} registerDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createRegisterWithHttpInfo(registerMapId, registerDto) {
    let postBody = registerDto;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling createRegister");
    }
    // verify the required parameter 'registerDto' is set
    if (registerDto === undefined || registerDto === null) {
      throw new Error("Missing the required parameter 'registerDto' when calling createRegister");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create new register in register map
   * @param {Number} registerMapId 
   * @param {module:model/RegisterDto} registerDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createRegister(registerMapId, registerDto) {
    return this.createRegisterWithHttpInfo(registerMapId, registerDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create new register map
   * @param {module:model/RegisterMapDto} registerMapDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RegisterMapDto} and HTTP response
   */
  createRegisterMapWithHttpInfo(registerMapDto) {
    let postBody = registerMapDto;
    // verify the required parameter 'registerMapDto' is set
    if (registerMapDto === undefined || registerMapDto === null) {
      throw new Error("Missing the required parameter 'registerMapDto' when calling createRegisterMap");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _RegisterMapDto.default;
    return this.apiClient.callApi('/registermaps', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create new register map
   * @param {module:model/RegisterMapDto} registerMapDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RegisterMapDto}
   */
  createRegisterMap(registerMapDto) {
    return this.createRegisterMapWithHttpInfo(registerMapDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete a register field
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Number} fieldId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteFieldWithHttpInfo(registerMapId, registerId, fieldId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling deleteField");
    }
    // verify the required parameter 'registerId' is set
    if (registerId === undefined || registerId === null) {
      throw new Error("Missing the required parameter 'registerId' when calling deleteField");
    }
    // verify the required parameter 'fieldId' is set
    if (fieldId === undefined || fieldId === null) {
      throw new Error("Missing the required parameter 'fieldId' when calling deleteField");
    }
    let pathParams = {
      'registerMapId': registerMapId,
      'registerId': registerId,
      'fieldId': fieldId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers/{registerId}/fields/{fieldId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete a register field
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Number} fieldId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteField(registerMapId, registerId, fieldId) {
    return this.deleteFieldWithHttpInfo(registerMapId, registerId, fieldId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete a register from a register map
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteRegisterWithHttpInfo(registerMapId, registerId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling deleteRegister");
    }
    // verify the required parameter 'registerId' is set
    if (registerId === undefined || registerId === null) {
      throw new Error("Missing the required parameter 'registerId' when calling deleteRegister");
    }
    let pathParams = {
      'registerMapId': registerMapId,
      'registerId': registerId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers/{registerId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete a register from a register map
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteRegister(registerMapId, registerId) {
    return this.deleteRegisterWithHttpInfo(registerMapId, registerId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteRegisterMapWithHttpInfo(registerMapId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling deleteRegisterMap");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteRegisterMap(registerMapId) {
    return this.deleteRegisterMapWithHttpInfo(registerMapId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Run the code generator for a register map
   * @param {Number} registerMapId 
   * @param {Object} opts Optional parameters
   * @param {String} opts.type the type of the output file to generate (cHeader, cpp-header, sv-package, sv-module, sv-testbench, vhdlPackage, vhdl-component, vhdl-inst, vhdl-testbench, html-doc, ip-xact)
   * @param {String} opts.baseaddr project-specific base address to use during code generation
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  downloadRegisterMapWithHttpInfo(registerMapId, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling downloadRegisterMap");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {
      'type': opts['type'],
      'baseaddr': opts['baseaddr']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/octet-stream', 'application/json; charset=utf-8'];
    let returnType = File;
    return this.apiClient.callApi('/registermaps/{registerMapId}/download', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Run the code generator for a register map
   * @param {Number} registerMapId 
   * @param {Object} opts Optional parameters
   * @param {String} opts.type the type of the output file to generate (cHeader, cpp-header, sv-package, sv-module, sv-testbench, vhdlPackage, vhdl-component, vhdl-inst, vhdl-testbench, html-doc, ip-xact)
   * @param {String} opts.baseaddr project-specific base address to use during code generation
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  downloadRegisterMap(registerMapId, opts) {
    return this.downloadRegisterMapWithHttpInfo(registerMapId, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get all register fields
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/FieldDto>} and HTTP response
   */
  getFieldsWithHttpInfo(registerMapId, registerId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling getFields");
    }
    // verify the required parameter 'registerId' is set
    if (registerId === undefined || registerId === null) {
      throw new Error("Missing the required parameter 'registerId' when calling getFields");
    }
    let pathParams = {
      'registerMapId': registerMapId,
      'registerId': registerId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_FieldDto.default];
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers/{registerId}/fields', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get all register fields
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/FieldDto>}
   */
  getFields(registerMapId, registerId) {
    return this.getFieldsWithHttpInfo(registerMapId, registerId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get list of permissions for the register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PermissionDto>} and HTTP response
   */
  getPermissionsWithHttpInfo(registerMapId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling getPermissions");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_PermissionDto.default];
    return this.apiClient.callApi('/registermaps/{registerMapId}/permissions', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get list of permissions for the register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PermissionDto>}
   */
  getPermissions(registerMapId) {
    return this.getPermissionsWithHttpInfo(registerMapId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a single register
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.includeFields Include register fields in the response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RegisterDto} and HTTP response
   */
  getRegisterWithHttpInfo(registerMapId, registerId, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling getRegister");
    }
    // verify the required parameter 'registerId' is set
    if (registerId === undefined || registerId === null) {
      throw new Error("Missing the required parameter 'registerId' when calling getRegister");
    }
    let pathParams = {
      'registerMapId': registerMapId,
      'registerId': registerId
    };
    let queryParams = {
      'includeFields': opts['includeFields']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _RegisterDto.default;
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers/{registerId}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a single register
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.includeFields Include register fields in the response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RegisterDto}
   */
  getRegister(registerMapId, registerId, opts) {
    return this.getRegisterWithHttpInfo(registerMapId, registerId, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get register map
   * @param {Number} registerMapId 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.includeRegisters Include registers in the response
   * @param {Boolean} opts.includeFields Include register fields in the response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RegisterMapDto} and HTTP response
   */
  getRegisterMapWithHttpInfo(registerMapId, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling getRegisterMap");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {
      'includeRegisters': opts['includeRegisters'],
      'includeFields': opts['includeFields']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _RegisterMapDto.default;
    return this.apiClient.callApi('/registermaps/{registerMapId}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get register map
   * @param {Number} registerMapId 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.includeRegisters Include registers in the response
   * @param {Boolean} opts.includeFields Include register fields in the response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RegisterMapDto}
   */
  getRegisterMap(registerMapId, opts) {
    return this.getRegisterMapWithHttpInfo(registerMapId, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get list of all register maps
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/RegisterMapDto>} and HTTP response
   */
  getRegisterMapsWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_RegisterMapDto.default];
    return this.apiClient.callApi('/registermaps', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get list of all register maps
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/RegisterMapDto>}
   */
  getRegisterMaps() {
    return this.getRegisterMapsWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get all registers in register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/RegisterDto>} and HTTP response
   */
  getRegistersWithHttpInfo(registerMapId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling getRegisters");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_RegisterDto.default];
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get all registers in register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/RegisterDto>}
   */
  getRegisters(registerMapId) {
    return this.getRegistersWithHttpInfo(registerMapId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Perform design rule checks on the register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/DrcDto>} and HTTP response
   */
  runDrcsWithHttpInfo(registerMapId) {
    let postBody = null;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling runDrcs");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_DrcDto.default];
    return this.apiClient.callApi('/registermaps/{registerMapId}/drc', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Perform design rule checks on the register map
   * @param {Number} registerMapId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/DrcDto>}
   */
  runDrcs(registerMapId) {
    return this.runDrcsWithHttpInfo(registerMapId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update a register field
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Number} fieldId 
   * @param {module:model/FieldDto} fieldDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  saveFieldWithHttpInfo(registerMapId, registerId, fieldId, fieldDto) {
    let postBody = fieldDto;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling saveField");
    }
    // verify the required parameter 'registerId' is set
    if (registerId === undefined || registerId === null) {
      throw new Error("Missing the required parameter 'registerId' when calling saveField");
    }
    // verify the required parameter 'fieldId' is set
    if (fieldId === undefined || fieldId === null) {
      throw new Error("Missing the required parameter 'fieldId' when calling saveField");
    }
    // verify the required parameter 'fieldDto' is set
    if (fieldDto === undefined || fieldDto === null) {
      throw new Error("Missing the required parameter 'fieldDto' when calling saveField");
    }
    let pathParams = {
      'registerMapId': registerMapId,
      'registerId': registerId,
      'fieldId': fieldId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers/{registerId}/fields/{fieldId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update a register field
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {Number} fieldId 
   * @param {module:model/FieldDto} fieldDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  saveField(registerMapId, registerId, fieldId, fieldDto) {
    return this.saveFieldWithHttpInfo(registerMapId, registerId, fieldId, fieldDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update register
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {module:model/RegisterDto} registerDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  saveRegisterWithHttpInfo(registerMapId, registerId, registerDto) {
    let postBody = registerDto;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling saveRegister");
    }
    // verify the required parameter 'registerId' is set
    if (registerId === undefined || registerId === null) {
      throw new Error("Missing the required parameter 'registerId' when calling saveRegister");
    }
    // verify the required parameter 'registerDto' is set
    if (registerDto === undefined || registerDto === null) {
      throw new Error("Missing the required parameter 'registerDto' when calling saveRegister");
    }
    let pathParams = {
      'registerMapId': registerMapId,
      'registerId': registerId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/registers/{registerId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update register
   * @param {Number} registerMapId 
   * @param {Number} registerId 
   * @param {module:model/RegisterDto} registerDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  saveRegister(registerMapId, registerId, registerDto) {
    return this.saveRegisterWithHttpInfo(registerMapId, registerId, registerDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update register map
   * @param {Number} registerMapId 
   * @param {module:model/RegisterMapDto} registerMapDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RegisterMapDto} and HTTP response
   */
  saveRegisterMapWithHttpInfo(registerMapId, registerMapDto) {
    let postBody = registerMapDto;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling saveRegisterMap");
    }
    // verify the required parameter 'registerMapDto' is set
    if (registerMapDto === undefined || registerMapDto === null) {
      throw new Error("Missing the required parameter 'registerMapDto' when calling saveRegisterMap");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _RegisterMapDto.default;
    return this.apiClient.callApi('/registermaps/{registerMapId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update register map
   * @param {Number} registerMapId 
   * @param {module:model/RegisterMapDto} registerMapDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RegisterMapDto}
   */
  saveRegisterMap(registerMapId, registerMapDto) {
    return this.saveRegisterMapWithHttpInfo(registerMapId, registerMapDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Set a permission for the register map
   * @param {Number} registerMapId 
   * @param {module:model/PermissionDto} permissionDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  setPermissionWithHttpInfo(registerMapId, permissionDto) {
    let postBody = permissionDto;
    // verify the required parameter 'registerMapId' is set
    if (registerMapId === undefined || registerMapId === null) {
      throw new Error("Missing the required parameter 'registerMapId' when calling setPermission");
    }
    // verify the required parameter 'permissionDto' is set
    if (permissionDto === undefined || permissionDto === null) {
      throw new Error("Missing the required parameter 'permissionDto' when calling setPermission");
    }
    let pathParams = {
      'registerMapId': registerMapId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/{registerMapId}/permissions', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Set a permission for the register map
   * @param {Number} registerMapId 
   * @param {module:model/PermissionDto} permissionDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  setPermission(registerMapId, permissionDto) {
    return this.setPermissionWithHttpInfo(registerMapId, permissionDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Upload a register map JSON
   * @param {Object} opts Optional parameters
   * @param {Number} opts.registerMapId ID of the register map to update
   * @param {File} opts.body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  uploadRegisterMapWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['body'];
    let pathParams = {};
    let queryParams = {
      'registerMapId': opts['registerMapId']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/octet-stream'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/registermaps/upload', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Upload a register map JSON
   * @param {Object} opts Optional parameters
   * @param {Number} opts.registerMapId ID of the register map to update
   * @param {File} opts.body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  uploadRegisterMap(opts) {
    return this.uploadRegisterMapWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = RegistermapsApi;