<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-left">
        <MessageSheet
          v-if="isError"
          type="warning"
          title="Verification Error"
          >{{ message }}</MessageSheet
        >
        <v-btn
          v-if="error === 'TOKEN_EXPIRED'"
          @click="resend"
          :loading="loading"
          >Resend verification link</v-btn
        >
        <MessageSheet
          v-if="isSuccess"
          type="success"
          title="Registration Successful"
          >Please proceed to
          <router-link :to="{ name: 'Login' }">login</router-link></MessageSheet
        >
      </v-col>
    </v-row>
    <MessageSnack v-model="snackMessage.show" :text="snackMessage.text" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import MessageSheet from '@/components/MessageSheet.vue'
import MessageSnack from '@/components/MessageSnack.vue'

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/

  components: {
    MessageSheet,
    MessageSnack,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data() {
    return {
      token: null,
      isSuccess: false,
      isError: false,
      message: '',
      error: '',
      loading: false,

      snackMessage: {
        show: false,
        text: '',
      },
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/

  created() {
    // Confirmation URL is of the form: http://localhost:8080/#/confirmRegistration?token=0eb9a609-1467-4e9e-bcd0-8576a242255b
    console.log(this.$route.query.token)
    this.token = this.$route.query.token
    var token = {
      token: this.token,
    }
    api.auth
      .confirm(token)
      .then(() => {
        this.isSuccess = true
      })
      .catch((error) => {
        if (error.status === 403) {
          this.$store.dispatch('logout')
        }
        console.log(error)
        this.isError = true
        this.message = error.body.message
        // Error can be one of the following: TOKEN_INVALID, TOKEN_EXPIRED
        this.error = error.body.error
      })
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/

  methods: {
    showErrorMessage(message) {
      this.snackMessage.text = 'Error: ' + message
      this.snackMessage.show = true
    },

    // Clicked "Resend verification link" button
    resend() {
      var msg = {
        token: this.token,
      }
      this.loading = true
      api.auth
        .resend(msg)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'VerificationLinkSent' })
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.loading = false
          this.showErrorMessage(error.body.message)
        })
    },
  },
}
</script>
