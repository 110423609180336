"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _LicenseDto = _interopRequireDefault(require("../model/LicenseDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Generator service.
* @module api/GeneratorApi
* @version 1.0.0
*/
class GeneratorApi {
  /**
  * Constructs a new GeneratorApi. 
  * @alias module:api/GeneratorApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new license
   * @param {module:model/LicenseDto} licenseDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createLicenseWithHttpInfo(licenseDto) {
    let postBody = licenseDto;
    // verify the required parameter 'licenseDto' is set
    if (licenseDto === undefined || licenseDto === null) {
      throw new Error("Missing the required parameter 'licenseDto' when calling createLicense");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/generator/licences', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new license
   * @param {module:model/LicenseDto} licenseDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createLicense(licenseDto) {
    return this.createLicenseWithHttpInfo(licenseDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * download a license file
   * @param {Number} licenseId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  downloadLicenseWithHttpInfo(licenseId) {
    let postBody = null;
    // verify the required parameter 'licenseId' is set
    if (licenseId === undefined || licenseId === null) {
      throw new Error("Missing the required parameter 'licenseId' when calling downloadLicense");
    }
    let pathParams = {
      'licenseId': licenseId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/octet-stream'];
    let returnType = File;
    return this.apiClient.callApi('/generator/licenses/{licenseId}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * download a license file
   * @param {Number} licenseId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  downloadLicense(licenseId) {
    return this.downloadLicenseWithHttpInfo(licenseId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get list of licenses
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/LicenseDto>} and HTTP response
   */
  getLicensesWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_LicenseDto.default];
    return this.apiClient.callApi('/generator/licences', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get list of licenses
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/LicenseDto>}
   */
  getLicenses() {
    return this.getLicensesWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = GeneratorApi;