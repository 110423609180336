<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Edit Account Information</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            ref="firstNameField"
            v-model="user.firstName"
            label="First Name"
            outlined
            dense
            class="mb-4"
          />
          <v-text-field
            ref="lastNameField"
            v-model="user.lastName"
            label="Last Name"
            outlined
            dense
            class="mb-4"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-4 mr-2"> Cancel </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mb-4 mr-2"
            type="submit"
            :disabled="!submitEnabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
const defaultUser = {
  firstName: '',
}

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,
      user: defaultUser,
    }
  },

  /************************************************************************************************/
  methods: {
    open(user) {
      this.user = Object.assign({}, user)
      this.submitEnabled = true
      this.dialog = true

      setTimeout(() => {
        this.$refs.firstNameField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.user)
    },

    validate() {},
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {},
}
</script>

<style></style>
