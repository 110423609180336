<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="1250" class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            Credits
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="body-1 text--primary">
            <div v-html="credits"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import strCredits from 'raw-loader!@/md/credits.md'
import pagedown from '@/libs/pagedown/Markdown.Converter.js'

export default {
  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      credits: '',
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {
    var converter = new pagedown.Converter()
    this.credits = converter.makeHtml(strCredits)
  },
}
</script>

<style></style>
