import settings from '@/json/settings.json'

// New field pattern
// allowed: field1, field[1..2], field[0:31], done[0..31], done_[0..31] done_well, done
// not allowed: done_, _done, __done, 1done, 1, _, don?e, done__well
const newFieldPattern = /^([a-zA-Z](?:_?[a-zA-Z0-9])*(_(?!$))?)(?:\[(\d+)(?:\.{2}|:)(\d+)\])?$/

// From https://stackoverflow.com/a/46181/63730
function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

function isHexNumber(value) {
  const re = /^[0-9a-fA-F]{1,8}$/
  return re.test(value)
}

// Checks if the value is a hex number starting with the '0x' prefix
function isHexNumberWithPrefix(value) {
  var normValue = value.trim().toLowerCase()
  const re = /^0x[0-9a-fA-F]{1,8}$/
  var result = re.test(normValue)
  // console.log('isHexNumberWithPrefix: ' + result)
  return result
}

function isPositiveOrNullInteger(value) {
  var normValue = value.trim().toLowerCase()
  const re = /^\d+$/
  if (!re.test(normValue)) {
    return false
  }
  var intValue = parseInt(normValue)
  if (isNaN(intValue)) {
    return false
  }
  return intValue >= 0
}

// Converts decimal (e.g. '123') or hexadecimal (e.g. '0x123') string to integer
function intFromString(value) {
  var normValue = value.trim().toLowerCase()
  if (normValue.startsWith('0x')) {
    return parseInt(normValue, 16)
  } else {
    return parseInt(normValue)
  }
}

function isMultipleOf4(hexValue) {
  var value = parseInt(hexValue, 16)
  return value % 4 === 0
}

function isValidIdentifier(name) {
  return /^[a-zA-Z](_?[a-zA-Z0-9])*$/.test(name)
}

// Returns the number of bytes occupied by a register
function registerSizeBytes(register, widthBits) {
  var result = widthBits / 8
  if (register.type === 'RegisterArray') {
    result = result * register.arrayLength
  }
  if (register.type === 'Memory') {
    result = result * register.depth
  }
  return result
}

/**
 * Returns the address offset immediately following the register with
 * the highest address offset in the given list of registers.
 */
function nextAddressOffset(registers, widthBits = 32) {
  if (registers.length == 0) {
    return 0x0
  }
  let sortedRegisters = Object.assign([], registers)
  sortedRegisters.sort((a, b) => a.addressOffset - b.addressOffset)
  let highRegister = sortedRegisters[sortedRegisters.length - 1]
  return highRegister.addressOffset + registerSizeBytes(highRegister, widthBits)
}

/**
 * Returns the next available bit offset for a field, or -1 in case of overflow
 */
function nextBitOffset(fields, registerWidthBits) {
  var sortedFields = Object.assign([], fields)
  sortedFields.sort((a, b) => a.bitOffset - b.bitOffset)
  var result = 0
  for (var i = 0; i < sortedFields.length; i++) {
    var field = sortedFields[i]
    if (result < field.bitOffset) {
      return result
    }
    result = field.bitOffset + field.bitWidth
  }
  // console.log(result)
  if (result >= registerWidthBits) {
    // overflow
    result = 0
  }
  return result
}

// Returns the range, i.e. the total number of bytes occupied by a register map
// in the system address space.
function range(registerMap, registers) {
  var result = 0x0
  if (registerMap == null) {
    return 0
  }
  if (registers == null) {
    return 0
  }
  registers.forEach(register => {
    var range =
      register.addressOffset + registerSizeBytes(register, registerMap.width)
    if (range > result) {
      result = range
    }
  })
  return result
}

function isValidMacAddress(value) {
  return /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/i.test(value)
}

/**
 * Returns the sharing message for a register map.
 *
 * @example "shared by guy@airhdl.com"
 * @example "shared with guy@noasic.com"
 */
function sharingMessage(registerMap) {
  let msg = ''
  if (registerMap.permission === 'ADMIN') {
    msg = 'Shared with: '
    let userNames = []
    for (var i = 0; i < registerMap.permissions.length; i++) {
      let entry = registerMap.permissions[i]
      if (entry.permission !== 'ADMIN') {
        userNames.push(entry.userName)
      }
    }
    msg += userNames.join(', ')
  } else {
    msg = 'Shared by: ' + registerMap.owner
  }
  return msg
}

function isSharedRegisterMap(registerMap) {
  if (
    (registerMap.permission === 'ADMIN' &&
      registerMap.permissions.length > 1) || // shared by the logged-in user
    registerMap.permission !== 'ADMIN' // shared with the logged-in user
  ) {
    return true
  }
  return false
}

/************************************************************************************************
 * Exported properties
 ************************************************************************************************/

export default {
  passwordRules: [
    value => value.length >= 8 || 'Password must have at least 8 characters'
  ],

  // E-mail address rule
  emailRules: [value => isValidEmail(value) || 'Invalid e-mail address'],

  // Object name rule: must valid valid identifier
  objectNameRules: [
    value => isValidIdentifier(value) || 'Must be a valid identifier'
  ],

  // Rules for new field name: must be a valid identifier, optionally followed
  // by a replication range, e.g. myField, myField1, myField0..7, myField_0..7, myField0:7
  newFieldNameRules: [
    value => newFieldPattern.test(value) || 'Must be a valid identifier',
    value => {
      let match = value.match(newFieldPattern)
      if (match) {
        // console.log('name ' + match[1])
        if (match[3] && match[4]) {
          // replication range available
          let low = parseInt(match[3])
          let high = parseInt(match[4])
          if (low >= high) {
            return 'Invalid replication range: high index must be greater than low index'
          } else if (low >= settings.registerWidthBits) {
            return `Invalid replication range: low index ${low} is out of range 0 to ${settings.registerWidthBits}`
          } else if (high >= settings.registerWidthBits) {
            return `Invalid replication range: high index ${high} is out of range 0 to ${settings.registerWidthBits}`
          }
        }
        return true
      } else {
        return false
      }
    }
  ],

  // Base address rule: must be a 32-bit hex number without the 0x prefix
  baseAddressRules: [
    value =>
      isHexNumber(value) || 'The address must be a valid 32-bit hex number',
    value =>
      isMultipleOf4(value) || 'The address must start on a 4-byte boundary'
  ],

  addressOffsetRules: [
    value =>
      isHexNumber(value) ||
      'The address offset must be a valid 32-bit hex number',
    value =>
      isMultipleOf4(value) ||
      'The address offset must start on a 4-byte boundary'
  ],

  // Field enum value rules
  // - must be an integer >= 0
  // - or a hex number <= 32 bits with '0x' prefix
  enumValueRules: [
    value =>
      isHexNumberWithPrefix(value) ||
      isPositiveOrNullInteger(value) ||
      'Must be a valid decimal or hex number'
  ],

  macAddressRules: [
    value =>
      isValidMacAddress(value) ||
      'Must be a valid MAC address (e.g. AD-11-A0-E6-71-75 or 83:32:AA:46:9C:4E)'
  ],

  registerMapDescriptionRules: [
    v =>
      v.length <= settings.registerMapDescriptionLength ||
      'Max ' + settings.registerMapDescriptionLength + ' characters'
  ],

  lfsrDescriptionRules: [
    v =>
      v.length <= settings.lfsrDescriptionLength ||
      'Max ' + settings.lfsrDescriptionLength + ' characters'
  ],

  registerDescriptionRules: [
    v =>
      v.length <= settings.registerDescriptionLength ||
      'Max ' + settings.registerDescriptionLength + ' characters'
  ],

  projectDescriptionRules: [v => v.length <= 255 || 'Max 255 characters'],
  fieldDescriptionRules: [
    v =>
      v.length <= settings.fieldDescriptionLength ||
      'Max ' + settings.fieldDescriptionLength + ' characters'
  ],

  licenseDescriptionRules: [
    v =>
      v.length <= settings.licenseDescriptionLength ||
      'Max ' + settings.licenseDescriptionLength + ' characters'
  ],

  fieldBitOffsetRules: [v => (v >= 0 && v <= 31) || 'Must be in range [0,31]'],

  fieldBitWidthRules: [v => (v >= 1 && v <= 32) || 'Must be in range [1,32]'],

  defaultAddressWidthRules: [
    v => v >= 1 || 'Must be greater than or equal to 1'
  ],

  nextAddressOffset,
  nextBitOffset,
  range,
  registerSizeBytes,
  isHexNumber,
  intFromString,
  sharingMessage,
  isSharedRegisterMap,
  newFieldPattern
}
