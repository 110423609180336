<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="1250" class="mx-auto" elevation="0">
          <v-card-title>
            <span class="text-h4 font-weight-medium">Plans</span>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="px-1">
            <Plans />
          </v-card-text>
          <v-card-text class="body-1 text--primary pt-0 mb-4">
            <v-row>
              <v-col cols="6">
                <h2>Special Conditions</h2>
                <ul>
                  <li><strong>Academic users</strong> (students and professors) can get a free
                    <strong>Professional</strong>
                    subscription for educational purposes. Please register with your academic
                    e-mail address and drop us a note at
                    <a href="mailto:sales@airhdl.com">sales@airhdl.com</a> to request
                    your free 6-month subscription.
                  </li>
                  <li><strong>Open source developers</strong> can get a free <strong>Professional</strong> subscription
                    for
                    use
                    in publicly available projects, which must be licensed under an open-source license. Please drop us
                    a
                    note with a description of your project at
                    <a href="mailto:sales@airhdl.com">sales@airhdl.com</a> to request
                    your free 6-month subscription.
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Plans from '@/components/Plans.vue'

export default {
  components: {
    Plans,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {}
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {},

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() { },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {},
}
</script>

<style>

</style>
