<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium pb-1">
            Dashboard
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <v-card>
                  <v-card-title
                    class="text-h6 font-weight-normal mt-4 py-2"
                    style="background: #f5f5f5"
                  >
                    <span class="font-weight-medium">Register Maps</span
                    ><v-spacer></v-spacer
                    ><span class="text-body-2"
                      >Owned by you:
                      {{ ownRegisterMapsCount }}&nbsp;&bull;&nbsp;Shared with
                      you: {{ sharedRegisterMapsCount }}</span
                    >
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-0">
                    <v-data-table
                      :headers="registerMapTableHeaders"
                      :items="registerMaps"
                      class="elevation-0 mx-0 mt-0 text-body-1 black--text"
                      hide-default-footer
                      :loading="registersMapsLoading"
                      loading-text="Loading..."
                      @click:row="onRegisterMapTableRowClick"
                      no-data-text="You haven't created any register maps yet."
                      disable-pagination
                    >
                      <!-- name column -->
                      <template v-slot:item.name="{ item }">
                        <v-icon class="mr-4" v-show="showIcons">mdi-sitemap</v-icon>
                        <span class="font-weight-medium">{{ item.name }}</span>
                        <template v-if="isSharedRegisterMap(item)">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" class="ml-4" v-show="showIcons">
                                mdi-account-multiple-outline
                              </v-icon>
                            </template>
                            <span>{{ sharingMessage(item) }}</span>
                          </v-tooltip>
                        </template>
                      </template>
                      <!-- Description column -->
                      <template v-slot:item.description="{ item }">
                        {{ shorten(item.description || '&ndash;') }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card :disabled="!allowProjects">
                  <v-card-title
                    class="text-h6 font-weight-normal mt-4 py-2"
                    style="background: #f5f5f5"
                  >
                    Projects<v-spacer></v-spacer
                    ><span class="text-body-2"
                      >Owned by you: {{ ownProjectsCount }}</span
                    >
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-0">
                    <v-data-table
                      :headers="projectTableHeaders"
                      :items="projects"
                      class="elevation-0 mx-0 mt-0 text-body-1 black--text"
                      hide-default-footer
                      :loading="projectsLoading"
                      loading-text="Loading..."
                      @click:row="onProjectTableRowClick"
                      no-data-text="You haven't created any projects yet."
                      disable-pagination
                    >
                      <!-- name column -->
                      <template v-slot:item.name="{ item }">
                        <v-icon class="mr-4" v-show="showIcons">mdi-folder-outline</v-icon>
                        <span class="font-weight-medium">{{ item.name }}</span>
                      </template>
                      <!-- Description column -->
                      <template v-slot:item.description="{ item }">
                        {{ shorten(item.description || '&ndash;') }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
                <v-card>
                  <v-card-title
                    class="text-h6 font-weight-normal mt-6 py-2"
                    style="background: #f5f5f5"
                  >
                    LFSRs<v-spacer></v-spacer
                    ><span class="text-body-2"
                      >Owned by you: {{ ownLfsrsCount }}</span
                    >
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-0">
                    <v-data-table
                      :headers="lfsrTableHeaders"
                      :items="lfsrs"
                      class="elevation-0 mx-0 mt-0 text-body-1 black--text"
                      hide-default-footer
                      :loading="lfsrsLoading"
                      loading-text="Loading..."
                      @click:row="onLfsrTableRowClick"
                      no-data-text="You haven't created any LFSRs yet."
                      disable-pagination
                    >
                      <!-- name column -->
                      <template v-slot:item.name="{ item }">
                        <v-icon class="mr-4" v-show="showIcons">mdi-view-column-outline</v-icon>
                        <span class="font-weight-medium">{{ item.name }}</span>
                      </template>
                      <!-- Description column -->
                      <template v-slot:item.description="{ item }">
                        {{ shorten(item.description || '&ndash;') }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import global from '@/global/index.js'
import utils from '@/global/utils.js'
import api from '@/services/ApiService.js'
import MessageSnack from '@/components/MessageSnack.vue'
import { mapGetters } from 'vuex'
import settings from '@/json/settings.json'

export default {
  components: {
    MessageSnack,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data() {
    return {
      projects: [],
      lfsrs: [],
      registerMaps: [],
      projectsLoading: false,
      lfsrsLoading: false,
      registersMapsLoading: false,
      reloadTimer: null,

      testimonial: {
        name: '',
        company: '',
        url: '',
        text: '',
      },

      termsDialog: {
        show: false,
        text: '',
      },

      message: {
        show: false,
        text: '',
      },

      projectTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '33%',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
        },
      ],

      lfsrTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '33%',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
        },
      ],

      registerMapTableHeaders: [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name',
          width: '33%',
        },
        {
          text: 'Description',
          align: 'left',
          sortable: false,
          value: 'description',
        },
      ],
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {
    this.loadObjects(true)
    this.reloadTimer = setInterval(
      this.loadObjects,
      settings.autoReloadPeriodSec * 1000
    )
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {
    ...mapGetters(['allowProjects', 'username', 'user']),

    // Whether or not to show the register map, project and LFSR icons in the dashboard tables
    showIcons() {
      return this.$vuetify.breakpoint.width >= settings.showIconsBreakpoint;
    },

    ownProjectsCount() {
      return this.projects.length
    },

    ownLfsrsCount() {
      return this.lfsrs.length
    },

    ownRegisterMapsCount() {
      var result = 0
      for (var i = 0; i < this.registerMaps.length; i++) {
        var registerMap = this.registerMaps[i]
        if (registerMap.permission === 'ADMIN') {
          result += 1
        }
      }
      return result
    },

    sharedRegisterMapsCount() {
      var result = 0
      for (var i = 0; i < this.registerMaps.length; i++) {
        var registerMap = this.registerMaps[i]
        if (registerMap.permission !== 'ADMIN') {
          result += 1
        }
      }
      return result
    },

    testimonialNameRules() {
      return [(v) => v.length > 0 || 'Name must not be empty']
    },

    testimonialTextRules() {
      return [(v) => v.length > 0 || 'Testimonial must not be empty']
    },
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    shorten(description) {
      return utils.shorten(description, settings.maxDescriptionLength)
    },

    async reloadUser() {
      await this.$store.dispatch('reloadUser')
    },

    onHideTestimonial() {
      console.log('onHideTestimonial')

      var userDto = {
        id: this.user.id,
        showTestimonial: false,
      }
      api.users
        .saveUser(this.user.id, userDto)
        .then(() => {
          this.reloadUser()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showMessage('An error occurred')
        })
    },

    onSubmitTestimonial() {
      console.log('onSubmitTestimonial')

      if (this.$refs.testimonialForm.validate()) {
        let messageDto = api.MessageDto
        messageDto.subject = 'User Testimonial'
        messageDto.to = 'guy@airhdl.com'
        messageDto.text = 'Submitted by: ' + this.username + '\n'
        messageDto.text += 'Name: ' + this.testimonial.name + '\n'
        messageDto.text += 'Company: ' + this.testimonial.company + '\n'
        messageDto.text += 'URL: ' + this.testimonial.url + '\n'
        messageDto.text += 'Text: ' + this.testimonial.text + '\n'
        api.message
          .sendTestimonial(messageDto)
          .then(() => {
            this.reloadUser()
            this.showMessage('Thanks for submitting your testimonial!')
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showMessage('An error occurred')
          })
      }
    },

    isSharedRegisterMap(registerMap) {
      return global.isSharedRegisterMap(registerMap)
    },

    sharingMessage(registerMap) {
      return global.sharingMessage(registerMap)
    },

    // Reload projects and register maps
    loadObjects(showProgress) {
      this.loadProjects(showProgress)
      this.loadLfsrs(showProgress)
      this.loadRegisterMaps(showProgress)
    },

    showMessage(message) {
      this.message.text = message
      this.message.show = true
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    loadProjects(showProgress) {
      if (showProgress) {
        this.projectsLoading = true
      }
      api.projects
        .getProjects()
        .then((response) => {
          this.projects = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
        .finally(() => (this.projectsLoading = false))
    },

    loadLfsrs(showProgress) {
      if (showProgress) {
        this.lfsrsLoading = true
      }
      api.lfsrs
        .getLfsrs()
        .then((response) => {
          this.lfsrs = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
        .finally(() => (this.lfsrsLoading = false))
    },

    loadRegisterMaps(showProgress) {
      if (showProgress) {
        this.registersMapsLoading = true
      }
      api.registerMaps
        .getRegisterMaps()
        .then((response) => {
          this.registerMaps = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
        .finally(() => (this.registersMapsLoading = false))
    },

    onProjectTableRowClick(item) {
      this.$router.push({
        name: 'Project',
        params: { projectId: item.id },
      })
    },

    onLfsrTableRowClick(item) {
      this.$router.push({
        name: 'Lfsr',
        params: { lfsrId: item.id },
      })
    },

    onRegisterMapTableRowClick(item) {
      this.$router.push({
        name: 'RegisterMap',
        params: { registerMapId: item.id },
      })
    },
  },
}
</script>

<style>
tbody tr:hover {
  cursor: pointer;
}
</style>