<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Change Password</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>        
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <PasswordInput
            v-model="oldPassword"
            label="Old Password"
            :enableRules="false"
            ref="oldPasswordInput"
          />
          <PasswordInput
            v-model="newPassword"
            label="New Password"
            :enableRules="true"
          />
          <p class="text-body-2 ml-0 mt-2" v-show="newPassword.length > 0">
            Password strength:
            <span :class="strengthColor(newPassword) + ' font-weight-medium'">{{
              strength(newPassword)
            }}</span>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-4 mr-2">
            Cancel
          </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mb-4 mr-2"
            type="submit"
            :disabled="!submitEnabled"
          >
            Change Password
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PasswordInput from '@/components/PasswordInput.vue'
import * as pw from '@/helpers/passwordHelpers.js'

export default {
  /************************************************************************************************/
  components: {
    PasswordInput
  },

  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,
      oldPassword: '',
      newPassword: ''
    }
  },

  /************************************************************************************************/
  methods: {
    open() {
      this.submitEnabled = true
      this.dialog = true
      this.oldPassword = ''
      this.newPassword = ''

      setTimeout(() => {
        this.$refs.oldPasswordInput.focus()
        this.$refs.form.resetValidation()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      var dto = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      }
      this.resolve(dto)
    },

    strength(password) {
      return pw.passwordStrength(password)
    },

    strengthColor(password) {
      return pw.passwordStrengthColor(password)
    }
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {}
}
</script>

<style></style>
