<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="800" class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            Privacy Policy
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="body-1 text--primary">
            <span class="md" v-html="terms"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import txt from 'raw-loader!@/md/privacy.md'
import pagedown from '@/libs/pagedown/Markdown.Converter.js'

export default {
  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      terms: '',
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {
    var converter = new pagedown.Converter()
    this.terms = converter.makeHtml(txt)
  },
}
</script>

<style>
h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}


.md h2 {
  margin-top: 1em;
}

.md h3 {
  margin-top: 0.75em;
}

.md p {
  margin-top: 0.25em;
}
</style>
