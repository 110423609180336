"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _FieldDto = _interopRequireDefault(require("./FieldDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RegisterDto model module.
 * @module model/RegisterDto
 * @version 1.0.0
 */
class RegisterDto {
  /**
   * Constructs a new <code>RegisterDto</code>.
   * @alias module:model/RegisterDto
   */
  constructor() {
    RegisterDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>RegisterDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RegisterDto} obj Optional instance to populate.
   * @return {module:model/RegisterDto} The populated <code>RegisterDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RegisterDto();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('registerMapId')) {
        obj['registerMapId'] = _ApiClient.default.convertToType(data['registerMapId'], 'Number');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = _ApiClient.default.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('access')) {
        obj['access'] = _ApiClient.default.convertToType(data['access'], 'String');
      }
      if (data.hasOwnProperty('addressOffset')) {
        obj['addressOffset'] = _ApiClient.default.convertToType(data['addressOffset'], 'Number');
      }
      if (data.hasOwnProperty('arrayLength')) {
        obj['arrayLength'] = _ApiClient.default.convertToType(data['arrayLength'], 'Number');
      }
      if (data.hasOwnProperty('genericArrayLength')) {
        obj['genericArrayLength'] = _ApiClient.default.convertToType(data['genericArrayLength'], 'Boolean');
      }
      if (data.hasOwnProperty('depth')) {
        obj['depth'] = _ApiClient.default.convertToType(data['depth'], 'Number');
      }
      if (data.hasOwnProperty('readLatency')) {
        obj['readLatency'] = _ApiClient.default.convertToType(data['readLatency'], 'Number');
      }
      if (data.hasOwnProperty('fields')) {
        obj['fields'] = _ApiClient.default.convertToType(data['fields'], [_FieldDto.default]);
      }
      if (data.hasOwnProperty('vol')) {
        obj['vol'] = _ApiClient.default.convertToType(data['vol'], 'Boolean');
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
RegisterDto.prototype['id'] = undefined;

/**
 * @member {Number} registerMapId
 */
RegisterDto.prototype['registerMapId'] = undefined;

/**
 * @member {String} type
 */
RegisterDto.prototype['type'] = undefined;

/**
 * @member {String} name
 */
RegisterDto.prototype['name'] = undefined;

/**
 * @member {String} description
 */
RegisterDto.prototype['description'] = undefined;

/**
 * @member {String} access
 */
RegisterDto.prototype['access'] = undefined;

/**
 * @member {Number} addressOffset
 */
RegisterDto.prototype['addressOffset'] = undefined;

/**
 * @member {Number} arrayLength
 */
RegisterDto.prototype['arrayLength'] = undefined;

/**
 * @member {Boolean} genericArrayLength
 */
RegisterDto.prototype['genericArrayLength'] = undefined;

/**
 * @member {Number} depth
 */
RegisterDto.prototype['depth'] = undefined;

/**
 * @member {Number} readLatency
 */
RegisterDto.prototype['readLatency'] = undefined;

/**
 * @member {Array.<module:model/FieldDto>} fields
 */
RegisterDto.prototype['fields'] = undefined;

/**
 * @member {Boolean} vol
 */
RegisterDto.prototype['vol'] = undefined;
var _default = RegisterDto;
exports.default = _default;