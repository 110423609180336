"use strict"

const regHeight = 75;
const regWidth = 50;
// const x0 = 75;
const x0 = regWidth/2+1;
// const y0 = 75;
const y0 = 1;
const regXSpace = 50;
const regXGrid = regWidth + regXSpace;
const regYGrid = 100;

function rect(x, y, text) {
  let result = `<g>`;
  result += `<rect
    x="${x}"
    y="${y}"
    width="${regWidth}"
    height="${regHeight}"
    style="
      fill: #ffffff;
      stroke-width: 1;
      stroke: rgb(0, 0, 0);
      fill-opacity: 1;
    " />`;
  result += `<text
      x="${x + regWidth / 2}"
      y="${y + regHeight / 2}"
      font-family="Verdana"
      font-size="12"
      fill="black"
      dominant-baseline="middle"
      text-anchor="middle"
    >
      ${text}
    </text>`;
  result += `</g>`;
  return result;
}

function line(points, endMarker = "arrow", startMarker = "", dashed = false) {
  let wayPoints = "";
  points.forEach((point) => {
    // e.g. "300,250 50,250 50,100 100,100"
    wayPoints += `${point.x},${point.y} `;
  });

  let strokeDashArray = "";
  if (dashed) {
    strokeDashArray = `stroke-dasharray="3 3"`;
  }

  let result = `<polyline
    points="${wayPoints}"
    style="fill: none; stroke: black; stroke-width: 1;"
    ${strokeDashArray}
    marker-end="url(#${endMarker})"
    marker-start="url(#${startMarker})"
  />`;
  return result;
}

function render(lfsr) {
  // Sort the taps in ascending order
  const taps = lfsr.taps.sort((a, b) => a - b);

  // Compute the cells to show
  let cells = [];
  cells.push(1);
  cells.push(taps[0]);
  let start = taps[0] + 1;
  for (let i = 1; i < taps.length; i++) {
    if (start != taps[i]) {
      cells.push(start);
    }
    cells.push(taps[i]);
    start = taps[i] + 1;
  }

  const svgWidth = x0 * 2 + cells.length * (regWidth + regXSpace);
  const svgHeight = y0 * 2 + regYGrid * 2 - (regYGrid - regHeight);

  let result = `<svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}" style="border: 0px solid black">`;

  result += `<defs>
    <marker
    id="arrow"
    viewBox="0 0 10 10"
    refX="10"
    refY="5"
    markerWidth="6"
    markerHeight="6"
    orient="auto-start-reverse"
    ><path d="M 0 0 L 10 5 L 0 10 z" /></marker>
    <marker id="dot" markerWidth="8" markerHeight="8" refX="5" refY="5">
        <circle cx="5" cy="5" r="3"></circle>
    </marker>
    </defs>`;

  // Register cells
  for (let i = 0; i < cells.length; i++) {
    let text = `Q${cells[i]}`;
    result += rect(x0 + i * regXGrid, y0 + 0 * regYGrid, text);
  }

  // Feedback cells
  for (let i = 0; i < taps.length - 1; i++) {
    let tap = taps[i];
    let cellIdx = cells.indexOf(tap);
    result += "<g>";
    result += rect(x0 + cellIdx * regXGrid, y0 + 1 * regYGrid, lfsr.feedback.toUpperCase());
    result += "</g>";
  }

  // Register-to-register nets
  for (let i = 0; i < cells.length - 1; i++) {
    const points = [
      { x: x0 + regXGrid * i + regWidth, y: y0 + regYGrid * 0 + regHeight / 2 },
      { x: x0 + regXGrid * (i + 1), y: y0 + regYGrid * 0 + regHeight / 2 },
    ];
    let dashed = false;
    if (cells[i + 1] != cells[i] + 1) {
      dashed = true;
    }
    result += line(points, "arrow", "", dashed);
  }

  // Last register to first XNOR net
  let points = [
    {
      x: x0 + regXGrid * (cells.length - 1) + regWidth,
      y: y0 + regYGrid * 0 + regHeight / 2,
    },
    {
      x: x0 + regXGrid * (cells.length - 1) + regWidth + regXSpace / 2,
      y: y0 + regYGrid * 0 + regHeight / 2,
    },
    {
      x:
        x0 +
        regXGrid * cells.indexOf(taps[taps.length - 1]) +
        regWidth +
        regXSpace / 2,
      y: y0 + regYGrid * 1 + (regHeight * 3) / 4,
    },
    {
      x: x0 + regXGrid * cells.indexOf(taps[taps.length - 2]) + regWidth,
      y: y0 + regYGrid * 1 + (regHeight * 3) / 4,
    },
  ];
  result += line(points);

  // Intermediate feedback taps
  for (let tapIdx = 0; tapIdx < taps.length - 1; tapIdx++) {
    const tap = taps[tapIdx];
    const points = [
      {
        x: x0 + regXGrid * cells.indexOf(tap) + regWidth + regXSpace / 2,
        y: y0 + regYGrid * 0 + regHeight / 2,
      },
      {
        x: x0 + regXGrid * cells.indexOf(tap) + regWidth + regXSpace / 2,
        y: y0 + regYGrid * 1 + (regHeight * 1) / 4,
      },
      {
        x: x0 + regXGrid * cells.indexOf(tap) + regWidth,
        y: y0 + regYGrid * 1 + (regHeight * 1) / 4,
      },
    ];
    result += line(points, "arrow", "dot");
  }

  // XNOR to XNOR nets
  const numXnor = taps.length - 1;
  for (let i = 0; i < numXnor - 1; i++) {
    const points = [
      {
        x: x0 + regXGrid * cells.indexOf(taps[i + 1]),
        y: y0 + regYGrid * 1 + regHeight / 2,
      },
      {
        x: x0 + regXGrid * cells.indexOf(taps[i]) + regWidth + regXSpace / 2,
        y: y0 + regYGrid * 1 + regHeight / 2,
      },
      {
        x: x0 + regXGrid * cells.indexOf(taps[i]) + regWidth + regXSpace / 2,
        y: y0 + regYGrid * 1 + (regHeight * 3) / 4,
      },
      {
        x: x0 + regXGrid * cells.indexOf(taps[i]) + regWidth,
        y: y0 + regYGrid * 1 + (regHeight * 3) / 4,
      },
    ];
    result += line(points);
  }

  // Last XNOR to first register net
  points = [
    {
      x: x0 + regXGrid * cells.indexOf(taps[0]),
      y: y0 + regYGrid * 1 + regHeight / 2,
    },
    {
      x: x0 - regWidth / 2,
      y: y0 + regYGrid * 1 + regHeight / 2,
    },
    {
      x: x0 - regWidth / 2,
      y: y0 + regYGrid * 0 + regHeight / 2,
    },
    {
      x: x0,
      y: y0 + regYGrid * 0 + regHeight / 2,
    },
  ];
  result += line(points);

  result += "</svg>";
  return result;
}

export default render;
