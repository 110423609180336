"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PaymentDto model module.
 * @module model/PaymentDto
 * @version 1.0.0
 */
class PaymentDto {
  /**
   * Constructs a new <code>PaymentDto</code>.
   * @alias module:model/PaymentDto
   */
  constructor() {
    PaymentDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PaymentDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PaymentDto} obj Optional instance to populate.
   * @return {module:model/PaymentDto} The populated <code>PaymentDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PaymentDto();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('subscription_id')) {
        obj['subscription_id'] = _ApiClient.default.convertToType(data['subscription_id'], 'Number');
      }
      if (data.hasOwnProperty('amount')) {
        obj['amount'] = _ApiClient.default.convertToType(data['amount'], 'String');
      }
      if (data.hasOwnProperty('currency')) {
        obj['currency'] = _ApiClient.default.convertToType(data['currency'], 'String');
      }
      if (data.hasOwnProperty('payout_date')) {
        obj['payout_date'] = _ApiClient.default.convertToType(data['payout_date'], 'String');
      }
      if (data.hasOwnProperty('is_paid')) {
        obj['is_paid'] = _ApiClient.default.convertToType(data['is_paid'], 'Number');
      }
      if (data.hasOwnProperty('is_one_off_charge')) {
        obj['is_one_off_charge'] = _ApiClient.default.convertToType(data['is_one_off_charge'], 'Boolean');
      }
      if (data.hasOwnProperty('receipt_url')) {
        obj['receipt_url'] = _ApiClient.default.convertToType(data['receipt_url'], 'String');
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
PaymentDto.prototype['id'] = undefined;

/**
 * @member {Number} subscription_id
 */
PaymentDto.prototype['subscription_id'] = undefined;

/**
 * @member {String} amount
 */
PaymentDto.prototype['amount'] = undefined;

/**
 * @member {String} currency
 */
PaymentDto.prototype['currency'] = undefined;

/**
 * @member {String} payout_date
 */
PaymentDto.prototype['payout_date'] = undefined;

/**
 * @member {Number} is_paid
 */
PaymentDto.prototype['is_paid'] = undefined;

/**
 * @member {Boolean} is_one_off_charge
 */
PaymentDto.prototype['is_one_off_charge'] = undefined;

/**
 * @member {String} receipt_url
 */
PaymentDto.prototype['receipt_url'] = undefined;
var _default = PaymentDto;
exports.default = _default;