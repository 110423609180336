<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-left">
        <MessageSheet type="success" title="Password reset link sent"
          ><p>
            We have just sent you an e-mail with a link to reset your password.
          </p>
          <p>The link is valid for 24 hours.</p></MessageSheet
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MessageSheet from "@/components/MessageSheet.vue";

export default {
  components: {
    MessageSheet,
  },
};
</script>

<style></style>
