<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- <v-col cols="12" md="6" offset-md="3" class="text-left"> -->
        <v-card class="pa-4 mt-4 mx-auto" max-width="480">
          <v-sheet color="#337AB7" elevation="0" height="5" tile></v-sheet>
          <v-card-title class="text-h4 font-weight-medium">
            Forgot your password?
          </v-card-title>
          <v-card-text class="text-body-1">
            <p>
              Please enter your email address and we'll send you a reset link.
            </p>
            <v-form v-model="form.isValid">
              <v-text-field
                ref="input"
                v-model="form.user.username"
                prepend-icon="mdi-account-circle"
                label="Email address"
                autocomplete="username"
                :rules="emailRules"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="!form.isValid"
              type="submit"
              color="#337AB7"
              :loading="form.loading"
              @click.stop="submit"
              class="ml-2 white--text"
              large
              >Send reset link</v-btn
            >
          </v-card-actions>
          <v-card-text class="text-body-1 mt-2">
            <router-link :to="{ name: 'Login' }">Back to Login</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import global from '@/global/index.js'
import MessageSnack from '@/components/MessageSnack.vue'

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/

  components: {
    MessageSnack
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data() {
    return {
      message: {
        show: false,
        text: ''
      },
      form: {
        loading: false,
        isValid: false,
        user: {
          username: ''
        }
      }
    }
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/

  computed: {
    emailRules() {
      return global.emailRules
    }
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/

  methods: {
    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    // Clicked "Send me a password reset link" button
    submit() {
      var dto = {
        username: this.form.user.username
      }
      this.form.loading = true
      api.auth
        .resetPassword(dto)
        .then(() => {
          this.form.loading = false
          this.$router.push({ name: 'PasswordResetLinkSent' })
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.form.loading = false
          this.showErrorMessage(
            'An error occurred while requesting your password reset link'
          )
        })
    }
  }
}
</script>

<style scoped>
/* avoid line breaks in the middle of a word in card titles */
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
