<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-breadcrumbs
            :items="breadcrumbs"
            divider="/"
            class="pl-4 py-2 my-4"
            large
          ></v-breadcrumbs>
          <v-alert
            dense
            type="info"
            elevation="0"
            outlined
            v-if="isReadOnly || isReadWrite"
          >
            <span v-if="isReadOnly">
              The owner has granted you permission to
              <strong>view</strong> this register map.
            </span>
            <span v-if="isReadWrite">
              The owner has granted you permission to
              <strong>edit</strong> this register map.
            </span>
          </v-alert>

          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h4 font-weight-medium pl-0">
              <!-- ******************************************************************************* -->
              <!-- Name -->
              <!-- ******************************************************************************* -->
              <div>
                <span class="mr-2">{{ registerMap.name }}</span>
                <span class="text-h5">register map</span>
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- ******************************************************************************* -->
            <!-- Upload -->
            <!-- ******************************************************************************* -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-4"
                  outlined
                  medium
                  :disabled="isReadOnly || !allowJsonUpload"
                  v-bind="attrs"
                  v-on="on"
                  @click="onUpload"
                  ><v-icon left> mdi-cloud-upload </v-icon>Upload</v-btn
                >
              </template>
              <span>Upload an update to this register map</span>
            </v-tooltip>
            <!-- ******************************************************************************* -->
            <!-- Run DRCs -->
            <!-- ******************************************************************************* -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  medium
                  class="mr-4"
                  @click="onRunDrcs"
                  v-bind="attrs"
                  v-on="on"
                  >Run DRCs</v-btn
                >
              </template>
              <span>Run the design rule checks</span>
            </v-tooltip>
            <!-- ******************************************************************************* -->
            <!-- Download menu -->
            <!-- ******************************************************************************* -->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  class="white--text mr-4"
                  v-bind="attrs"
                  v-on="on"
                  medium
                >
                  <v-icon left> mdi-cloud-download </v-icon>
                  Download
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <template v-for="(item, index) in downloadMenuItems">
                    <v-divider
                      v-if="index === 13"
                      :key="index + 100"
                    ></v-divider>
                    <v-list-item
                      :key="index"
                      :value="item"
                      @click="generate(item.type)"
                      :disabled="!isDownloadAllowed(item.type)"
                    >
                      <template v-slot:default>
                        <v-list-item-content v-text="item.title">
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <!-- ******************************************************************************* -->
            <!-- Settings menu -->
            <!-- ******************************************************************************* -->
            <v-menu offset-y close-on-click v-model="showSettingsMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="elevation-0" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <template v-for="(item, index) in settingsMenuItems">
                    <v-divider
                      v-if="index === 3"
                      :key="index + 100"
                    ></v-divider>
                    <v-list-item
                      :key="index"
                      :value="item"
                      @click.stop="item.action"
                      :disabled="!isSettingsMenuItemEnabled(item)"
                    >
                      <template v-slot:default>
                        <v-list-item-content v-text="item.title">
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-app-bar>
          <v-divider class="mx-4"></v-divider>
          <!-- ******************************************************************************* -->
          <!-- DRC results -->
          <!-- ******************************************************************************* -->
          <v-banner single-line v-model="showDrcResultBanner" class="mx-4">
            <v-avatar v-if="drcSuccess" slot="icon" color="success" size="30">
              <v-icon color="white"> mdi-check </v-icon>
            </v-avatar>
            <v-avatar v-if="!drcSuccess" slot="icon" color="error" size="40">
              <v-icon color="white"> mdi-message-alert </v-icon>
            </v-avatar>
            <span v-if="drcSuccess"
              >All design rule checks completed successfully!</span
            >
            <span v-if="!drcSuccess">DRC Error: {{ drcErrors }}</span>
            <template v-slot:actions>
              <v-icon @click="showDrcResultBanner = false"> mdi-close </v-icon>
            </template>
          </v-banner>

          <v-card-text class="text-body-1 black--text mt-4">
            <!-- ******************************************************************************* -->
            <!-- Description -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Description</v-col
              >
              <v-col cols="9" class="text-left"
                ><span>{{ registerMap.description || '&ndash;' }}</span>
              </v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Base address -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Default base address</v-col
              >
              <v-col cols="9" class="text-left"
                ><span>
                  <pre>
0x{{ registerMap.baseAddress.toString(16).toUpperCase() }}</pre
                  >
                </span>
              </v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Width -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Register width</v-col
              >
              <v-col cols="9" class="text-left"
                >{{ registerMap.width }} bits</v-col
              >
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Default address width -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Default address width</v-col
              >
              <v-col cols="9" class="text-left"
                >{{ registerMap.addrWidthBits }} bits</v-col
              >
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Code generation options -->
            <!-- ******************************************************************************* -->
            <v-row v-if="codeGenerationOptions.length > 0">
              <v-col cols="3" class="text-left font-weight-bold"
                >Code generation options</v-col>
              <v-col cols="9" class="text-left">
                <v-chip
                label
                small
                class="mr-2"
                v-for="option in codeGenerationOptions"
                :key="option"
                >{{ option }}</v-chip>
              </v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Register count -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Register count</v-col
              >
              <v-col cols="9" class="text-left">{{
                registerMap.registers.length
              }}</v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Range -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Range</v-col>
              <v-col cols="9" class="text-left">{{ range }} bytes</v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Revision -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Revision</v-col
              >
              <v-col cols="9" class="text-left">{{
                registerMap.revision
              }}</v-col>
            </v-row>
            <!-- ******************************************************************************* -->
            <!-- Owner -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Owner</v-col>
              <v-col cols="9" class="text-left">{{
                formatOwner(registerMap)
              }}</v-col>
            </v-row>
          </v-card-text>
          <!-- ******************************************************************************* -->
          <!-- Registers -->
          <!-- ******************************************************************************* -->
          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h5 font-weight-medium">
              Registers
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              autocomplete="off"
              class="shrink"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!-- Button: auto-compute register offets -->
                <v-btn
                  outlined
                  medium
                  class="ml-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="onComputeRegisterOffsets"
                  :disabled="registerMap.registers.length == 0 || isReadOnly"
                  ><v-icon>mdi-refresh-auto</v-icon></v-btn
                >
              </template>
              <span>Auto-compute register offsets</span>
            </v-tooltip>
          </v-app-bar>
          <v-divider class="mx-4"></v-divider>
          <v-data-table
            :headers="registerTableHeaders"
            :items="registerMap.registers"
            :search="search"
            class="elevation-1 mx-4 mt-4 text-body-1 black--text"
            hide-default-footer
            :loading="registersLoading"
            loading-text="Loading..."
            @click:row="onRegisterTableRowClick"
            no-data-text="This register map does not contain any registers yet."
            v-model="selectedRegisters"
            show-select
            disable-pagination
          >
            <!-- Offset column -->
            <template v-slot:item.addressOffset="{ item }">
              <pre>0x{{ item.addressOffset.toString(16).toUpperCase() }}</pre>
            </template>

            <!-- Name column -->
            <template v-slot:item.name="{ item }">
              <span class="font-weight-medium">{{
                item.name + dimension(item)
              }}</span>
            </template>

            <!-- Description column -->
            <template v-slot:item.description="{ item }">
              {{ shorten(item.description || '&ndash;') }}
            </template>

            <!-- Type column -->
            <template v-slot:item.type="{ item }">
              {{ type(item) }}
            </template>

            <!-- Actions column -->
            <template v-slot:item.actions="{ item }">
              <v-icon
                dense
                class="mr-3"
                @click.stop="onEditRegister(item)"
                :disabled="isReadOnly"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                dense
                class="mr-3"
                @click.stop="onDuplicateRegister(item)"
                :disabled="isReadOnly"
              >
                mdi-content-copy
              </v-icon>
              <v-icon
                dense
                @click.stop="onDeleteRegister(item)"
                :disabled="isReadOnly"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-card-actions class="mt-4 mb-10 mx-2">
            <v-btn
              small
              v-show="selectedRegisters.length > 0"
              @click="onDeleteSelectedRegisters"
              >Delete selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-tooltip v-model="showNewRegisterTooltip" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  fab
                  dark
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="onNewRegister"
                  :disabled="isReadOnly"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Create new register</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- ******************************************************************************* -->
    <!-- Sharing dialog -->
    <!-- ******************************************************************************* -->

    <v-dialog v-model="shareRegisterMapDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Share Register Map</span>
          <v-spacer></v-spacer>
          <v-icon @click="shareRegisterMapDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text> </v-card-text>
        <v-card-text>
          <v-form
            ref="shareRegisterMapForm"
            v-model="shareRegisterMapForm.isValid"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  autocomplete="off"
                  ref="fieldUserName"
                  v-model="shareRegisterMapForm.newPermissionUserName"
                  label="E-mail address"
                  :rules="emailRules"
                  outlined
                  dense
                  class="mb-4"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="shareRegisterMapForm.newPermission"
                  :items="permissionSelectItems"
                  item-text="text"
                  item-value="value"
                  label="Permission"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-spacer></v-spacer>
                <v-btn
                  @click="onNewPermissionAdd"
                  color="#337AB7"
                  class="white--text"
                  :disabled="!shareRegisterMapForm.isValid"
                  >Add</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="mb-4">
              <v-data-table
                :headers="permissionTableHeaders"
                :items="registerMapPermissions"
                class="elevation-1 mx-0 mt-4 text-body-1 black--text"
                hide-default-footer
                no-data-text="This register map is not shared with anyone."
                disable-pagination
              >
                <!-- Actions column -->
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    dense
                    @click.stop="deletePermission(item)"
                    v-if="item.permission !== 'ADMIN'"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirmationDialog" />
    <RegisterDialog ref="registerDialog" />
    <RegisterMapDialog ref="registerMapDialog" />
    <PermissionDialog ref="permissionDialog" />
    <UploadDialog ref="uploadDialog" />
    <TransferOwnershipDialog ref="transferOwnershipDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import axios from 'axios'
import utils from '@/global/utils.js'
import { mapGetters } from 'vuex'
import global from '@/global/index.js'
import api from '@/services/ApiService.js'
import UploadDialog from '@/components/UploadDialog.vue'
import TransferOwnershipDialog from '@/components/TransferOwnershipDialog.vue'
import MessageSnack from '@/components/MessageSnack.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import RegisterDialog from '@/components/RegisterDialog.vue'
import RegisterMapDialog from '@/components/RegisterMapDialog.vue'
import PermissionDialog from '@/components/PermissionDialog.vue'
import settings from '@/json/settings.json'

export default {
  /************************************************************************************************/
  components: {
    ConfirmDialog,
    RegisterDialog,
    RegisterMapDialog,
    PermissionDialog,
    UploadDialog,
    TransferOwnershipDialog,
    MessageSnack,
  },
  /************************************************************************************************/
  data() {
    return {
      showSettingsMenu: false,
      registersLoading: false,
      showDrcResultBanner: false,
      drcSuccess: true,
      drcErrors: '',
      registerMapId: 0,
      timer: null,
      shareRegisterMapDialog: false,
      selectedRegisters: [],
      reloadTimer: null,
      registerMapPermissions: [],
      showNewRegisterTooltip: false,
      search: '',
      autoReload: true, // periodically reload register map using reloadTimer

      shareRegisterMapForm: {
        isValid: false,
        newPermissionUserName: '',
        newPermission: '',
      },

      message: {
        show: false,
        text: '',
      },

      permissionSelectItems: [
        { text: 'read-only', value: 'READ' },
        { text: 'read-write', value: 'READ_WRITE' },
      ],

      settingsMenuItems: [
        {
          name: 'editRegisterMap',
          title: 'Edit Register Map',
          action: this.onEditRegisterMap,
        },
        {
          name: 'shareRegisterMap',
          title: 'Share Register Map',
          action: this.onShareRegisterMap,
        },
        {
          name: 'transferRegisterMap',
          title: 'Transfer Ownership',
          action: this.ontransferRegisterMap,
        },
        {
          name: 'deleteRegisterMap',
          title: 'Delete Register Map',
          action: this.deleteRegisterMap,
        },
      ],

      downloadMenuItems: [
        { title: 'C Header', type: 'cHeader' },
        { title: 'C++ Header', type: 'cppHeader' },
        { title: 'SystemVerilog Package', type: 'svPackage' },
        { title: 'SystemVerilog Module', type: 'svModule' },
        { title: 'SystemVerilog Testbench', type: 'svTestbench' },
        { title: 'VHDL Package', type: 'vhdlPackage' },
        { title: 'VHDL Component', type: 'vhdlComponent' },
        { title: 'VHDL Instantiation Template', type: 'vhdlInstance' },
        { title: 'VHDL Testbench', type: 'vhdlTestbench' },
        { title: 'HTML Documentation', type: 'htmlDoc' },
        { title: 'Markdown Documentation', type: 'mdDoc' },
        { title: 'IP-XACT XML', type: 'ipxact' },
        { title: 'JSON', type: 'json' },
        { title: 'All Files', type: 'all' },
      ],

      registerMap: {
        id: 0,
        description: '',
        baseAddress: 0,
        width: 0,
        revision: 0,
        registers: [],
      },

      permissionTableHeaders: [
        {
          text: 'User',
          align: 'start',
          sortable: false,
          value: 'userName',
        },
        {
          text: 'Permission',
          align: 'start',
          sortable: false,
          value: 'permission',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
      ],

      registerTableHeaders: [
        /*
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },*/
        {
          text: 'Offset',
          align: 'start',
          sortable: false,
          value: 'addressOffset',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '10em',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '100%',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Access',
          align: 'start',
          sortable: false,
          value: 'access',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em', // make sure icons don't wrap
        },
      ],

      breadcrumbs: [
        {
          text: 'Register Maps',
          disabled: false,
          to: { name: 'RegisterMaps' },
        },
        {
          text: 'Register Map',
          disabled: false,
        },
      ],
    }
  },

  /************************************************************************************************/
  created() {
    // REVIEW: handle missing register map
    this.registerMapId = this.$route.params.registerMapId
    this.loadRegisterMap()
    this.reloadTimer = setInterval(
      this.onReloadTimer,
      settings.autoReloadPeriodSec * 1000
    )
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************
   * Watches
   ************************************************************************************************/

  watch: {
    registerMap: function () {
      this.initBreadCrumbs()
    },
  },

  /************************************************************************************************/
  computed: {
    ...mapGetters([
      'allowXmlDownload',
      'allowTestbenchDownload',
      'allowJsonUpload',
      'allowShareRegisterMaps',
    ]),

    codeGenerationOptions() {
      let result = []
      if(this.registerMap.generateRecordPorts) {
        result.push("record ports")
      }
      if(this.registerMap.generatePackedStructs) {
        result.push("packed structs")
      }
      if(this.registerMap.disableTimestamps) {
        result.push("disable timestamps")
      }
      return result
    },

    isReadOnly() {
      return this.registerMap.permission === 'READ'
    },

    isReadWrite() {
      return this.registerMap.permission === 'READ_WRITE'
    },

    isAdmin() {
      return this.registerMap.permission === 'ADMIN'
    },

    registerMapDescriptionRules() {
      return global.registerMapDescriptionRules
    },

    baseAddressRules() {
      return global.baseAddressRules
    },

    objectNameRules() {
      return global.objectNameRules
    },

    // Returns the next available address offset for a register
    nextAddressOffset() {
      return global.nextAddressOffset(
        this.registerMap.registers,
        this.registerMap.width
      )
    },

    range() {
      return global.range(this.registerMap, this.registerMap.registers)
    },

    emailRules() {
      return global.emailRules
    },
  },

  /************************************************************************************************/
  methods: {
    shorten(description) {
      return utils.shorten(description, settings.maxDescriptionLength)
    },

    isSettingsMenuItemEnabled(item) {
      if (this.isReadOnly) {
        return false
      }
      if (item.name === 'shareRegisterMap' && !this.isAdmin) {
        return false
      }
      if (item.name === 'shareRegisterMap' && !this.allowShareRegisterMaps) {
        return false
      }
      if (item.name === 'deleteRegisterMap' && !this.isAdmin) {
        return false
      }
      if (item.name === 'transferRegisterMap' && !this.isAdmin) {
        return false
      }
      if (item.name === 'transferRegisterMap' && !this.allowShareRegisterMaps) {
        return false
      }
      return true
    },

    isDownloadAllowed(type) {
      if (type === 'ipxact' && !this.allowXmlDownload) {
        return false
      }
      if (type === 'vhdlTestbench' && !this.allowTestbenchDownload) {
        return false
      }
      if (type === 'svTestbench' && !this.allowTestbenchDownload) {
        return false
      }
      return true
    },

    // Delete a single register
    deleteRegister(register) {
      return api.registerMaps
        .deleteRegister(this.registerMapId, register.id)
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked the "delete selected registers" button
    async onDeleteSelectedRegisters() {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the selected register(s)? This action cannot be undone.`
        )
      ) {
        let results = []
        for (var i = 0; i < this.selectedRegisters.length; i++) {
          results.push(this.deleteRegister(this.selectedRegisters[i]))
        }
        Promise.all(results)
          .then(() => {
            this.loadRegisterMap()
          })
          .catch((error) => {
            this.showErrorMessage(error.body.message)
          })
        this.selectedRegisters = []
      }
    },

    initBreadCrumbs() {
      this.breadcrumbs = [
        {
          text: 'Register Maps',
          to: { name: 'RegisterMaps' },
          exact: true,
        },
        {
          text: this.registerMap.name,
          to: {
            name: 'RegisterMap',
            params: { registerMapId: this.registerMapId },
          },
          exact: true,
        },
      ]
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    // Clicked "upload" button
    onUpload() {
      this.$refs.uploadDialog
        .open()
        .then((file) => {
          var opts = {
            body: file,
            registerMapId: this.registerMapId,
          }
          api.registerMaps
            .uploadRegisterMap(opts)
            // upload completed successfully
            .then(() => {
              this.loadRegisterMap()
            })
            // upload error
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        // dialog closed with 'Cancel'
        .catch(() => {})
    },

    // Clicked Settings -> Transfer Register Map Ownership
    ontransferRegisterMap() {
      this.$refs.transferOwnershipDialog
        .open(this.registerMap)
        .then((username) => {
          // transmit only the properties that can be modified in the dialog
          var registerMapDto = {
            owner: username,
          }
          // Disable register map auto-reloading while the ownership is being transferred to
          // prevent "unauthorized access" errors due to automatic reloading.
          this.autoReload = false
          api.registerMaps
            .saveRegisterMap(this.registerMapId, registerMapDto)
            .then(() => {
              // Redirect to register maps view
              this.$router.push({ name: 'RegisterMaps' })
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
              this.autoReload = true
            })
        })
        // dialog closed with 'Cancel'
        .catch(() => {})
    },

    // Clicked settings -> share register map
    onShareRegisterMap() {
      this.shareRegisterMapForm.newPermissionUserName = ''
      this.shareRegisterMapForm.newPermission =
        this.permissionSelectItems[0].value
      // Make a copy of the current permissions, so that the register map refreshes
      // don't mess up the edited permission list.
      this.registerMapPermissions = Object.assign(
        [],
        this.registerMap.permissions
      )
      this.shareRegisterMapDialog = true
      setTimeout(() => {
        this.$refs.shareRegisterMapForm.resetValidation()
        this.$refs.fieldUserName.focus()
      }, 0)
    },

    // Clicked settings -> edit register map
    onEditRegisterMap() {
      this.$refs.registerMapDialog
        .open(this.registerMap)
        .then((registerMap) => {
          // Transmit only the properties that can be modified in the dialog
          // NOTE: update onEditRegisterMap in RegisterMaps.vue if changing
          // anything in the DTO.
          const registerMapDto = {
            id: registerMap.id,
            name: registerMap.name,
            description: registerMap.description,
            baseAddress: registerMap.baseAddress,
            addrWidthBits: registerMap.addrWidthBits,
            generateRecordPorts: registerMap.generateRecordPorts,
            generatePackedStructs: registerMap.generatePackedStructs,
            disableTimestamps: registerMap.disableTimestamps
          }
          api.registerMaps
            .saveRegisterMap(this.registerMapId, registerMapDto)
            .then(() => {
              this.loadRegisterMap()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    formatOwner(registerMap) {
      let ownerUserName = ''
      if ('owner' in registerMap) {
        ownerUserName = registerMap.owner.toLowerCase()
      }
      var myUserName = this.$store.state.user.username.toLowerCase()
      if (ownerUserName === myUserName) {
        return registerMap.owner + ' (you)'
      }
      return registerMap.owner
    },

    // Download a generated file of a given type (e.g. 'vhdlPackage')
    generate(type) {
      this.hideDrcs()
      if (type === 'all') {
        // Use axios to download binary files as OpenAPI Generator client does not seem to work with them
        const token = api.getJWT()
        const basePath = api.getBasePath() // e.g. http://localhost:8082/api
        const config = {
          // Note: when adding application/json, the response.data becomes a string and the 
          // downloaded zip-file is corrupted. So we cannot use:
          // responseType: 'arraybuffer; application/json',
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 10000
        }
        axios
          .get(
            `${basePath}/registermaps/${this.registerMapId}/download?type=all`,
            config
          )
          .then((response) => {
              console.log(`response type: ${typeof response.data}`)
              console.log(response)
              // Extract filename from content-disposition header
              var contentDisposition = response.headers['content-disposition']
              // e.g. "attachment; filename=map4_regs_pkg.vhd"
              var pattern = /filename=([a-zA-Z0-9_.]+)/
              var match = pattern.exec(contentDisposition)
              var filename = match[1]
              // Note: blobs are file-like objects of immutable, raw data; they can be read as text or binary data.
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = filename
              link.click()
              URL.revokeObjectURL(link.href)
          })
          .catch((error) => {
            // Possible errors (see: https://stackoverflow.com/a/63743705/63730)
            // 1. Wrong request: something is wrong with the request                             -> error.message
            // 2. Bad network request: server does not respond at all (wrong URL or server down) -> error.request
            // 3. Error status: most common, with any request that does not return 200.          -> error.response.data, status, headers
            // ---
            if(error.response) {
              if(error.response.status === 500) { // 500 means one or more DRC error(s) occurred
                const utf8decoder = new TextDecoder()
                const data = utf8decoder.decode(error.response.data)
                const response = JSON.parse(data) 
                this.showDrcs(false, response.failedDrcs)
              } else {
                this.showErrorMessage(error.message)
              }
            } else if (error.request) {
              // Client never received a response, or request never left
              this.showErrorMessage(error.message)
            } else if (error.message) {
              // Bad request on the client side
              this.showErrorMessage(error.message)
            } else {
              // Anything other error
              this.showErrorMessage('download error')
            }
          })
      } else {
        // Download a single file
        var opts = {
          type: type,
        }
        api.registerMaps
          .downloadRegisterMapWithHttpInfo(this.registerMapId, opts)
          .then(function (response_and_data) {
            //console.log(response_and_data)
            //console.log(JSON.stringify(response_and_data))
            console.log("DRC passed")
            // Extract filename from content-disposition header
            var contentDisposition =
              response_and_data.response.headers['content-disposition']
            // e.g. "attachment; filename=map4_regs_pkg.vhd"
            var pattern = /filename=([a-zA-Z0-9_.]+)/
            var match = pattern.exec(contentDisposition)
            var filename = match[1]
            // Instruct the browser to download the file
            const blob = new Blob([response_and_data.data], {
              type: 'application/octet-stream',
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = filename
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .catch((error) => {
            console.warn(error)
            if (error.status === 403) {
              this.$store.dispatch('logout')
              return // don't show error message
            } else if(error.status === 500) {
              this.showDrcs(false, error.body.failedDrcs)
            } else {
              this.showErrorMessage(error.body.message)
            }
          })
      }
    },

    hideDrcs() {
      this.showDrcResultBanner = false
    },

    showDrcs(isSuccess, errors) {
      console.log("showDrcs")
      this.showDrcResultBanner = true
      this.drcSuccess = isSuccess
      if (isSuccess) {
        this.drcErrors = ''
      } else {
        this.drcErrors = errors[0].message
      }
    },

    onRunDrcs() {
      this.hideDrcs()
      api.registerMaps
        .runDrcs(this.registerMapId)
        .then((response) => {
          if (response.length === 0) {
            this.showDrcs(true, null)
          } else {
            this.showDrcs(false, response)
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    onRegisterTableRowClick(item) {
      this.$router.push({
        name: 'Register',
        params: { registerMapId: this.registerMapId, registerId: item.id },
      })
    },

    // Returns the dimension string for an array or a memory, e.g. '[10]'
    dimension(item) {
      if (item.type === 'RegisterArray') {
        return ` [${item.arrayLength}]`
      } else if (item.type === 'Memory') {
        return ` [${item.depth}]`
      }
      return ''
    },

    // Returns the type string for a register map element
    type(item) {
      if (item.type === 'RegisterArray') {
        return 'Array'
      }
      return item.type
    },

    async onDeleteRegister(register) {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the register <code>${register.name}</code>? This action cannot be undone.`
        )
      ) {
        api.registerMaps
          .deleteRegister(this.registerMapId, register.id)
          .then(() => {
            this.loadRegisterMap()
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },

    // Click "edit register" icon
    onEditRegister(register) {
      this.$refs.registerDialog
        .open(null, register)
        .then((result) => {
          return api.registerMaps
            .saveRegister(this.registerMapId, register.id, result)
            .then(() => {
              this.loadRegisterMap()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    onDuplicateRegister(item) {
      // Get the full register object (including fields)
      var opts = { includeFields: true }
      api.registerMaps
        .getRegister(this.registerMapId, item.id, opts)
        .then((register) => {
          register.name += ' - COPY'
          api.registerMaps
            .createRegister(this.registerMapId, register)
            .then(() => {
              this.loadRegisterMap()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked "delete permission" item
    deletePermission(item) {
      var permissionDto = {
        userName: item.userName,
        permission: null, // no permission
      }
      api.registerMaps
        .setPermission(this.registerMapId, permissionDto)
        .then(() => {
          this.loadRegisterMapPermissions()
          // Reset the permission form
          this.shareRegisterMapForm.newPermissionUserName = ''
          this.shareRegisterMapForm.newPermission =
            this.permissionSelectItems[0].value
          this.$refs.shareRegisterMapForm.resetValidation()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Reload register map (called by periodic timer)
    onReloadTimer() {
      if (this.autoReload) {
        this.loadRegisterMap(false)
      }
    },

    // Load register map, registers and permissions from backend
    loadRegisterMap(/*showProgress*/) {
      // Load register map
      api.registerMaps
        .getRegisterMap(this.registerMapId, { includeRegisters: true })
        .then((response) => {
          console.log("loadRegisterMap")
          console.log(response.disableTimestamps)
          this.registerMap = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          } else if (error.status === 400) {
            // access denied
            this.$router.push({ name: 'Dashboard' })
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Load register map permissions from the backend
    loadRegisterMapPermissions() {
      api.registerMaps
        .getPermissions(this.registerMapId)
        .then((response) => {
          this.registerMapPermissions = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    hideNewRegisterTooltip() {
      setTimeout(() => {
        this.showNewRegisterTooltip = false
      }, 0)
    },

    // Clicked the "Create new register" button
    onNewRegister() {
      this.$refs.registerDialog
        .open(this.nextAddressOffset, null)
        .then((result) => {
          this.hideNewRegisterTooltip()
          api.registerMaps
            .createRegister(this.registerMapId, result)
            .then(() => {
              /* Created register successfully */
              this.loadRegisterMap()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
          this.hideNewRegisterTooltip()
        })
    },

    // Clicked "add new permission" button in the dialog
    onNewPermissionAdd() {
      var permissionDto = {
        userName: this.shareRegisterMapForm.newPermissionUserName,
        permission: this.shareRegisterMapForm.newPermission,
      }
      api.registerMaps
        .setPermission(this.registerMapId, permissionDto)
        .then(() => {
          this.loadRegisterMapPermissions()
          // Reset the permission form
          this.shareRegisterMapForm.newPermissionUserName = ''
          this.shareRegisterMapForm.newPermission =
            this.permissionSelectItems[0].value
          this.$refs.shareRegisterMapForm.resetValidation()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked the "delete register map" menu item
    async deleteRegisterMap() {
      this.showSettingsMenu = false
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the register map <code>${this.registerMap.name}</code>? This action cannot be undone.`
        )
      ) {
        api.registerMaps
          .deleteRegisterMap(this.registerMap.id)
          .then(() => {
            this.$router.push({ name: 'RegisterMaps' })
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },

    // Clicked the "auto-compute register offsets" button
    async onComputeRegisterOffsets() {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          'Are you sure you want to re-compute all register offsets? This action cannot be undone.'
        )
      ) {
        api.registerMaps
          .computeRegisterOffsets(this.registerMap.id)
          .then(() => {
            this.loadRegisterMap()
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table-header {
  background-color: #f5f5f5;
}
</style>

<style>
.col {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
