<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">Register Calculator</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="2" class="px-2">
              <v-select
                :items="radixItems"
                label="Radix"
                outlined
                dense
                hide-details
                v-model="radix"
                @change="onRadixChange"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text>
        <v-simple-table ref="table" dense>
          <template v-slot:default>
            <tbody>
              <template v-for="laneIdx in laneIndexes">
                <tr :key="'row0' + laneIdx">
                  <td
                    v-for="bit in bits(laneIdx)"
                    :key="bit"
                    align="center"
                    width="6.25%"
                    class="text-body-2"
                  >
                    {{ bit }}
                  </td>
                </tr>
                <tr :key="'row1' + laneIdx">
                  <template v-for="(cell, index) in cells[laneIdx]">
                    <td
                      :key="index"
                      class="py-2 px-2"
                      :colspan="cell.bitWidth"
                      align="center"
                      v-if="cell.field"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            hide-details
                            :label="cell.field.name"
                            v-model="cell.field.value"
                            @blur="onFieldBlur(cell)"
                            @input="onFieldInput(cell)"
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <span>{{ cell.field.name }}</span>
                      </v-tooltip>
                    </td>
                    <td
                      :key="index"
                      :colspan="cell.bitWidth"
                      align="center"
                      style="background-color: #ddd"
                      v-else
                    >
                      &nbsp;
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="5" class="px-2">
              <v-text-field
                autocomplete="off"
                label="Value"
                outlined
                dense
                :prefix="valuePrefix"
                v-model="registerValue"
                @input="onValueInput"
                ref="valueTextField"
              ></v-text-field>
            </v-col>
            <v-col cols="7" class="py-2">
              <v-btn small @click="onClear">Set to Zero</v-btn
              ><v-btn small class="ml-4" @click="onReset()"
                >Set to Reset Value</v-btn
              ><v-btn small class="ml-4" @click="onCopyToClipboard"
                >Copy to clipboard</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  created() {
    for (var i = 0; i < this.laneCount; i++) {
      this.laneIndexes.push(i)
    }
    for (i = 0; i < this.widthBits; i++) {
      this.regBits.push(0)
    }
  },

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      register: null,
      laneCount: 2,
      laneIndexes: [], // e.g. [0, 1]
      widthBits: 32,
      cells: [],
      registerValue: '0',
      regBits: [],
      model: '0',
      timer: null,
      radixItems: ['hex', 'dec', 'bin'],
      radix: 'hex',
    }
  },

  /************************************************************************************************/

  methods: {
    onRadixChange() {
      this.updateRegisterValueInput(this.regBits, this.radix)
      this.updateFieldInputs(this.regBits, this.radix)
    },

    // Copy register value to clipboard
    onCopyToClipboard() {
      console.log(`Copy to clipboard: ${this.registerValue}`)

      // from: https://stackoverflow.com/a/61503961/63730
      navigator.clipboard.writeText(this.registerValue)

      // Alternative:
      // from: https://stackoverflow.com/questions/22581345
      /*
      this.$refs.valueTextField.focus()
      let textToCopy = this.$refs.valueTextField.$el.querySelector('input')
      textToCopy.select()
      document.execCommand('copy')
      */
    },

    // Set the register to a given value
    // @note updates this.regBits
    setRegisterValue(value) {
      for (var i = 0; i < this.regBits.length; i++) {
        this.regBits[i] = (value / 2 ** i) & 0x1
      }
      this.updateRegisterValueInput(this.regBits, this.radix)
      this.updateFieldInputs(this.regBits, this.radix)
    },

    // Get the value of a given field
    getFieldValue(field, regBits) {
      let value = 0
      for (
        var bitIdx = field.bitOffset;
        bitIdx < field.bitOffset + field.bitWidth;
        bitIdx++
      ) {
        if (regBits[bitIdx] === 'X') {
          value = NaN
          break
        } else if (regBits[bitIdx] === 1) {
          let fieldBitIdx = bitIdx - field.bitOffset
          value += 2 ** fieldBitIdx
        }
      }
      return value
    },

    // Update field inputs according to a given register value
    updateFieldInputs(regBits, radix) {
      // update field inputs
      for (
        var fieldIdx = 0;
        fieldIdx < this.register.fields.length;
        fieldIdx++
      ) {
        let field = this.register.fields[fieldIdx]
        let fieldValue = this.getFieldValue(field, regBits)
        let digits = 0
        if (radix === 'bin') {
          digits = field.bitWidth
        }
        field.value = this.formatValue(fieldValue, radix, digits)
      }
    },

    // Changed the value field
    onValueInput() {
      console.log('onValueInput')
      let value = this.parseInput(this.registerValue, this.radix)
      // update register bits
      for (var i = 0; i < this.regBits.length; i++) {
        if (isNaN(value)) {
          this.regBits[i] = 'X'
        } else {
          this.regBits[i] = (value / 2 ** i) & 0x1
        }
      }
      this.updateFieldInputs(this.regBits, this.radix)
    },

    // Field lost focus -> cleanup the field value
    onFieldBlur(cell) {
      //console.log('onFieldBlur')
      if (cell.field.value.trim() === '') {
        // Field empty -> set to zero
        cell.field.value = '0'
      } else {
        // Remove surrounding whitespaces
        cell.field.value = cell.field.value.trim()
      }
      this.onFieldInput(cell)
    },

    // Entered a new field value
    onFieldInput(cell) {
      //console.log('onFieldInput')
      //console.log(cell.field.value)
      let field = cell.field
      let value = this.parseInput(field.value, this.radix)
      //console.log(value)

      // Update register bits
      for (
        var i = field.bitOffset;
        i <= field.bitOffset + field.bitWidth - 1;
        i++
      ) {
        var zeroBasedBitOffset = i - field.bitOffset
        if (isNaN(value)) {
          this.regBits[i] = 'X'
        } else {
          this.regBits[i] = (value / 2 ** zeroBasedBitOffset) & 0x1
        }
      }

      // Update register value
      this.updateRegisterValueInput(this.regBits, this.radix)
    },

    // Clicked the "clear" button
    onClear() {
      this.setRegisterValue(0)
    },

    // Parse string input to integer
    parseInput(str, radix) {
      let strTrim = str.trim() // remove surrounding whitespaces
      var result = 0
      if (strTrim === '') {
        result = NaN
      } else if (radix === 'hex') {
        result = parseInt(strTrim, 16)
      } else if (radix === 'bin') {
        result = parseInt(strTrim, 2)
      } else {
        result = parseInt(strTrim)
      }
      return result
    },

    // Get bit at given index from value
    getBit(value, idx) {
      let bit = value / 2 ** idx
      bit &= 0x1
      return bit
    },

    // Compute register reset value
    onReset() {
      // Compute the register reset value
      let resetValue = 0
      for (
        var fieldIdx = 0;
        fieldIdx < this.register.fields.length;
        fieldIdx++
      ) {
        var field = this.register.fields[fieldIdx]
        resetValue += field.reset * 2 ** field.bitOffset
      }

      // Update the register value
      this.setRegisterValue(resetValue)
    },

    // lane 0: [31, 30, ... 16]
    // lane 1: [15, 14, ... 0]
    bits(lane) {
      let laneRev = this.laneCount - lane - 1
      let bitLow = laneRev * this.bitsPerLane
      let bitHigh = bitLow + this.bitsPerLane - 1
      let result = []
      for (var bit = bitHigh; bit >= bitLow; bit--) {
        result.push(bit)
      }
      return result
    },

    // Compute the list of table cells for a given lane
    computeCells(laneIdx) {
      let cells = []
      let laneLowBitIdx = (this.laneCount - laneIdx - 1) * this.bitsPerLane
      let laneHighBitIdx = laneLowBitIdx + this.bitsPerLane - 1
      let currField = null
      let currFieldBits = 0
      for (var bitIdx = laneHighBitIdx; bitIdx >= laneLowBitIdx; bitIdx--) {
        if (currField != null) {
          currFieldBits += 1
        } else {
          for (
            var testFieldIdx = 0;
            testFieldIdx < this.register.fields.length;
            testFieldIdx++
          ) {
            var testField = this.register.fields[testFieldIdx]
            // does this bit belong to any fields?
            if (
              bitIdx >= testField.bitOffset &&
              bitIdx < testField.bitOffset + testField.bitWidth
            ) {
              currField = testField
              currFieldBits = 1
              break
            }
          }
          if (currField == null) {
            // this bit doesn't belong to any fields
            cells.push({ field: null, bitWidth: 1 })
          }
        }
        if (currField != null) {
          if (bitIdx === laneLowBitIdx || currField.bitOffset === bitIdx) {
            // field ends on this bit
            cells.push({ field: currField, bitWidth: currFieldBits })
            currField = null
          }
        }
      }
      return cells
    },

    // Update register value input control according to register bits
    // @note modifies this.registerValue
    updateRegisterValueInput(regBits, radix) {
      //console.log('updateRegisterValueInput ' + regBits + radix)
      // Compute numeric register value
      var regValue = 0
      for (var i = 0; i < this.widthBits; i++) {
        if (regBits[i] === 'X') {
          this.registerValue = '?'
          return
        } else if (regBits[i] === 1) {
          regValue += 2 ** i
        }
      }

      // Convert register value to string
      var digits = 0
      if (radix === 'hex') {
        digits = 8
      } else if (radix === 'bin') {
        digits = 32
      }
      var strRegValue = this.formatValue(regValue, radix, digits)

      // Update register value input
      this.registerValue = strRegValue
    },

    // Format value according to the given radix
    // e.g. formatValue(15, 'hex', 8) -> "0000000F"
    formatValue(value, radix, digits = 0) {
      if (isNaN(value)) {
        return '?'
      }
      var strRegValue
      if (radix === 'hex') {
        strRegValue = value.toString(16)
      } else if (radix === 'dec') {
        strRegValue = value.toString(10)
      } else {
        strRegValue = value.toString(2)
      }
      strRegValue = strRegValue.toUpperCase()
      if (digits > 0 && strRegValue.length < digits) {
        let leadingZeroCount = digits - strRegValue.length
        for (var i = 0; i < leadingZeroCount; i++) {
          strRegValue = '0' + strRegValue
        }
      }
      return strRegValue
    },

    open(register) {
      this.radix = 'hex'

      // Make a copy of the register
      this.register = JSON.parse(JSON.stringify(register))

      // Initialize table cells based on register fields
      this.cells = []
      for (var laneIdx = 0; laneIdx < this.laneCount; laneIdx++) {
        this.cells.push(this.computeCells(laneIdx))
      }

      // Set initial register value
      this.setRegisterValue(0x0)

      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve()
    },

    validate() {},
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {
    bitsPerLane() {
      return this.widthBits / this.laneCount
    },

    valuePrefix() {
      if (this.radix === 'hex') {
        return '0x'
      } else if (this.radix === 'bin') {
        return '0b'
      } else {
        return ''
      }
    },
  },
}
</script>

<style></style>
