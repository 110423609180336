<template>
  <v-container fluid>
    <v-sheet max-width="1250" class="mx-auto">
      <v-row class="text-center" dense>
        <!-- *********************************************************************************** -->
        <!-- Main -->
        <!-- *********************************************************************************** -->
        <v-col cols="12" class="mb-0">
          <p class="text-h4 text-md-h3 font-weight-bold mt-md-10">
            Register Management Done Right
          </p>

          <p class="text-h5 font-weight-regular mt-6">
            Create AXI4 register maps for your FPGA or ASIC project <br />in the
            browser and download the generated code.
          </p>
          <p>
            <v-btn
              :to="{ name: 'Signup' }"
              large
              color="#337AB7"
              class="white--text mt-4"
              >Try it now for free</v-btn
            >
          </p>
        </v-col>

        <v-col cols="8" offset="2">
          <video
            id="player"
            controls
            playsinline
            data-poster="https://airhdl.com/assets/airhdl-intro-poster.png"
          >
            <source
              src="https://airhdl.com/assets/airhdl-intro.mp4"
              type="video/mp4"
            />
          </video>
        </v-col>

        <!-- *********************************************************************************** -->
        <!-- Testimonials -->
        <!-- *********************************************************************************** -->
        <v-col cols="12">
          <p class="text-h5 font-weight-bold mt-8">
            Trusted by 2000+ developers worldwide
          </p>

          <v-col cols="12">
            <v-carousel
              v-model="model"
              height="300"
              light
              show-arrows-on-hover
              hide-delimiters
              hide-delimiter-background
              cycle
              interval="12000"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-carousel-item v-for="i in testimonials.length / 3" :key="i">
                <v-row class="fill-height" align="center" justify="center">
                  <v-col
                    v-for="testimonial in testimonials.slice(
                      (i - 1) * 3,
                      i * 3
                    )"
                    :key="testimonial.name"
                    cols="4"
                  >
                    <v-card height="240" outlined>
                      <v-card-title class="justify-center">
                        <v-icon large>mdi-account-circle</v-icon>
                      </v-card-title>
                      <v-card-text class="py-0 font-weight-medium body-1">
                        {{ testimonial.name }}
                      </v-card-text>
                      <v-card-text class="py-0 body-1">
                        {{ testimonial.company }}
                      </v-card-text>
                      <v-card-text class="body-1">
                        "{{ testimonial.text }}"
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
            <v-carousel
              v-model="model"
              height="275"
              light
              show-arrows-on-hover
              hide-delimiters
              hide-delimiter-background
              cycle
              interval="12000"
              v-if="$vuetify.breakpoint.mobile"
            >
              <v-carousel-item
                v-for="(testimonial, i) in testimonials"
                :key="i"
              >
                <v-card height="275" outlined>
                  <v-card-title class="justify-center">
                    <v-icon large>mdi-account-circle</v-icon>
                  </v-card-title>
                  <v-card-text class="py-0 font-weight-medium body-1">
                    {{ testimonial.name }}
                  </v-card-text>
                  <v-card-text class="py-0 body-1">
                    {{ testimonial.company }}
                  </v-card-text>
                  <v-card-text class="body-1">
                    "{{ testimonial.text }}"
                  </v-card-text>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-col>
        <!-- *********************************************************************************** -->
        <!-- Features -->
        <!-- *********************************************************************************** -->
        <v-col cols="12">
          <p class="text-h5 font-weight-bold mt-0">Featured in</p>
          <v-item-group class="mb-8">
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="feature in features"
                  :key="feature.link"
                  cols="12"
                  sm="4"
                >
                  <v-item>
                    <a :href="feature.link" target="_blank">
                      <v-img
                        class="mx-auto"
                        max-height="75"
                        max-width="200"
                        :src="feature.src"
                        eager
                      >
                      </v-img>
                    </a>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-col>
      </v-row>
    </v-sheet>
    <!-- see https://github.com/apertureless/vue-cookie-law -->
    <cookie-law
      theme="dark-lime"
      buttonText="Got it!"
      buttonClass="CookieButton"
    >
      <div slot="message">
        This website uses cookies to ensure you get the best experience. Please
        review our <router-link to="Privacy">Privacy Policy</router-link> for
        additional information.
      </div>
    </cookie-law>
  </v-container>
</template>

<script>
import testimonials from '@/json/testimonials.json'
import CookieLaw from 'vue-cookie-law'
import Plyr from 'plyr'

export default {
  name: 'Home',

  components: {
    CookieLaw,
  },

  methods: {},

  created() {},

  mounted() {
    // Load cloudflare analytics script
    let script = document.createElement('script')
    script.setAttribute(
      'src',
      'https://static.cloudflareinsights.com/beacon.min.js'
    )
    script.setAttribute(
      'data-cf-beacon',
      '{"token": "0a4483a5f08642f088246f35223b237f"}'
    )
    script.setAttribute('defer', true)
    document.head.appendChild(script)

    // Init video player
    this.player = new Plyr('#player', {
      title: 'Example Title',
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'settings',
        'airplay',
        'fullscreen',
      ],
    })
  },

  data() {
    return {
      model: 0,
      testimonials: testimonials,

      features: [
        {
          src: require('@/assets/eeweb-logo.png'),
          link: 'https://www.eeweb.com/profile/geschema/articles/free-web-based-hdl-register-generator-for-fpga-developers',
        },
        {
          src: require('@/assets/ep-logo-alpha.png'),
          link: 'https://www.elektronikpraxis.vogel.de/axi4-registerbaenke-nie-wieder-von-hand-programmieren-a-664879',
        },
        {
          src: require('@/assets/xilinx-logo.png'),
          link: 'https://forums.xilinx.com/t5/Xcell-Daily-Blog/Free-Web-based-airhdl-AXI4-register-file-generator-creates-HDL-C/ba-p/800946',
        },
      ],
      colors: ['1', '2', '3'],
      show: true,
    }
  },
}
</script>

<style src='plyr/dist/plyr.css'></style>

