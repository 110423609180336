"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The LfsrDto model module.
 * @module model/LfsrDto
 * @version 1.0.0
 */
class LfsrDto {
  /**
   * Constructs a new <code>LfsrDto</code>.
   * @alias module:model/LfsrDto
   */
  constructor() {
    LfsrDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>LfsrDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LfsrDto} obj Optional instance to populate.
   * @return {module:model/LfsrDto} The populated <code>LfsrDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LfsrDto();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('length')) {
        obj['length'] = _ApiClient.default.convertToType(data['length'], 'Number');
      }
      if (data.hasOwnProperty('taps')) {
        obj['taps'] = _ApiClient.default.convertToType(data['taps'], ['Number']);
      }
      if (data.hasOwnProperty('feedback')) {
        obj['feedback'] = _ApiClient.default.convertToType(data['feedback'], 'String');
      }
      if (data.hasOwnProperty('period')) {
        obj['period'] = _ApiClient.default.convertToType(data['period'], 'String');
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
LfsrDto.prototype['id'] = undefined;

/**
 * @member {String} name
 */
LfsrDto.prototype['name'] = undefined;

/**
 * @member {String} description
 */
LfsrDto.prototype['description'] = undefined;

/**
 * @member {Number} length
 */
LfsrDto.prototype['length'] = undefined;

/**
 * @member {Array.<Number>} taps
 */
LfsrDto.prototype['taps'] = undefined;

/**
 * @member {String} feedback
 */
LfsrDto.prototype['feedback'] = undefined;

/**
 * @member {String} period
 */
LfsrDto.prototype['period'] = undefined;
var _default = LfsrDto;
exports.default = _default;