"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UserNameDto model module.
 * @module model/UserNameDto
 * @version 1.0.0
 */
class UserNameDto {
  /**
   * Constructs a new <code>UserNameDto</code>.
   * @alias module:model/UserNameDto
   */
  constructor() {
    UserNameDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UserNameDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserNameDto} obj Optional instance to populate.
   * @return {module:model/UserNameDto} The populated <code>UserNameDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserNameDto();
      if (data.hasOwnProperty('username')) {
        obj['username'] = _ApiClient.default.convertToType(data['username'], 'String');
      }
    }
    return obj;
  }
}

/**
 * @member {String} username
 */
UserNameDto.prototype['username'] = undefined;
var _default = UserNameDto;
exports.default = _default;