<template>
  <v-dialog v-model="dialog" max-width="600px" :persistent="!allowClose">
    <v-card>
      <v-card-title>
        <span class="headline">New License</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false" v-if="allowClose">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-card-text class="mt-4">
        <p class="text-body-1">
          Please fill-in the information below to create a new license file. The
          license will be locked to the provided hardware ID.
        </p>

        <v-form ref="licenseForm">
          <v-text-field
            autocomplete="off"
            ref="hardwareIdField"
            v-model="license.hardwareId"
            label="Hardware ID (MAC address)"
            :rules="macAddressRules"
            outlined
            dense
            @input="validate"
          />
          <v-text-field
            autocomplete="off"  
            v-model="license.description"
            label="Description (optional, e.g. 'Joe's workstation')"
            outlined
            dense
            :rules="licenseDescriptionRules"
            @input="validate"
          /> </v-form
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" class="mb-4 mr-2"> Cancel </v-btn>
        <v-btn
          color="#337AB7"
          class="white--text mb-4 mr-2"
          @click="submit"
          :disabled="!submitEnabled"
        >
          Create License
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'

const defaultLicense = {
  product: 'CL',
  hardwareId: '',
  description: '',
}

export default {
  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      license: defaultLicense,
      reject: null,
      dialog: false,
      resolve: null,
      submitEnabled: false,
      allowClose: true,
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {},

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    open(allowClose = true) {
      this.allowClose = allowClose

      this.license = Object.assign({}, defaultLicense)
      this.submitEnabled = false
      this.dialog = true

      // Reset the validation and focus the first text field
      setTimeout(() => {
        this.$refs.licenseForm.resetValidation()
        this.$refs.hardwareIdField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.license)
    },

    validate() {
      if (this.$refs.licenseForm.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    },
  },

  /************************************************************************************************
   * Watches
   ************************************************************************************************/
  watch: {},

  /************************************************************************************************
   * Computed Properties
   ************************************************************************************************/
  computed: {
    macAddressRules() {
      return global.macAddressRules
    },

    licenseDescriptionRules() {
      return global.licenseDescriptionRules
    },
  },
}
</script>

<style></style>
