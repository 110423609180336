<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-left">
        <MessageSheet type="success" title="Password Reset Completed"
          ><p>Your password has been changed successfully.</p>
          <p>Please proceed to <router-link :to="{ name: 'Login' }">login</router-link></p></MessageSheet
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MessageSheet from "@/components/MessageSheet.vue";

export default {
  components: {
    MessageSheet,
  },
};
</script>

<style></style>
