<template>
  <v-sheet :color="color" elevation="1" class="rounded pa-5 mt-5 mb-5">
    <p class="text-h5 font-weight-medium">
      {{ title }}
    </p>
    <p><slot></slot></p>    
  </v-sheet>
</template>

<script>
export default {
  props: {
    type: String,
    title: String
  },
  computed: {
    color() {
      if (this.type === "warning") {
        return "red lighten-4";
      } else if (this.type === "success") {
        return "green lighten-4";
      }
      return ""
    },
  },
};
</script>
