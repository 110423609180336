"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _UserDto = _interopRequireDefault(require("../model/UserDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Admin service.
* @module api/AdminApi
* @version 1.0.0
*/
class AdminApi {
  /**
  * Constructs a new AdminApi. 
  * @alias module:api/AdminApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Update user
   * @param {Number} userId 
   * @param {module:model/UserDto} userDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  adminSaveUserWithHttpInfo(userId, userDto) {
    let postBody = userDto;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling adminSaveUser");
    }
    // verify the required parameter 'userDto' is set
    if (userDto === undefined || userDto === null) {
      throw new Error("Missing the required parameter 'userDto' when calling adminSaveUser");
    }
    let pathParams = {
      'userId': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/admin/users/{userId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update user
   * @param {Number} userId 
   * @param {module:model/UserDto} userDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  adminSaveUser(userId, userDto) {
    return this.adminSaveUserWithHttpInfo(userId, userDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AdminApi;