import Vue from 'vue'
import './plugins/axios'
import VueMq from 'vue-mq'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import api from '@/services/ApiService.js'
import store from './store'
import { ViewportBreakpoint } from './global/viewport'
import LoadScript from 'vue-plugin-load-script'
import settings from '@/json/settings.json'
import config from '@/json/config.json'
import VueGtag from 'vue-gtag'

// Disable "You are running Vue in development mode." message
Vue.config.productionTip = false

console.log('main: using profile: ' + config.profile)

/************************************************************************************************
 *  Set API base path
 ************************************************************************************************/

var basePath
if (Vue.config.devtools) {
  // Development build running locally: frontend runs on a different port (8080) than backend (8082)
  basePath = 'http://localhost:8082/api'
} else {
  // Production build: frontend and backend run on the same port (e.g. 8080)
  basePath = location.protocol + '//' + location.host + '/api'
}

console.log('main: setting base path to ' + basePath)
api.setBasePath(basePath)

/************************************************************************************************
 * Google Ads
 ************************************************************************************************/

Vue.use(VueGtag, {
  config: { id: 'AW-10942363583' }
})

/************************************************************************************************
 * Paddle
 ************************************************************************************************/

// Get paddle vendor ID
let vendorId
if (config.profile === 'prod') {
  vendorId = settings.paddle.vendorId.prod
} else {
  vendorId = settings.paddle.vendorId.dev
}

// console.log('main: using paddle vendor ID ' + vendorId)

// Load paddle script
Vue.use(LoadScript)
Vue.loadScript('https://cdn.paddle.com/paddle/paddle.js').then(() => {
  if (config.profile === 'dev') {
    // https://developer.paddle.com/getting-started/sandbox
    window.Paddle.Environment.set('sandbox')
  }
  window.Paddle.Setup({
    vendor: vendorId,
    eventCallback: function(data) {
      console.log('main: paddle eventCallback')
      console.log(data)
      // The data.event will specify the event type
      if (data.event === 'Checkout.Complete') {
        console.log('Checkout.Complete')
        console.log(data.eventData)
        store.dispatch('reloadUser')
      } else if (data.event === 'Checkout.Close') {
        console.log(data.eventData)
      }
    }
  })
})

/************************************************************************************************/

Vue.use(VueMq, {
  breakpoints: {
    sm: ViewportBreakpoint.Small,
    md: ViewportBreakpoint.Medium,
    lg: ViewportBreakpoint.Large
  },
  defaultBreakpoint: 'sm'
})

/************************************************************************************************/

new Vue({
  vuetify,
  router,
  store, // injects store into all components, so they have direct access to it
  render: h => h(App)
}).$mount('#app')
