"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The LicenseDto model module.
 * @module model/LicenseDto
 * @version 1.0.0
 */
class LicenseDto {
  /**
   * Constructs a new <code>LicenseDto</code>.
   * @alias module:model/LicenseDto
   */
  constructor() {
    LicenseDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>LicenseDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LicenseDto} obj Optional instance to populate.
   * @return {module:model/LicenseDto} The populated <code>LicenseDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LicenseDto();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('hardwareId')) {
        obj['hardwareId'] = _ApiClient.default.convertToType(data['hardwareId'], 'String');
      }
      if (data.hasOwnProperty('product')) {
        obj['product'] = _ApiClient.default.convertToType(data['product'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = _ApiClient.default.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('expirationDate')) {
        obj['expirationDate'] = _ApiClient.default.convertToType(data['expirationDate'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'String');
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
LicenseDto.prototype['id'] = undefined;

/**
 * @member {String} hardwareId
 */
LicenseDto.prototype['hardwareId'] = undefined;

/**
 * @member {String} product
 */
LicenseDto.prototype['product'] = undefined;

/**
 * @member {String} creationDate
 */
LicenseDto.prototype['creationDate'] = undefined;

/**
 * @member {String} expirationDate
 */
LicenseDto.prototype['expirationDate'] = undefined;

/**
 * @member {String} description
 */
LicenseDto.prototype['description'] = undefined;

/**
 * @member {String} status
 */
LicenseDto.prototype['status'] = undefined;
var _default = LicenseDto;
exports.default = _default;