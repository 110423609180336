"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AccessTokenDto = _interopRequireDefault(require("../model/AccessTokenDto"));
var _ConfirmTokenDto = _interopRequireDefault(require("../model/ConfirmTokenDto"));
var _InlineResponse = _interopRequireDefault(require("../model/InlineResponse200"));
var _InlineResponse2 = _interopRequireDefault(require("../model/InlineResponse400"));
var _InlineResponse3 = _interopRequireDefault(require("../model/InlineResponse4001"));
var _InlineResponse4 = _interopRequireDefault(require("../model/InlineResponse401"));
var _PasswordTokenDto = _interopRequireDefault(require("../model/PasswordTokenDto"));
var _UserCredentialsDto = _interopRequireDefault(require("../model/UserCredentialsDto"));
var _UserNameDto = _interopRequireDefault(require("../model/UserNameDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Auth service.
* @module api/AuthApi
* @version 1.0.0
*/
class AuthApi {
  /**
  * Constructs a new AuthApi. 
  * @alias module:api/AuthApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Change password using password reset token
   * @param {module:model/PasswordTokenDto} passwordTokenDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  changePasswordWithHttpInfo(passwordTokenDto) {
    let postBody = passwordTokenDto;
    // verify the required parameter 'passwordTokenDto' is set
    if (passwordTokenDto === undefined || passwordTokenDto === null) {
      throw new Error("Missing the required parameter 'passwordTokenDto' when calling changePassword");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = null;
    return this.apiClient.callApi('/auth/changePassword', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Change password using password reset token
   * @param {module:model/PasswordTokenDto} passwordTokenDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  changePassword(passwordTokenDto) {
    return this.changePasswordWithHttpInfo(passwordTokenDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Confirm the registration
   * @param {module:model/ConfirmTokenDto} confirmTokenDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  confirmWithHttpInfo(confirmTokenDto) {
    let postBody = confirmTokenDto;
    // verify the required parameter 'confirmTokenDto' is set
    if (confirmTokenDto === undefined || confirmTokenDto === null) {
      throw new Error("Missing the required parameter 'confirmTokenDto' when calling confirm");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/auth/confirm', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Confirm the registration
   * @param {module:model/ConfirmTokenDto} confirmTokenDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  confirm(confirmTokenDto) {
    return this.confirmWithHttpInfo(confirmTokenDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * User login
   * @param {module:model/UserCredentialsDto} userCredentialsDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
   */
  loginUserWithHttpInfo(userCredentialsDto) {
    let postBody = userCredentialsDto;
    // verify the required parameter 'userCredentialsDto' is set
    if (userCredentialsDto === undefined || userCredentialsDto === null) {
      throw new Error("Missing the required parameter 'userCredentialsDto' when calling loginUser");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _InlineResponse.default;
    return this.apiClient.callApi('/auth/login', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * User login
   * @param {module:model/UserCredentialsDto} userCredentialsDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
   */
  loginUser(userCredentialsDto) {
    return this.loginUserWithHttpInfo(userCredentialsDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * User logout
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  logoutUserWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/auth/logout', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * User logout
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  logoutUser() {
    return this.logoutUserWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Refresh the access token
   * @param {String} refreshToken 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccessTokenDto} and HTTP response
   */
  refreshTokenWithHttpInfo(refreshToken) {
    let postBody = null;
    // verify the required parameter 'refreshToken' is set
    if (refreshToken === undefined || refreshToken === null) {
      throw new Error("Missing the required parameter 'refreshToken' when calling refreshToken");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _AccessTokenDto.default;
    return this.apiClient.callApi('/auth/refresh_token', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Refresh the access token
   * @param {String} refreshToken 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccessTokenDto}
   */
  refreshToken(refreshToken) {
    return this.refreshTokenWithHttpInfo(refreshToken).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * User registration
   * @param {module:model/UserCredentialsDto} userCredentialsDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  registerUserWithHttpInfo(userCredentialsDto) {
    let postBody = userCredentialsDto;
    // verify the required parameter 'userCredentialsDto' is set
    if (userCredentialsDto === undefined || userCredentialsDto === null) {
      throw new Error("Missing the required parameter 'userCredentialsDto' when calling registerUser");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = null;
    return this.apiClient.callApi('/auth/register', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * User registration
   * @param {module:model/UserCredentialsDto} userCredentialsDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  registerUser(userCredentialsDto) {
    return this.registerUserWithHttpInfo(userCredentialsDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Resend the verification link
   * @param {module:model/ConfirmTokenDto} confirmTokenDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  resendWithHttpInfo(confirmTokenDto) {
    let postBody = confirmTokenDto;
    // verify the required parameter 'confirmTokenDto' is set
    if (confirmTokenDto === undefined || confirmTokenDto === null) {
      throw new Error("Missing the required parameter 'confirmTokenDto' when calling resend");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/auth/resend', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Resend the verification link
   * @param {module:model/ConfirmTokenDto} confirmTokenDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  resend(confirmTokenDto) {
    return this.resendWithHttpInfo(confirmTokenDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Request password reset link
   * @param {module:model/UserNameDto} userNameDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  resetPasswordWithHttpInfo(userNameDto) {
    let postBody = userNameDto;
    // verify the required parameter 'userNameDto' is set
    if (userNameDto === undefined || userNameDto === null) {
      throw new Error("Missing the required parameter 'userNameDto' when calling resetPassword");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/auth/resetPassword', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Request password reset link
   * @param {module:model/UserNameDto} userNameDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  resetPassword(userNameDto) {
    return this.resetPasswordWithHttpInfo(userNameDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = AuthApi;