<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ isNewRegisterMapDialog ? 'New' : 'Edit' }} Register Map</span
        >
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            autocomplete="off"  
            ref="registerMapNameField"
            v-model="registerMap.name"
            label="Name"
            :rules="objectNameRules"
            outlined
            dense
            class="mb-4 mx-2"
            @input="validate"
          />
          <v-textarea
            v-model="registerMap.description"
            label="Description"
            value=""
            outlined
            dense
            class="mb-4 mx-2"
            :rules="registerMapDescriptionRules"
            counter
            @input="validate"
          ></v-textarea>
          <v-text-field
            autocomplete="off"  
            v-model="strBaseAddress"
            label="Default base address"
            :rules="baseAddressRules"
            hint="The default base address of this register map in the system address space."
            persistent-hint
            outlined
            prefix="0x"
            dense
            class="mb-4 mx-2"
            @input="validate"
          />
          <v-text-field
            autocomplete="off"  
            v-model="registerMap.addrWidthBits"
            label="Default address width"
            :rules="defaultAddressWidthRules"
            hint="The default width of the address port, in bits."
            persistent-hint
            outlined
            dense
            type="number"
            class="mb-4 mx-2"
            @input="validate"
          />
          <p class="text-subtitle-1 ml-2 mb-0">Code Generation Options</p>
          <v-checkbox
            v-model="registerMap.generateRecordPorts"
            label="Wrap user ports into VHDL records / SystemVerilog structs"
            dense
            hide-details
            class="mx-2"
          ></v-checkbox>
          <v-checkbox
            v-if="features.includes('packed-structs')"
            :disabled="!registerMap.generateRecordPorts"
            v-model="registerMap.generatePackedStructs"
            label="Generate packed SystemVerilog structs"
            dense
            hide-details
            class="mx-2"
          ></v-checkbox>
          <v-checkbox
            v-model="registerMap.disableTimestamps"
            label="Disable timestamps in generated code"
            dense
            hide-details
            class="mx-2"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-4"> Cancel </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mb-4 mr-4"
            type="submit"
            :disabled="!submitEnabled"
          >
            {{
              isNewRegisterMapDialog
                ? 'Create Register Map'
                : 'Save Register Map'
            }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'
import { mapGetters } from 'vuex'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      isNewRegisterMapDialog: true,
      dialog: false,
      submitEnabled: false,
      strBaseAddress: 0,
      createDefaultField: true,
      resolve: null,
      reject: null,

      defaultRegisterMap: {
        name: '',
        description: '',
        width: 32,
        addrWidthBits: 32,
        baseAddress: 0,
        revision: 0,
        generateRecordPorts: false,
        generatePackedStructs: false,
        disableTimestamps: false
      },

      registerMap: Object.assign({}, this.defaultRegisterMap),
    }
  },

  created() {
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    //  - if register map is not null, show the "Edit Register Map" dialog
    //  - otherwise show the "New Register Map" dialog
    open(registerMap) {
      if (registerMap == null) {
        this.isNewRegisterMapDialog = true
        this.registerMap = Object.assign({}, this.defaultRegisterMap)
        this.strBaseAddress = this.registerMap.baseAddress.toString(16).toUpperCase()
        this.submitEnabled = false
      } else {
        this.isNewRegisterMapDialog = false
        this.strBaseAddress = registerMap.baseAddress.toString(16).toUpperCase()
        this.registerMap = Object.assign({}, registerMap)
        this.submitEnabled = true // assume that the register data is valid and enable the "Save register" button
      }
      this.dialog = true

      setTimeout(() => {
        this.$refs.form.resetValidation()
        this.$refs.registerMapNameField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.registerMap.baseAddress = parseInt(this.strBaseAddress, 16)
      this.dialog = false
      this.resolve(this.registerMap)
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    },
  },

  /************************************************************************************************/
  watch: {
    strBaseAddress(newValue) {
      this.registerMap.addressOffset = parseInt(newValue, 16)
    },
  },

  /************************************************************************************************/
  computed: {
    ...mapGetters([
      'features',
    ]),

    objectNameRules() {
      return global.objectNameRules
    },

    registerMapDescriptionRules() {
      return global.registerMapDescriptionRules
    },

    baseAddressRules() {
      return global.baseAddressRules
    },

    defaultAddressWidthRules() {
      return global.defaultAddressWidthRules
    }
  },
}
</script>

<style></style>
