<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <span class="text-body-1 text--primary"
          >Pay Yearly<v-switch
            v-model="payMonthly"
            class="d-inline-flex mx-4"
            hide-details
            dense
          ></v-switch
          >Monthly</span
        >
      </v-col>
    </v-row>
    <v-row align="stretch" class="my-4">
      <v-col
        cols="12"
        md="3"
        v-for="plan in plans"
        :key="plan.name"
        class="d-flex flex-column"
      >
        <v-card class="flex d-flex flex-column pa-4" elevation="2">
          <v-sheet color="#337AB7" elevation="0" height="5" tile></v-sheet>
          <v-card-title
            v-text="plan.name"
            class="text-h6 font-weight-black pb-2"
          ></v-card-title>
          <v-divider></v-divider>
          <v-card-text
            ><span class="text-h4 font-weight-regular text--primary">{{
              price(plan)
            }}</span>
          </v-card-text>
          <v-card-text class="pt-0">
            <span
              class="text-body-2 text--primary"
              v-html="priceConditions(plan)"
            ></span
            ><br />
            <span class="text-body-2 text--primary" v-html="priceBilled(plan)">
            </span
          ></v-card-text>
          <v-card-actions class="pt-0 mx-2">
            <template v-if="plan.actionLink == null">
              <v-btn
                class="my-4 white--text"
                block
                color="#337AB7"
                @click="onClick(plan)"
                v-if="isLoggedIn"
                :disabled="!plan.actionEnable"
                >{{ plan.actionText }}</v-btn
              >
            </template>
            <template v-else>
              <v-btn
                class="my-4 white--text"
                block
                color="#337AB7"
                :href="plan.actionLink"
                v-if="isLoggedIn"
                :disabled="!plan.actionEnable"
                >{{ plan.actionText }}</v-btn
              >
            </template>
            <v-divider v-if="!isLoggedIn"></v-divider>
          </v-card-actions>
          <v-card-text
            class="pt-0 text-left font-weight-medium text-body-1 text--primary"
            >{{ plan.featuresTitle }}</v-card-text
          >
          <v-card-text class="mt-0 pt-0 text-body-1 text--primary">
            <ul>
              <li v-for="featureText in plan.featuresText" :key="featureText">
                {{ featureText }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import plans from '@/json/plans.json'
import config from '@/json/config.json'

export default {
  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      payMonthly: false,
    }
  },

  /************************************************************************************************
   * Created Hook
   ************************************************************************************************/
  created() {},

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    onClick(plan) {
      let productId = null
      if (this.payMonthly) {
        if (config.profile === 'prod') {
          productId = plan.monthlyProductId.prod
        } else {
          productId = plan.monthlyProductId.dev
        }
      } else {
        if (config.profile === 'prod') {
          productId = plan.yearlyProductId.prod
        } else {
          productId = plan.yearlyProductId.dev
        }
      }
      console.log('plans: selected product ID ' + productId)
      this.$emit('select', productId, plan.name, this.payMonthly)
    },

    hasPrice(plan) {
      if (
        this.payMonthly &&
        'monthlyPrice' in plan &&
        parseInt(plan.monthlyPrice) > 0
      ) {
        return true
      }
      if (
        !this.payMonthly &&
        'yearlyPrice' in plan &&
        parseInt(plan.yearlyPrice) > 0
      ) {
        return true
      }
      return false
    },

    price(plan) {
      if (this.payMonthly) {
        return this.monthlyPrice(plan)
      }
      return this.yearlyPrice(plan)
    },

    priceConditions(plan) {
      if ('priceConditions' in plan) {
        return plan.priceConditions
      }
      return '&nbsp;'
    },

    priceBilled(plan) {
      if (this.payMonthly) {
        if ('monthlyPrice' in plan) {
          if (parseInt(plan.monthlyPrice) > 0) {
            return 'Billed monthly'
          }
          // plan is free -> no billing info
          return '&nbsp;'
        }
        // plan has no monthly price -> no billing info
        return '&nbsp;'
      } else {
        if ('yearlyPrice' in plan) {
          if (parseInt(plan.yearlyPrice) > 0) {
            return `Billed yearly ($${plan.yearlyPrice * 12} per year)`
          }
          // plan is free -> no billing info
          return '&nbsp;'
        }
        // plan has no yearly price -> no billing info
        return '&nbsp;'
      }
    },

    monthlyPrice(plan) {
      var currency = '$'
      if ('monthlyPrice' in plan) {
        var price = parseInt(plan.monthlyPrice)
        if (price === 0) {
          return 'Free'
        }
        return currency + price
      }
      return 'Custom'
    },

    yearlyPrice(plan) {
      var currency = '$'
      if ('yearlyPrice' in plan) {
        var price = parseInt(plan.yearlyPrice)
        if (price === 0) {
          return 'Free'
        }
        return currency + price
      }
      return 'Custom'
    },
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },

    plans() {
      var result = []
      plans.forEach((plan) => {
        if (plan.visible) {
          result.push(plan)
        }
      })
      return result
    },
  },
}
</script>
