<!-- see https://techformist.com/reusable-confirmation-dialog-vuetify/ -->
<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="text-h5 pt-4">
        <span>
          {{ title }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-show="!!message" class="text-body-1 pt-4 black--text"
        ><span v-html="message"></span
      ></v-card-text>
      <v-card-actions class="pt-4 pb-6">
        <v-spacer></v-spacer>
        <v-btn v-if="!options.noconfirm" text @click.native="cancel"
          >Cancel</v-btn
        >
        <v-btn color="#337AB7" class="white--text mr-4" @click.native="agree"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDlg',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'grey lighten-3',
        width: 504,
        zIndex: 200,
        noconfirm: false,
      },
    }
  },

  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>
