<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <MessageSheet
          v-if="!isLinkValid"
          type="warning"
          title="Invalid password reset link"
          ><p>
            This password reset link is not valid. Please contact our technical
            support.
          </p></MessageSheet
        >
        <v-card v-if="isLinkValid" class="pa-4 mt-4 mx-auto" max-width="480">
          <v-sheet color="#337AB7" elevation="0" height="5" tile></v-sheet>
          <v-card-title class="text-h4 font-weight-medium">
            Reset Password
          </v-card-title>
          <v-card-text class="text-body-1">
            Please enter your new password below.
          </v-card-text>
          <v-form ref="form" @submit.prevent="submit">
            <v-card-text>
              <v-text-field
                v-model="form.password"
                prepend-icon="mdi-lock"
                :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="form.showPassword ? 'text' : 'password'"
                label="Password"
                @click:append="form.showPassword = !form.showPassword"
                autocomplete="password"
                :rules="passwordRules"
                @input="onInput"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                type="submit"
                class="white--text"
                large
                color="#337AB7"
                :disabled="form.disabled"
                :loading="form.loading"
                >Change my password</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <MessageSnack v-model="errorMessage.show" :text="errorMessage.text" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import global from '@/global/index.js'
import MessageSheet from '@/components/MessageSheet.vue'
import MessageSnack from '@/components/MessageSnack.vue'

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/

  components: {
    MessageSheet,
    MessageSnack
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data() {
    return {
      form: {
        showPassword: false,
        disabled: true,
        loading: false,
        password: ''
      },
      isLinkValid: false,
      token: null,
      isSuccess: false,
      isError: false,
      message: '',
      error: '',
      errorMessage: {
        show: false,
        text: ''
      }
    }
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/

  computed: {
    passwordRules() {
      return global.passwordRules
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/

  created() {
    // Password reset URL is of the form: http://localhost:8082/#/resetPassword?token=82551ca8-1c96-4e93-8101-9a0344f34066
    this.token = this.$route.query.token
    if (this.token) {
      this.isLinkValid = true
    }
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/

  methods: {
    showErrorMessage(message) {
      this.errorMessage.text = 'Error: ' + message
      this.errorMessage.show = true
    },

    onInput() {
      if (this.$refs.form.validate()) {
        this.form.disabled = false
      } else {
        this.form.disabled = true
      }
    },

    // Clicked "Change my password" button
    submit() {
      if (!this.$refs.form.validate()) {
        this.snackbar.show = true
        this.snackbar.text = 'Error: please enter a valid password'
        return
      }
      var dto = {
        token: this.token,
        password: this.form.password
      }
      this.form.loading = true
      api.auth
        .changePassword(dto)
        .then(() => {
          this.form.loading = false
          this.$router.push({ name: 'PasswordResetCompleted' })
        })
        .catch(error => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.form.loading = false
          this.showErrorMessage(error.body.message)
        })
    }
  }
}
</script>
