"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UserDto model module.
 * @module model/UserDto
 * @version 1.0.0
 */
class UserDto {
  /**
   * Constructs a new <code>UserDto</code>.
   * @alias module:model/UserDto
   */
  constructor() {
    UserDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UserDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserDto} obj Optional instance to populate.
   * @return {module:model/UserDto} The populated <code>UserDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserDto();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('username')) {
        obj['username'] = _ApiClient.default.convertToType(data['username'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = _ApiClient.default.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('lastName')) {
        obj['lastName'] = _ApiClient.default.convertToType(data['lastName'], 'String');
      }
      if (data.hasOwnProperty('subscribed')) {
        obj['subscribed'] = _ApiClient.default.convertToType(data['subscribed'], 'Boolean');
      }
      if (data.hasOwnProperty('enabled')) {
        obj['enabled'] = _ApiClient.default.convertToType(data['enabled'], 'Boolean');
      }
      if (data.hasOwnProperty('plan')) {
        obj['plan'] = _ApiClient.default.convertToType(data['plan'], 'String');
      }
      if (data.hasOwnProperty('registrationDate')) {
        obj['registrationDate'] = _ApiClient.default.convertToType(data['registrationDate'], 'String');
      }
      if (data.hasOwnProperty('planExpirationDate')) {
        obj['planExpirationDate'] = _ApiClient.default.convertToType(data['planExpirationDate'], 'String');
      }
      if (data.hasOwnProperty('subscriptionId')) {
        obj['subscriptionId'] = _ApiClient.default.convertToType(data['subscriptionId'], 'String');
      }
      if (data.hasOwnProperty('subscriptionStatus')) {
        obj['subscriptionStatus'] = _ApiClient.default.convertToType(data['subscriptionStatus'], 'String');
      }
      if (data.hasOwnProperty('subscriptionUpdateUrl')) {
        obj['subscriptionUpdateUrl'] = _ApiClient.default.convertToType(data['subscriptionUpdateUrl'], 'String');
      }
      if (data.hasOwnProperty('subscriptionCancelUrl')) {
        obj['subscriptionCancelUrl'] = _ApiClient.default.convertToType(data['subscriptionCancelUrl'], 'String');
      }
      if (data.hasOwnProperty('subscriptionPausedFrom')) {
        obj['subscriptionPausedFrom'] = _ApiClient.default.convertToType(data['subscriptionPausedFrom'], 'String');
      }
      if (data.hasOwnProperty('subscriptionExpired')) {
        obj['subscriptionExpired'] = _ApiClient.default.convertToType(data['subscriptionExpired'], 'Boolean');
      }
      if (data.hasOwnProperty('activeLicenses')) {
        obj['activeLicenses'] = _ApiClient.default.convertToType(data['activeLicenses'], 'Number');
      }
      if (data.hasOwnProperty('maxActiveLicenses')) {
        obj['maxActiveLicenses'] = _ApiClient.default.convertToType(data['maxActiveLicenses'], 'Number');
      }
      if (data.hasOwnProperty('termsAgreedVersion')) {
        obj['termsAgreedVersion'] = _ApiClient.default.convertToType(data['termsAgreedVersion'], 'Number');
      }
      if (data.hasOwnProperty('lastLogin')) {
        obj['lastLogin'] = _ApiClient.default.convertToType(data['lastLogin'], 'String');
      }
      if (data.hasOwnProperty('showTestimonial')) {
        obj['showTestimonial'] = _ApiClient.default.convertToType(data['showTestimonial'], 'Boolean');
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
UserDto.prototype['id'] = undefined;

/**
 * @member {String} username
 */
UserDto.prototype['username'] = undefined;

/**
 * @member {String} firstName
 */
UserDto.prototype['firstName'] = undefined;

/**
 * @member {String} lastName
 */
UserDto.prototype['lastName'] = undefined;

/**
 * @member {Boolean} subscribed
 */
UserDto.prototype['subscribed'] = undefined;

/**
 * @member {Boolean} enabled
 */
UserDto.prototype['enabled'] = undefined;

/**
 * @member {String} plan
 */
UserDto.prototype['plan'] = undefined;

/**
 * @member {String} registrationDate
 */
UserDto.prototype['registrationDate'] = undefined;

/**
 * @member {String} planExpirationDate
 */
UserDto.prototype['planExpirationDate'] = undefined;

/**
 * @member {String} subscriptionId
 */
UserDto.prototype['subscriptionId'] = undefined;

/**
 * @member {String} subscriptionStatus
 */
UserDto.prototype['subscriptionStatus'] = undefined;

/**
 * @member {String} subscriptionUpdateUrl
 */
UserDto.prototype['subscriptionUpdateUrl'] = undefined;

/**
 * @member {String} subscriptionCancelUrl
 */
UserDto.prototype['subscriptionCancelUrl'] = undefined;

/**
 * @member {String} subscriptionPausedFrom
 */
UserDto.prototype['subscriptionPausedFrom'] = undefined;

/**
 * @member {Boolean} subscriptionExpired
 */
UserDto.prototype['subscriptionExpired'] = undefined;

/**
 * @member {Number} activeLicenses
 */
UserDto.prototype['activeLicenses'] = undefined;

/**
 * @member {Number} maxActiveLicenses
 */
UserDto.prototype['maxActiveLicenses'] = undefined;

/**
 * @member {Number} termsAgreedVersion
 */
UserDto.prototype['termsAgreedVersion'] = undefined;

/**
 * @member {String} lastLogin
 */
UserDto.prototype['lastLogin'] = undefined;

/**
 * @member {Boolean} showTestimonial
 */
UserDto.prototype['showTestimonial'] = undefined;
var _default = UserDto;
exports.default = _default;