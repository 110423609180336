"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _PermissionDto = _interopRequireDefault(require("./PermissionDto"));
var _RegisterDto = _interopRequireDefault(require("./RegisterDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RegisterMapDto model module.
 * @module model/RegisterMapDto
 * @version 1.0.0
 */
class RegisterMapDto {
  /**
   * Constructs a new <code>RegisterMapDto</code>.
   * @alias module:model/RegisterMapDto
   */
  constructor() {
    RegisterMapDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>RegisterMapDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RegisterMapDto} obj Optional instance to populate.
   * @return {module:model/RegisterMapDto} The populated <code>RegisterMapDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RegisterMapDto();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('width')) {
        obj['width'] = _ApiClient.default.convertToType(data['width'], 'Number');
      }
      if (data.hasOwnProperty('addrWidthBits')) {
        obj['addrWidthBits'] = _ApiClient.default.convertToType(data['addrWidthBits'], 'Number');
      }
      if (data.hasOwnProperty('baseAddress')) {
        obj['baseAddress'] = _ApiClient.default.convertToType(data['baseAddress'], 'Number');
      }
      if (data.hasOwnProperty('revision')) {
        obj['revision'] = _ApiClient.default.convertToType(data['revision'], 'Number');
      }
      if (data.hasOwnProperty('generateRecordPorts')) {
        obj['generateRecordPorts'] = _ApiClient.default.convertToType(data['generateRecordPorts'], 'Boolean');
      }
      if (data.hasOwnProperty('generatePackedStructs')) {
        obj['generatePackedStructs'] = _ApiClient.default.convertToType(data['generatePackedStructs'], 'Boolean');
      }
      if (data.hasOwnProperty('disableTimestamps')) {
        obj['disableTimestamps'] = _ApiClient.default.convertToType(data['disableTimestamps'], 'Boolean');
      }
      if (data.hasOwnProperty('owner')) {
        obj['owner'] = _ApiClient.default.convertToType(data['owner'], 'String');
      }
      if (data.hasOwnProperty('lastModified')) {
        obj['lastModified'] = _ApiClient.default.convertToType(data['lastModified'], 'String');
      }
      if (data.hasOwnProperty('permission')) {
        obj['permission'] = _ApiClient.default.convertToType(data['permission'], 'String');
      }
      if (data.hasOwnProperty('permissions')) {
        obj['permissions'] = _ApiClient.default.convertToType(data['permissions'], [_PermissionDto.default]);
      }
      if (data.hasOwnProperty('registers')) {
        obj['registers'] = _ApiClient.default.convertToType(data['registers'], [_RegisterDto.default]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
RegisterMapDto.prototype['id'] = undefined;

/**
 * @member {String} name
 */
RegisterMapDto.prototype['name'] = undefined;

/**
 * @member {String} description
 */
RegisterMapDto.prototype['description'] = undefined;

/**
 * @member {Number} width
 */
RegisterMapDto.prototype['width'] = undefined;

/**
 * @member {Number} addrWidthBits
 */
RegisterMapDto.prototype['addrWidthBits'] = undefined;

/**
 * @member {Number} baseAddress
 */
RegisterMapDto.prototype['baseAddress'] = undefined;

/**
 * @member {Number} revision
 */
RegisterMapDto.prototype['revision'] = undefined;

/**
 * @member {Boolean} generateRecordPorts
 */
RegisterMapDto.prototype['generateRecordPorts'] = undefined;

/**
 * @member {Boolean} generatePackedStructs
 */
RegisterMapDto.prototype['generatePackedStructs'] = undefined;

/**
 * @member {Boolean} disableTimestamps
 */
RegisterMapDto.prototype['disableTimestamps'] = undefined;

/**
 * @member {String} owner
 */
RegisterMapDto.prototype['owner'] = undefined;

/**
 * @member {String} lastModified
 */
RegisterMapDto.prototype['lastModified'] = undefined;

/**
 * @member {String} permission
 */
RegisterMapDto.prototype['permission'] = undefined;

/**
 * @member {Array.<module:model/PermissionDto>} permissions
 */
RegisterMapDto.prototype['permissions'] = undefined;

/**
 * @member {Array.<module:model/RegisterDto>} registers
 */
RegisterMapDto.prototype['registers'] = undefined;
var _default = RegisterMapDto;
exports.default = _default;