<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ isNewRegisterDialog ? 'New' : 'Edit' }}
          {{ formattedRegisterType(register) }}</span
        >
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            autocomplete="off"  
            ref="registerNameField"
            v-model="register.name"
            label="Name"
            :rules="objectNameRules"
            outlined
            dense
            class="mb-2"
            @input="validate"
          />
          <v-select
            v-model="register.type"
            :items="types"
            item-text="text"
            item-value="value"
            label="Type"
            outlined
            dense
            @input="validate"
            :filled="!isNewRegisterDialog"
            :readonly="!isNewRegisterDialog"
          ></v-select>
          <v-select
            v-model="register.access"
            :items="accessModes"
            item-text="text"
            label="Access mode"
            outlined
            dense
            @input="validate"
          ></v-select>
          <v-text-field
            autocomplete="off"
            v-model="strAddressOffset"
            label="Address offset"
            :rules="addressOffsetRules"
            hint="The address offset of this register, relative to the register map's base address."
            persistent-hint
            outlined
            prefix="0x"
            dense
            class="mb-4"
            @input="validate"
          />
          <v-textarea
            v-model="register.description"
            label="Description"
            rows="5"
            value=""
            outlined
            dense
            class="mb-2"
            :rules="registerDescriptionRules"
            counter
            @input="validate"
          ></v-textarea>
          <v-text-field
            autocomplete="off"
            v-if="register.type === 'RegisterArray'"
            v-model="register.arrayLength"
            label="Array length"
            :rules="arrayLengthRules"
            hint="The number of elements in the register array."
            persistent-hint
            outlined
            dense
            class="mb-2"
            type="number"
            @input="validate"
          />
          <v-checkbox
            v-if="register.type === 'RegisterArray' && features.includes('generic-array-length')"
            v-model="register.genericArrayLength"
            label="Generic array length (VHDL-only)"
            dense
            hide-details
            class="mx-0"
          ></v-checkbox>
          <v-text-field
            autocomplete="off"
            v-if="register.type === 'Memory'"
            v-model="register.depth"
            label="Memory depth"
            :rules="memoryDepthRules"
            hint="The memory depth, in elements."
            persistent-hint
            outlined
            dense
            class="mb-2"
            type="number"
            @input="validate"
          />
          <v-text-field
            autocomplete="off"
            v-if="register.type === 'Memory'"
            v-model="register.readLatency"
            label="Memory read latency"
            :rules="memoryReadLatencyRules"
            hint="The memory read latency, in clock cycles."
            persistent-hint
            outlined
            dense
            class="mb-2"
            type="number"
            @input="validate"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-4 mr-2"> Cancel </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mb-4 mr-2"
            type="submit"
            :disabled="!submitEnabled"
          >
            {{ isNewRegisterDialog ? 'Create Register' : 'Save Register' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'
import { mapGetters } from 'vuex'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      minArrayLength: 1,
      maxArrayLength: 65535,
      minMemoryDepth: 1,
      maxMemoryDepth: 1073741824,
      minMemoryReadLatency: 1,
      maxMemoryReadLatency: 5,

      isNewRegisterDialog: true,
      dialog: false,
      submitEnabled: false,
      strAddressOffset: 0,
      resolve: null,
      reject: null,

      defaultRegister: {
        name: '',
        addressOffset: 0,
        description: '',
        access: 'READ_WRITE',
        type: 'Register',
        arrayLength: 1,
        genericArrayLength: false,
        depth: 1,
        readLatency: 1
      },

      register: Object.assign({}, this.defaultRegister),

      types: [
        { text: 'Register', value: 'Register' },
        { text: 'Array', value: 'RegisterArray' },
        { text: 'Memory', value: 'Memory' },
      ],

      arrayLengthRules: [
        (value) => !!value || 'Please enter a valid number.',
        (value) =>
          (value >= this.minArrayLength && value <= this.maxArrayLength) ||
          `Array length must be in range [${this.minArrayLength}, ${this.maxArrayLength}].`,
      ],

      memoryDepthRules: [
        (value) => !!value || 'Please enter a valid number.',
        (value) =>
          (value >= this.minMemoryDepth && value <= this.maxMemoryDepth) ||
          `Memory depth must be in range [${this.minMemoryDepth}, ${this.maxMemoryDepth}].`,
      ],

      memoryReadLatencyRules: [
        (value) => !!value || 'Please enter a valid number.',
        (value) =>
          (value >= this.minMemoryReadLatency &&
            value <= this.maxMemoryReadLatency) ||
          `Memory read latency must be in range [${this.minMemoryReadLatency}, ${this.maxMemoryReadLatency}].`,
      ],
    }
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    //  - if register is not null, show the "Edit Register" dialog
    //  - otherwise show the "New Register dialog"
    open(defaultAddressOffset, register) {
      if (register == null) {
        this.isNewRegisterDialog = true
        this.strAddressOffset = defaultAddressOffset.toString(16).toUpperCase()
        this.register = Object.assign({}, this.defaultRegister)
        this.submitEnabled = false
      } else {
        this.isNewRegisterDialog = false
        this.strAddressOffset = register.addressOffset
          .toString(16)
          .toUpperCase()
        this.register = Object.assign({}, register)
        this.submitEnabled = true // assume that the register data is valid and enable the "Save register" button
        // Set default array/memory properties, for the case that the register type is changed in the dialog
        if (this.register.arrayLength == null) {
          this.register.arrayLength = 1
        }
        if (this.register.depth == null) {
          this.register.depth = 1
        }
        if (this.register.readLatency == null) {
          this.register.readLatency = 1
        }
      }
      this.dialog = true

      setTimeout(() => {
        this.$refs.form.resetValidation()
        this.$refs.registerNameField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.register.addressOffset = parseInt(this.strAddressOffset, 16)
      this.dialog = false
      this.resolve(this.register)
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    },

    formattedRegisterType(register) {
      if (register.type === 'Register') {
        return 'Register'
      } else if (register.type === 'RegisterArray') {
        return 'Register Array'
      } else {
        return 'Memory'
      }
    },
  },

  /************************************************************************************************/
  watch: {
    strAddressOffset(newValue) {
      this.register.addressOffset = parseInt(newValue, 16)
    },

    // When the register type is changed to Memory, prevent the access mode from
    // remaining set to "interrupt".
    registerType() {
      if (
        this.register.type === 'Memory' &&
        this.register.access === 'interrupt'
      ) {
        this.register.access = this.accessModes[0]
      }
    },
  },

  /************************************************************************************************/
  computed: {
    ...mapGetters([
      'features',
    ]),

    registerType() {
      return this.register.type
    },

    objectNameRules() {
      return global.objectNameRules
    },

    registerDescriptionRules() {
      return global.registerDescriptionRules
    },

    addressOffsetRules() {
      return global.addressOffsetRules
    },

    enumValueRules() {
      return global.enumValueRules
    },

    accessModes() {
      if (this.register.type === 'Memory') {
        return [
          { text: 'read-write', value: 'READ_WRITE' },
          { text: 'read-only', value: 'READ_ONLY' },
          { text: 'write-only', value: 'WRITE_ONLY' },
          /* Memory has no 'interrupt' access mode */
        ]
      } else {
        return [
          { text: 'read-write', value: 'READ_WRITE' },
          { text: 'read-only', value: 'READ_ONLY' },
          { text: 'write-only', value: 'WRITE_ONLY' },
          { text: 'interrupt', value: 'INTERRUPT' },
        ]
      }
    },
  },
}
</script>

<style></style>
