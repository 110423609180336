<!-- Add register map to project dialog -->
<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Add Register Maps</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-card-text>
        <v-data-table
          v-model="selectedRegisterMaps"
          :headers="headers"
          :items="allRegisterMaps"
          show-select
          class="elevation-1"
          hide-default-footer
          ref="table"
          disable-pagination
          no-data-text="You haven't created any register maps yet."
        >
          <template v-slot:item.description="{ item }">
            {{ shorten(item.description) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" class="mb-4 mr-2"> Cancel </v-btn>
        <v-btn
          color="#337AB7"
          class="white--text mb-4 mr-2"
          @click="submit"
          :disabled="selectedRegisterMaps.length === 0"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import utils from '@/global/utils.js'
import settings from '@/json/settings.json'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      projectRegisterMaps: [],
      allRegisterMaps: [],
      selectedRegisterMaps: [],

      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
        },
      ],
    }
  },

  /************************************************************************************************/
  methods: {
    shorten(description) {
      return utils.shorten(description, settings.maxDescriptionLength)
    },

    // Open the dialog
    open(projectRegisterMaps, allRegisterMaps) {
      this.projectRegisterMaps = Object.assign({}, projectRegisterMaps)
      this.allRegisterMaps = []
      for (var i = 0; i < allRegisterMaps.length; i++) {
        var registerMap = allRegisterMaps[i]
        if (registerMap.permission === 'ADMIN') {
          this.allRegisterMaps.push(registerMap)
        }
      }
      //this.allRegisterMaps = allRegisterMaps
      this.selectedRegisterMaps = []
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.resolve(null)
    },

    submit() {
      this.dialog = false
      this.resolve(this.selectedRegisterMaps)
    },
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {},
}
</script>

<style></style>
