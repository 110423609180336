<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Edit Account Information</span>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            ref="firstNameField"
            v-model="user.firstName"
            label="First Name"
            outlined
            dense
            class="mb-4"
          />
          <v-text-field
            ref="lastNameField"
            v-model="user.lastName"
            label="Last Name"
            outlined
            dense
            class="mb-4"
          />
          <v-select
            v-model="user.plan"
            :items="plans"
            item-text="text"
            item-value="value"
            label="Plan"
            outlined
            dense
          ></v-select>
          <v-text-field
            autocomplete="off"
            v-model="expirationDate"
            label="Plan expiration date"
            prepend-icon="mdi-calendar"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> Cancel </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text"
            type="submit"
            :disabled="!submitEnabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dh from '@/helpers/dateHelpers.js'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,
      user: {
        firstName: null,
        lastName: null,
        plan: null,
      },
      expirationDate: '',
      menu2: false,

      plans: [
        { text: 'BASIC', value: 'BASIC' },
        { text: 'PROFESSIONAL', value: 'PROFESSIONAL' },
        { text: 'PROFESSIONAL_CL', value: 'PROFESSIONAL_CL' },
      ],
    }
  },

  /************************************************************************************************/
  methods: {
    open(user) {
      this.user = Object.assign({}, user)
      console.log(user)
      console.log(user.planExpirationDate)
      if (user.planExpirationDate != null) {
        this.expirationDate = this.formatDate(new Date(user.planExpirationDate))
      } else {
        this.expirationDate = this.formatDate(new Date())
      }
      this.submitEnabled = true
      this.dialog = true

      setTimeout(() => {
        this.$refs.firstNameField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      // e.g. "2021-05-26T00:00:00.000Z"
      this.user.planExpirationDate = new Date(this.expirationDate).toISOString()
      this.resolve(this.user)
    },

    validate() {},

    formatDate(date) {
      return dh.formatDate(date)
    },
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {},
}
</script>

<style></style>
