<template>
  <v-app>
    <!--
    <v-system-bar app height="30"
      >isLoggedIn: {{ isLoggedIn }}, isAdmin: {{ isAdmin }}, backendReachable:
      {{ isBackendReachable }}
    </v-system-bar>
    -->

    <v-app-bar app clipped-left color="white" elevation="1">
      <div class="d-flex align-center">
        <router-link :to="{ name: 'Home' }">
          <v-img
            alt="airhdl Logo"
            src="@/assets/logo.png"
            max-height="50"
            max-width="200"
            contain
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <mq-layout mq="sm">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </mq-layout>

      <mq-layout mq="md+">
        <v-btn :to="{ name: 'Admin' }" class="mr-4" text v-if="isAdmin">
          Admin
        </v-btn>

        <v-btn :to="{ name: 'Plans' }" text class="mr-4" v-if="!isLoggedIn">
          Plans
        </v-btn>

        <v-btn :to="{ name: 'Products' }" text class="mr-4" v-if="!isLoggedIn">
          Products
        </v-btn>

        <v-btn href="/docs" target="_blank" text class="mr-4"> Docs </v-btn>

        <v-btn
          href="https://airhdl.com/blog/"
          target="_blank"
          text
          class="mr-4"
        >
          Blog
        </v-btn>

        <v-btn
          href="http://airhdl.uservoice.com/forums/279130-airhdl-feature-requests"
          target="_blank"
          class="mr-4"
          text
          v-if="isLoggedIn"
        >
          Suggest a Feature
        </v-btn>

        <v-btn :to="{ name: 'Account' }" class="mr-4" text v-if="isLoggedIn">
          Account
        </v-btn>

        <!-- Logout button -->
        <v-btn @click="onLogout" :loading="logoutInProgress" v-if="isLoggedIn">
          Logout
        </v-btn>

        <v-btn :to="{ name: 'Login' }" text class="mr-4" v-if="!isLoggedIn">
          Login
        </v-btn>

        <!-- Register button -->
        <v-btn
          :to="{ name: 'Signup' }"
          color="#337AB7"
          class="white--text"
          v-if="!isLoggedIn"
        >
          Sign up</v-btn
        >
      </mq-layout>
    </v-app-bar>

    <!--
      We set a key on the navigation drawer to ensure it is re-rendered
      properly once the user logs in
    -->
    <v-navigation-drawer
      :key="`drawer#${username}`"
      v-model="drawer"
      :clipped="true"
      :temporary="!isLoggedIn"
      :mobile-breakpoint="ViewportBreakpoint.Small"
      app
    >
      <v-list nav class="mt-4">
        <v-list-item
          v-for="item in navigationItems"
          :key="item.title"
          link
          :to="item.route ? { name: item.route } : null"
          :href="item.href ? item.href : null"
          :target="item.href ? '_blank' : null"
          :disabled="!isAllowed(item)"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon large>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-h6"
              >{{ item.title }} <sup v-if="item.title === 'LFSRs'">beta</sup>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <mq-layout v-if="isLoggedIn" mq="sm">
          <v-list-item link to="Account">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6">Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="onLogout">
            <v-list-item-content>
              <v-list-item-title class="text-h6">Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </mq-layout>
      </v-list>

      <template v-slot:append>
        <v-card elevation="0">
          <v-divider></v-divider>
          <v-card-text class="text-left">
            Logged in as:
            <router-link :to="{ name: 'Account' }">{{
              $store.getters.username
            }}</router-link>
            <v-divider class="mt-2 mb-2"></v-divider>
            {{ config.name }} {{ config.profile }}
            <a @click="onReleaseNotes">{{ config.version }}</a
            ><br />
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-alert
        dense
        type="warning"
        class="mx-7 mt-4 mb-0"
        dismissible
        outlined
        v-if="isLoggedIn && isProfessionalPlan && !subscriptionActive"
      >
        <span>
          Your subscription has expired on {{ planExpirationDate }}. Please
          renew it to continue using the features of the
          {{ subscriptionPlanUnmasked }} plan.
        </span>
      </v-alert>
      <router-view />
    </v-main>

    <v-footer padless v-if="!isLoggedIn">
      <v-col class="text-center pa-0" cols="12">
        <v-card flat tile class="text-center" color="grey lighten-3">
          <v-card-text>
            <v-btn
              v-for="icon in footerIcons"
              :key="icon.link"
              class="mx-4"
              icon
              link
              :href="icon.link"
              target="_blank"
            >
              <v-icon size="24px">
                {{ icon.name }}
              </v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text class="py-0">
            <v-btn
              v-for="link in footerLinks"
              :key="link.route"
              link
              :to="{ name: link.route }"
              text
              rounded
              class="font-weight-regular"
            >
              {{ link.text }}
            </v-btn>
          </v-card-text>
          <v-card-text>
            {{ new Date().getFullYear() }} — noasic GmbH
          </v-card-text>
        </v-card>
      </v-col>
    </v-footer>

    <!-- Terms and Conditions dialog -->
    <v-dialog v-model="termsDialog.show" persistent width="800">
      <v-card>
        <v-card-title class="text-h5 pt-3">Terms and Conditions</v-card-title>
        <v-card-text class="text--primary">
          To continue using the service please agree to our updated Terms and
          Conditions.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="text--primary mt-4">
          <div class="text-left" v-html="termsDialog.text"></div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            dark
            color="#337AB7"
            @click.stop="onTermsAgree"
            class="mb-4"
          >
            I Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="serverNotAvailableDialog" persistent width="480">
      <v-card>
        <v-card-title class="text-h5">Server not available</v-card-title>
        <v-card-text class="text--primary"
          >The connection to the server has been lost. Please wait until the
          connection is restored, or check your internet connection.
        </v-card-text>
      </v-card>
    </v-dialog>

    <ReleaseNotesDialog ref="releaseNotesDialog" />
  </v-app>
</template>

<script>
import * as date from '@/helpers/dateHelpers.js'
import { ViewportBreakpoint } from './global/viewport'
import { mapGetters } from 'vuex'
import strTerms from 'raw-loader!@/md/terms.md'
import api from '@/services/ApiService.js'
import ReleaseNotesDialog from '@/components/ReleaseNotesDialog.vue'
import settings from '@/json/settings.json'
import config from '@/json/config.json'
import pagedown from '@/libs/pagedown/Markdown.Converter.js'

export default {
  components: {
    ReleaseNotesDialog,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data: () => ({
    drawer: null,
    ViewportBreakpoint,
    logoutInProgress: false,
    userReloadTimer: null,
    //tokenRefreshTimer: null,
    serverNotAvailableDialog: false,

    termsDialog: {
      show: false,
      text: '',
    },

    footerIcons: [
      {
        name: 'mdi-twitter',
        link: 'https://twitter.com/airhdl',
      },
      {
        name: 'mdi-linkedin',
        link: 'https://www.linkedin.com/company/airhdl',
      },
      {
        name: 'mdi-youtube',
        link: 'https://www.youtube.com/channel/UC-wMEudDXtPUOeG5k5XmWgA',
      },
      {
        name: 'mdi-github',
        link: 'https://github.com/airhdl',
      },
    ],

    footerLinks: [
      { text: 'Terms and Conditions', route: 'Terms' },
      { text: 'Privacy', route: 'Privacy' },
      { text: 'Contact', route: 'Contact' },
    ],

    // For mobile view
    loggedOutNavigationItems: [
      { title: 'Docs', route: null, href: '/docs' },
      { title: 'Plans', route: 'Plans', href: null },
      { title: 'Products', route: 'Products', href: null },
      { title: 'Blog', route: null, href: 'https://airhdl.com/blog/' },
      { title: 'Login', route: 'Login', href: null },
      { title: 'Sign up', route: 'Signup', href: null },
    ],

    loggedInNavigationItems: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', route: 'Dashboard' },
      { title: 'Projects', icon: 'mdi-folder-outline', route: 'Projects' },
      { title: 'Register Maps', icon: 'mdi-sitemap', route: 'RegisterMaps' },
      { title: 'LFSRs', icon: 'mdi-view-column-outline', route: 'LFSRs' },
      { title: 'Generator', icon: 'mdi-cogs', route: 'Generator' },
    ],
  }),

  /************************************************************************************************
   * Methods
   ************************************************************************************************/

  methods: {
    // Clicked the "Release Notes" link
    onReleaseNotes() {
      this.$refs.releaseNotesDialog.open()
    },

    async reloadUser() {
      await this.$store.dispatch('reloadUser')
    },

    // Clicked the "Agree" button in the Terms and Conditions dialog
    onTermsAgree() {
      var userDto = {
        id: this.user.id,
        termsAgreedVersion: this.settings.termsVersion,
      }
      api.users
        .saveUser(this.user.id, userDto)
        .then(() => {
          this.reloadUser() // reload user to update its termsAgreedVersion property
          this.termsDialog.show = false
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showMessage('Error: could not save terms agreed information')
        })
    },

    showTermsAndConditions() {
      var converter = new pagedown.Converter()
      this.termsDialog.text = converter.makeHtml(strTerms)
      this.termsDialog.show = true
    },

    isAllowed(item) {
      if (item.route === 'Projects' && !this.allowProjects) {
        return false
      }
      if (item.route === 'Generator' && !this.allowCommandLineGenerator) {
        return false
      }
      return true
    },

    // Clicked "Logout" button
    onLogout() {
      console.log('app: logout')
      // console.log(this.$route.params)
      this.logoutInProgress = true
      this.$store.dispatch('logout')
    },

    enableUserVoiceWidget() {
      window.UserVoice = []
      ;(function () {
        var uv = document.createElement('script')
        uv.type = 'text/javascript'
        uv.async = true
        uv.src =
          '//airhdl.uservoice.com/widget_environment/arC9nPK7nNOtSNDLNm4uRw.js'
        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(uv, s)
      })()

      window.UserVoice.push([
        'set',
        {
          accent_color: '#337AB7',
          trigger_color: 'white',
          trigger_background_color: 'rgba(46, 49, 51, 0.6)',
        },
      ])

      // Identify user
      // see: https://developer.uservoice.com/docs/widgets/identify/
      window.UserVoice.push([
        'identify',
        {
          email: this.$store.getters.username,
          id: this.$store.state.user.id,
        },
      ])
      window.UserVoice.push([
        'addTrigger',
        { mode: 'contact', trigger_position: 'bottom-right' },
      ])
    },

    disableUserVoiceWidget() {
      window.UserVoice = []
    },

    // Reload user information
    onUserReloadTimer() {
      this.reloadUser()
      if (this.user.termsAgreedVersion < this.settings.termsVersion) {
        this.showTermsAndConditions()
      }
    },

    // Backend test timer handler
    onBackendTestTimer() {
      // console.log('app: check backend connectivity...')
      api.actuator
        .getApplicationHealth()
        .then((response) => {
          if ('status' in response) {
            this.$store.dispatch(
              'setBackendReachable',
              response.status === 'UP'
            )
          } else {
            console.log('app: backend is DOWN')
            this.$store.dispatch('setBackendReachable', false)
          }
        })
        .catch(() => {
          console.log('app: backend is DOWN')
          this.$store.dispatch('setBackendReachable', false)
        })
    },
  },

  /************************************************************************************************
   * Computed Properties
   ************************************************************************************************/

  computed: {
    ...mapGetters([
      'allowProjects',
      'isProfessionalPlan',
      'subscriptionActive',
      'subscriptionPlanUnmasked',
      'username',
      'isLoggedIn',
      'allowCommandLineGenerator',
      'user',
      'isBackendReachable',
    ]),

    navigationItems() {
      return this.isLoggedIn
        ? this.loggedInNavigationItems
        : this.loggedOutNavigationItems
    },

    isAdmin() {
      return this.$store.getters.isLoggedIn && this.$store.getters.isAdmin
    },

    planExpirationDate() {
      let planExpirationDate = this.$store.getters.planExpirationDate // e.g. 2022-06-18T00:00:00Z
      if (planExpirationDate) {
        return date.formatDateDDMMMYYYY(
          new Date(this.$store.state.user.planExpirationDate)
        )
      }
      return null
    },

    profile() {
      return settings.profile
    },

    settings() {
      return settings
    },

    config() {
      return config
    },
  },

  /************************************************************************************************
   * Watchers
   ************************************************************************************************/

  watch: {
    isBackendReachable(newValue) {
      if (newValue) {
        // backend reachable
        this.serverNotAvailableDialog = false
      } else {
        if (this.isLoggedIn) {
          this.serverNotAvailableDialog = true
        }
      }
    },

    isLoggedIn(newValue) {
      console.log('app: isLoggedIn ' + newValue)
      console.log('app: current route is ' + this.$route.name)
      if (newValue) {
        // User just logged in
        this.enableUserVoiceWidget() // show UserVoice widget

        // this.$router.push({ name: 'Dashboard' })

        // Refresh access token periodically
        /*
        if (this.tokenRefreshTimer == null) {
          console.log(
            'app: token refresh period is ' +
              settings.tokenRefreshPeriodSec +
              ' sec'
          )
          this.tokenRefreshTimer = setInterval(
            this.onTokenRefreshTimer,
            settings.tokenRefreshPeriodSec * 1000
          )
        }*/

        // Reload user information periodically
        if (this.userReloadTimer == null) {
          console.log(
            'app: user reload period is ' +
              settings.userReloadPeriodSec +
              ' sec'
          )
          this.userReloadTimer = setInterval(
            this.onUserReloadTimer,
            settings.userReloadPeriodSec * 1000
          )
        }
      } else {
        // User just logged out
        this.disableUserVoiceWidget() // hide UserVoice widget

        // Stop refreshing access token
        /*
        if (this.tokenRefreshTimer != null) {
          clearInterval(this.tokenRefreshTimer)
          this.usertokenRefreshTimerReloadTimer = null
        }*/

        // Stop reloading user information
        if (this.userReloadTimer != null) {
          clearInterval(this.userReloadTimer)
          this.userReloadTimer = null
        }
      }
    },
  },

  /************************************************************************************************
   * Created Hook
   ************************************************************************************************/

  created() {
    console.log('app: created')

    // Test if backend server is reachable now
    this.onBackendTestTimer()

    // Set up a timer to test if the backend server is reachable periodically
    this.backendTestTimer = setInterval(
      this.onBackendTestTimer,
      settings.backendTestPeriodSec * 1000
    )
  },

  /************************************************************************************************
   * Mounted Hook
   ************************************************************************************************/

  mounted() {},
}
</script>

<style>
/* do not underline links */
a {
  text-decoration: none;
}

/* Hide the osano cookie widget (see https://docs.osano.com/hiding-the-cookie-widget) */
.osano-cm-widget {
  display: none;
}

.CookieButton {
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-transform: uppercase;
  background-color: #337ab7;
  border-radius: 4px;
  white-space: nowrap;
  padding: 0 19.5555555556px;
  height: 44px;
  font-size: 0.875rem;
}
</style>
