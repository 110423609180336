<template>
  <v-container fluid fill-height>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4 mt-4 mx-auto" max-width="480">
          <v-sheet color="#337AB7" elevation="0" height="5" tile></v-sheet>
          <v-card-title class="text-h4 font-weight-medium">
            Sign up
          </v-card-title>
          <v-form ref="form" @submit.prevent="onSubmit" v-model="form.isValid">
            <v-card-text class="text-body-1">
              <v-text-field
                :rules="emailRules"
                v-model="form.user.username"
                prepend-icon="mdi-account-circle"
                label="Email"
                autocomplete="username"
                ref="userNameField"
                type="email"
              />
              <v-text-field
                :rules="passwordRules"
                v-model="form.user.password"
                prepend-icon="mdi-lock"
                :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="form.showPassword ? 'text' : 'password'"
                label="Password"
                @click:append="form.showPassword = !form.showPassword"
                autocomplete="new-password"
              />
              <p
                class="text-body-2 ml-8 mt-2"
                v-show="form.user.password.length > 0"
              >
                Password strength:
                <span
                  :class="
                    strengthColor(form.user.password) + ' font-weight-medium'
                  "
                  >{{ strength(form.user.password) }}</span
                >
              </p>
              <p class="text-body-2 mt-4">
                By clicking the "Sign Up" button below, you agree to our
                <router-link :to="{ name: 'Terms' }"
                  >Terms and Conditions</router-link
                >
                as well as to our
                <router-link :to="{ name: 'Privacy' }"
                  >Privacy Policy</router-link
                >.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                type="submit"
                class="white--text"
                color="#337AB7"
                :loading="form.loading"
                large
                :disabled="!form.isValid"
                >Sign up</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-card-text class="text-body-1">
            Already have an account?
            <router-link :to="{ name: 'Login' }">Login</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <vue-recaptcha
      ref="recaptcha"
      sitekey="6LevYGEbAAAAABnwOt5qE-TQZSlmE1MGyAlzKed_"
      :loadRecaptchaScript="true"
      @verify="onRecaptchaVerify"
      @expired="onRecaptchaExpired"
      @error="onRecaptchaError"
      size="invisible"
    ></vue-recaptcha>
    <MessageSnack v-model="errorMessage.show" :text="errorMessage.text" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import global from '@/global/index.js'
import MessageSnack from '@/components/MessageSnack.vue'
import * as pw from '@/helpers/passwordHelpers.js'
import VueRecaptcha from 'vue-recaptcha'
import settings from '@/json/settings.json'

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/

  components: {
    MessageSnack,
    VueRecaptcha,
  },

  /************************************************************************************************
   * Created Hook
   ************************************************************************************************/

  created() {},

  /************************************************************************************************
   * Methods
   ************************************************************************************************/

  methods: {
    onSubmit() {
      console.log('onSubmit')
      if (!this.$refs.form.validate()) {
        this.snackbar.show = true
        this.snackbar.text =
          'Error: please make sure that all fields have valid values'
        return
      }
      this.$refs.recaptcha.execute()
    },

    onRecaptchaExpired() {
      console.log('onRecaptchaExpired')
      this.$refs.recaptcha.reset()
    },

    onRecaptchaError() {
      console.log('onRecaptchaError')
      this.$refs.recaptcha.reset()
    },

    onRecaptchaVerify(response) {
      console.log('onRecaptchaVerify')
      this.$refs.recaptcha.reset()
      this.register(response)
    },

    showErrorMessage(message) {
      this.errorMessage.text = 'Error: ' + message
      this.errorMessage.show = true
    },

    // From https://stackoverflow.com/a/46181/63730
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    // Clicked "Register" button
    register(recaptcha) {
      this.form.loading = true
      var userCredentialsDto = Object.assign({}, this.form.user)
      userCredentialsDto.recaptcha = recaptcha
      userCredentialsDto.termsAgreedVersion = settings.termsVersion
      api.auth
        .registerUser(userCredentialsDto)
        .then(() => {
          this.form.loading = false
          // Google Ads conversion tracking
          // see: https://developers.google.com/tag-platform/gtagjs/reference/events#sign_up
          this.$gtag.event('sign_up')
          this.$router.push({ name: 'VerificationLinkSent' })
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          console.log(error)
          this.form.loading = false
          this.showErrorMessage(error.body.message)
        })
    },

    strength(password) {
      return pw.passwordStrength(password)
    },

    strengthColor(password) {
      return pw.passwordStrengthColor(password)
    },
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/

  computed: {
    emailRules() {
      return global.emailRules
    },

    passwordRules() {
      return global.passwordRules
    },

    settings() {
      return settings
    },
  },
  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data() {
    return {
      errorMessage: {
        show: false,
        text: '',
      },

      dialog: {
        show: false,
        text: '',
      },

      snackbar: {
        show: false,
        text: '',
      },

      form: {
        showPassword: false,
        loading: false,
        disabled: true,
        isValid: false,
        user: {
          username: '',
          password: '',
          recaptcha: '',
        },
      },
    }
  },
}
</script>
