"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ProjectDto = _interopRequireDefault(require("../model/ProjectDto"));
var _RegisterMapInstanceDto = _interopRequireDefault(require("../model/RegisterMapInstanceDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Projects service.
* @module api/ProjectsApi
* @version 1.0.0
*/
class ProjectsApi {
  /**
  * Constructs a new ProjectsApi. 
  * @alias module:api/ProjectsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Add register map instances to a project
   * @param {Number} projectId 
   * @param {Array.<module:model/RegisterMapInstanceDto>} registerMapInstanceDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  addRegisterMapInstancesWithHttpInfo(projectId, registerMapInstanceDto) {
    let postBody = registerMapInstanceDto;
    // verify the required parameter 'projectId' is set
    if (projectId === undefined || projectId === null) {
      throw new Error("Missing the required parameter 'projectId' when calling addRegisterMapInstances");
    }
    // verify the required parameter 'registerMapInstanceDto' is set
    if (registerMapInstanceDto === undefined || registerMapInstanceDto === null) {
      throw new Error("Missing the required parameter 'registerMapInstanceDto' when calling addRegisterMapInstances");
    }
    let pathParams = {
      'projectId': projectId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/projects/{projectId}/registerMapInstances', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Add register map instances to a project
   * @param {Number} projectId 
   * @param {Array.<module:model/RegisterMapInstanceDto>} registerMapInstanceDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  addRegisterMapInstances(projectId, registerMapInstanceDto) {
    return this.addRegisterMapInstancesWithHttpInfo(projectId, registerMapInstanceDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Create a new project
   * @param {module:model/ProjectDto} projectDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createProjectWithHttpInfo(projectDto) {
    let postBody = projectDto;
    // verify the required parameter 'projectDto' is set
    if (projectDto === undefined || projectDto === null) {
      throw new Error("Missing the required parameter 'projectDto' when calling createProject");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/projects', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new project
   * @param {module:model/ProjectDto} projectDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createProject(projectDto) {
    return this.createProjectWithHttpInfo(projectDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete a project
   * @param {Number} projectId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteProjectWithHttpInfo(projectId) {
    let postBody = null;
    // verify the required parameter 'projectId' is set
    if (projectId === undefined || projectId === null) {
      throw new Error("Missing the required parameter 'projectId' when calling deleteProject");
    }
    let pathParams = {
      'projectId': projectId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/projects/{projectId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete a project
   * @param {Number} projectId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteProject(projectId) {
    return this.deleteProjectWithHttpInfo(projectId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete a register map instance from a project
   * @param {Number} projectId 
   * @param {Number} registerMapInstanceId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteRegisterMapInstanceWithHttpInfo(projectId, registerMapInstanceId) {
    let postBody = null;
    // verify the required parameter 'projectId' is set
    if (projectId === undefined || projectId === null) {
      throw new Error("Missing the required parameter 'projectId' when calling deleteRegisterMapInstance");
    }
    // verify the required parameter 'registerMapInstanceId' is set
    if (registerMapInstanceId === undefined || registerMapInstanceId === null) {
      throw new Error("Missing the required parameter 'registerMapInstanceId' when calling deleteRegisterMapInstance");
    }
    let pathParams = {
      'projectId': projectId,
      'registerMapInstanceId': registerMapInstanceId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/projects/{projectId}/registerMapInstances/{registerMapInstanceId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete a register map instance from a project
   * @param {Number} projectId 
   * @param {Number} registerMapInstanceId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteRegisterMapInstance(projectId, registerMapInstanceId) {
    return this.deleteRegisterMapInstanceWithHttpInfo(projectId, registerMapInstanceId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get a project
   * @param {Number} projectId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProjectDto} and HTTP response
   */
  getProjectWithHttpInfo(projectId) {
    let postBody = null;
    // verify the required parameter 'projectId' is set
    if (projectId === undefined || projectId === null) {
      throw new Error("Missing the required parameter 'projectId' when calling getProject");
    }
    let pathParams = {
      'projectId': projectId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _ProjectDto.default;
    return this.apiClient.callApi('/projects/{projectId}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get a project
   * @param {Number} projectId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProjectDto}
   */
  getProject(projectId) {
    return this.getProjectWithHttpInfo(projectId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get all projects
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProjectDto>} and HTTP response
   */
  getProjectsWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_ProjectDto.default];
    return this.apiClient.callApi('/projects', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get all projects
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProjectDto>}
   */
  getProjects() {
    return this.getProjectsWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update a project
   * @param {Number} projectId 
   * @param {module:model/ProjectDto} projectDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  saveProjectWithHttpInfo(projectId, projectDto) {
    let postBody = projectDto;
    // verify the required parameter 'projectId' is set
    if (projectId === undefined || projectId === null) {
      throw new Error("Missing the required parameter 'projectId' when calling saveProject");
    }
    // verify the required parameter 'projectDto' is set
    if (projectDto === undefined || projectDto === null) {
      throw new Error("Missing the required parameter 'projectDto' when calling saveProject");
    }
    let pathParams = {
      'projectId': projectId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/projects/{projectId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update a project
   * @param {Number} projectId 
   * @param {module:model/ProjectDto} projectDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  saveProject(projectId, projectDto) {
    return this.saveProjectWithHttpInfo(projectId, projectDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Set register map instance
   * @param {Number} projectId 
   * @param {Number} registerMapInstanceId 
   * @param {module:model/RegisterMapInstanceDto} registerMapInstanceDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  setRegisterMapInstanceWithHttpInfo(projectId, registerMapInstanceId, registerMapInstanceDto) {
    let postBody = registerMapInstanceDto;
    // verify the required parameter 'projectId' is set
    if (projectId === undefined || projectId === null) {
      throw new Error("Missing the required parameter 'projectId' when calling setRegisterMapInstance");
    }
    // verify the required parameter 'registerMapInstanceId' is set
    if (registerMapInstanceId === undefined || registerMapInstanceId === null) {
      throw new Error("Missing the required parameter 'registerMapInstanceId' when calling setRegisterMapInstance");
    }
    // verify the required parameter 'registerMapInstanceDto' is set
    if (registerMapInstanceDto === undefined || registerMapInstanceDto === null) {
      throw new Error("Missing the required parameter 'registerMapInstanceDto' when calling setRegisterMapInstance");
    }
    let pathParams = {
      'projectId': projectId,
      'registerMapInstanceId': registerMapInstanceId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/projects/{projectId}/registerMapInstances/{registerMapInstanceId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Set register map instance
   * @param {Number} projectId 
   * @param {Number} registerMapInstanceId 
   * @param {module:model/RegisterMapInstanceDto} registerMapInstanceDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  setRegisterMapInstance(projectId, registerMapInstanceId, registerMapInstanceDto) {
    return this.setRegisterMapInstanceWithHttpInfo(projectId, registerMapInstanceId, registerMapInstanceDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = ProjectsApi;