<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h4 font-weight-medium pl-0">
              <div>
                <span class="mr-4">Account</span>
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y close-on-click v-model="showSettingsMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="elevation-0" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <template v-for="(item, index) in settingsMenuItems">
                    <v-divider v-if="index === 2" :key="100 + index"></v-divider>
                    <v-list-item :key="index" :value="item" @click.stop="item.action">
                      <template v-slot:default>
                        <v-list-item-content v-text="item.title">
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-app-bar>
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="text-h5 font-weight-medium pb-1">
            Your Info
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text-body-1 black--text mt-4">
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">User ID</v-col>
              <v-col cols="9" class="text-left"><span>{{ user.id }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Email</v-col>
              <v-col cols="9" class="text-left"><span>{{ $store.state.user.username }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Name</v-col>
              <v-col cols="9" class="text-left"><span> {{ name || '&ndash;' }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Registration Date</v-col>
              <v-col cols="9" class="text-left"><span> {{ formatDate(user.registrationDate) }}</span>
              </v-col>
            </v-row>
            <v-row v-if="features.length > 0">
              <v-col cols="3" class="text-left font-weight-bold">Preview Feature(s)</v-col>
              <v-col cols="9" class="text-left"> <v-chip label small class="mr-2" v-for="feature in features"
                  :key="feature">{{ feature }}</v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title class="text-h5 font-weight-medium pb-1">
            <span>Subscription</span>
            <v-spacer></v-spacer>
            <v-btn class="ml-4 white--text mb-2" :disabled="!showUpgradeSubscription" @click="onUpgrade"
              color="#337AB7"><v-icon class="mr-2">mdi-briefcase</v-icon>Upgrade</v-btn>
            <v-btn class="ml-4 mb-2" :disabled="!user.subscriptionUpdateUrl" @click="onsubscriptionUpdate"
              target="_blank">
              <v-icon class="mr-2">mdi-credit-card-outline</v-icon>
              Update
            </v-btn>
            <v-btn class="ml-4 mb-2" :disabled="!user.subscriptionCancelUrl" @click="onsubscriptionCancel"
              target="_blank">
              <v-icon class="mr-2"> mdi-stop </v-icon>
              Cancel</v-btn>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text-body-1 black--text mt-4">
            <v-row v-if="user.subscriptionId">
              <v-col cols="3" class="text-left font-weight-bold">Subscription ID</v-col>
              <v-col cols="9" class="text-left"><span> {{ user.subscriptionId }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">Plan</v-col>
              <v-col cols="9" class="text-left">
                <span>{{ user.plan }}</span>
              </v-col>
            </v-row>
            <!-- Old (non-paddle) professional plan -->
            <v-row v-if="user.plan !== 'BASIC' && user.subscriptionId == null">
              <v-col cols="3" class="text-left font-weight-bold">Status</v-col>
              <v-col cols="9" class="text-left"><v-chip label small
                  :color="user.subscriptionExpired ? 'warning' : 'success'">subscription
                  {{ user.subscriptionExpired ? 'expired' : 'active' }}</v-chip>
              </v-col>
            </v-row>
            <!-- New (paddle-managed) profession plan -->
            <v-row v-if="user.plan !== 'BASIC' && user.subscriptionId != null">
              <v-col cols="3" class="text-left font-weight-bold">Status</v-col>
              <v-col cols="9" class="text-left"><v-chip label small
                  :color="subscriptionColor(user.subscriptionStatus)">subscription {{ user.subscriptionStatus
                  }}</v-chip>
              </v-col>
            </v-row>
            <v-row v-if="user.planExpirationDate && user.plan !== 'BASIC'">
              <v-col cols="3" class="text-left font-weight-bold">Expiration Date</v-col>
              <v-col cols="9" class="text-left"><span> {{ formatDate(user.planExpirationDate) }}</span>
              </v-col>
            </v-row>
            <v-row v-if="user.subscriptionPausedFrom">
              <v-col cols="3" class="text-left font-weight-bold">Paused from</v-col>
              <v-col cols="9" class="text-left"><span> {{ formatDate(user.subscriptionPausedFrom) }}</span>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-title class="text-h5 font-weight-medium pb-1">
            Payments
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text-body-1 black--text mt-4">
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold">History</v-col>
              <v-col cols="9">
                <v-data-table :headers="paymentTableHeaders" :items="payments" no-data-text="No payments available."
                  dense>
                  <template v-slot:item.receipt_url="{ item }">
                    <v-btn small :href="item.receipt_url" target="_blank" v-if="item.receipt_url" class="my-2">View
                      Receipt</v-btn>
                  </template>
                  <template v-slot:item.is_paid="{ item }">
                    <v-checkbox hide-details v-model="item.is_paid" readonly class="mt-0"></v-checkbox>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MessageSnack v-model="message.show" :text="message.text" />
    <ConfirmDialog ref="confirmationDialog" />
    <AccountDialog ref="accountDialog" />
    <ChangePasswordDialog ref="changePasswordDialog" />

    <!-- Upgrade dialog -->
    <v-dialog v-model="upgradeDialog" max-width="1250px">
      <v-card>
        <v-card-title>
          <span class="headline">Upgrade Subscription</span>
          <v-spacer></v-spacer>
          <v-icon @click="upgradeDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mb-4"></v-divider>
        <v-card-text class="pa-0">
          <v-stepper v-model="upgradeStep" flat>
            <v-stepper-header>
              <v-stepper-step @click="onStep1Clicked" editable :complete="upgradeStep > 1" step="1">
                Select Plan
                <small>{{ upgradeSelectedPlanText }}</small>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="upgradeStep > 2" step="2">
                Provide Payment Details
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="py-1">
                <v-card flat>
                  <Plans ref="plans" @select="onSelectPlan" />
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2" class="py-1">
                <v-card flat>
                  <div class="checkout-container"></div>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import global from '@/global/index.js'
import MessageSnack from '@/components/MessageSnack.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import AccountDialog from '@/components/AccountDialog.vue'
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue'
import Plans from '@/components/Plans.vue'
import plans from '@/json/plans.json'
import { mapGetters } from 'vuex'

export default {
  /************************************************************************************************
   * Components
   ************************************************************************************************/
  components: {
    MessageSnack,
    ConfirmDialog,
    AccountDialog,
    ChangePasswordDialog,
    Plans,
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {
    this.$store.dispatch('reloadUser') // refresh the user when opening this view

    api.users
      .getPayments(this.user.id)
      .then((response) => {
        this.payments = response
      })
      .catch((error) => {
        if (error.status === 403) {
          this.$store.dispatch('logout')
        }
        this.showMessage('Error: could not load payments')
      })
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      changePasswordLoading: false,
      saveNameLoading: false,
      newsletterLoading: false,
      deleteAccountLoading: false,
      showSettingsMenu: false,
      upgradeDialog: false,
      plans: plans,
      upgradeStep: 1,
      upgradeSelectedPlan: null,
      upgradeSelectedPlanPayMonthly: false,
      pauseSubscriptionLoading: false,
      resumeSubscriptionLoading: false,
      payments: [],

      paymentTableHeaders: [
        {
          text: 'ID',
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Date',
          align: 'left',
          sortable: true,
          value: 'payout_date',
        },
        {
          text: 'Currency',
          align: 'left',
          sortable: false,
          value: 'currency',
        },
        {
          text: 'Amount',
          align: 'left',
          sortable: false,
          value: 'amount',
        },
        {
          text: 'Paid',
          align: 'left',
          sortable: false,
          value: 'is_paid',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'receipt_url',
        },
      ],

      message: {
        show: false,
        text: '',
      },

      settingsMenuItems: [
        { title: 'Edit Account Information', action: this.onEditAccount },
        { title: 'Change Password', action: this.onChangePassword },
        { title: 'Delete Account', action: this.onDeleteAccount },
      ],
    }
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    // Clicked "pause subscription" button
    onPauseSubscription() {
      this.pauseSubscriptionLoading = true
      let opts = {
        action: 'pause',
      }
      api.users
        .updateSubscription(this.user.id, opts)
        .then(() => {
          this.$store.dispatch('reloadUser')
          this.pauseSubscriptionLoading = false
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.pauseSubscriptionLoading = false
          this.showMessage('Error: could not pause the subscription')
        })
    },

    // Clicked "resume subscription" button
    onResumeSubscription() {
      this.resumeSubscriptionLoading = true
      let opts = {
        action: 'resume',
      }
      api.users
        .updateSubscription(this.user.id, opts)
        .then(() => {
          this.$store.dispatch('reloadUser')
          this.resumeSubscriptionLoading = false
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.resumeSubscriptionLoading = false
          this.showMessage('Error: could not resume the subscription')
        })
    },

    // Clicked "update payment method"
    onsubscriptionUpdate() {
      window.Paddle.Checkout.open({
        override: this.user.subscriptionUpdateUrl,
      })
    },

    // Clicked "cancel subscription"
    onsubscriptionCancel() {
      window.Paddle.Checkout.open({
        override: this.user.subscriptionCancelUrl,
      })
    },

    subscriptionColor(status) {
      if (status.toLowerCase() === 'active') {
        return 'success'
      } else if (status.toLowerCase() === 'trialing') {
        return 'success'
      } else if (status.toLowerCase() === 'past_due') {
        return 'warning'
      } else if (status.toLowerCase() === 'paused') {
        return 'note'
      } else if (status.toLowerCase() === 'deleted') {
        return 'warning'
      }
    },

    // Clicked on the "Select Plan" step
    onStep1Clicked() {
      this.upgradeSelectedPlan = null
      this.upgradeSelectedPlanPayMonthly = null
    },

    // Clicked the "upgrade" button
    onUpgrade() {
      this.upgradeDialog = true
      this.upgradeSelectedPlan = null
      this.upgradeSelectedPlanPayMonthly = null
      this.upgradeStep = 1
    },

    // Selected a plan in the "Upgrade Subscription" dialog
    onSelectPlan(productId, productName, payMonthly) {
      console.log(
        'account: selected plan id = ' + productId + ', name = ' + productName
      )
      this.upgradeStep = 2
      this.upgradeSelectedPlan = productName
      this.upgradeSelectedPlanPayMonthly = payMonthly
      window.Paddle.Checkout.open({
        product: productId,
        email: this.$store.getters.username,
        passthrough: `{"user_id": ${this.$store.getters.user.id} }`,
        locale: 'en',
        marketingConsent: '0',
        allowQuantity: false,
        disableLogout: true,
        frameTarget: 'checkout-container', // The className of your checkout <div>
        method: 'inline',
        frameInitialHeight: 416,
        frameStyle:
          'width:100%; min-width:312px; background-color: transparent; border: none;',
      })
    },

    formatDate(date) {
      if (!date) {
        return ''
      }
      var d = new Date(date)
      var day = d.getDate() <= 9 ? '0' + d.getDate() : d.getDate()
      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      return (
        day + '-' + months[d.getMonth()].toUpperCase() + '-' + d.getFullYear()
      )
    },

    showMessage(text) {
      console.log('showMessage')
      this.message.text = text
      this.message.show = true
    },

    // Clicked the "Edit Account" menu item
    onEditAccount() {
      this.$refs.accountDialog
        .open(this.user)
        .then((user) => {
          var userDto = {
            firstName: user.firstName,
            lastName: user.lastName,
          }
          api.users
            .saveUser(this.user.id, userDto)
            .then(() => {
              this.$store.dispatch('reloadUser')
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showMessage('Error: could not save user information')
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    // Clicked the "Delete Account" menu item
    async onDeleteAccount() {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the account for ${this.user.username}? This action cannot be undone.`
        )
      ) {
        api.users
          .deleteUser(this.user.id)
          .then(() => {
            this.deleteAccountLoading = false
            this.$store.dispatch('logout')
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.deleteAccountLoading = false
            this.showMessage('Error: could not delete this account.')
          })
      }
    },

    // Clicked "Edit" name link
    onEditName() {
      this.showEditNameForm = true
    },

    // Clicked "Change" password link
    onChangePassword() {
      this.$refs.changePasswordDialog
        .open()
        .then((passwords) => {
          api.users
            .savePassword(this.user.id, passwords)
            .then(() => {
              this.showMessage('Your password was changed successfully.')
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showMessage('Error: ' + error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },
  },

  /************************************************************************************************
   * Watches
   ************************************************************************************************/
  watch: {
    showChangePasswordForm: function (newValue) {
      if (newValue) {
        // form is being shown
        this.newPassword = ''
        this.oldPassword = ''
      }
    },
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {

    ...mapGetters([
      'features',
    ]),

    showUpgradeSubscription() {
      if (this.$store.getters.subscriptionPlanMasked === 'BASIC') {
        // BASIC plan, or expired professional plan
        return true
      }
      return false
    },

    upgradeSelectedPlanText() {
      if (this.upgradeSelectedPlan) {
        return (
          this.upgradeSelectedPlan +
          ', ' +
          (this.upgradeSelectedPlanPayMonthly
            ? 'billed monthly'
            : 'billed yearly')
        )
      }
      return ''
    },

    user() {
      return this.$store.getters.user
    },

    passwordRules() {
      return global.passwordRules
    },

    subscribedText() {
      if (this.form.subscribed) {
        return 'subscribed'
      }
      return 'not subscribed'
    },

    name() {
      if (this.firstName === '' && this.lastNName === '') {
        return null
      }
      return this.firstName + ' ' + this.lastNName
    },

    firstName() {
      if (this.user == null) {
        return ''
      }
      if (this.user.firstName == null) {
        return ''
      }
      return this.user.firstName
    },

    lastNName() {
      if (this.user == null) {
        return ''
      }
      if (this.user.lastName == null) {
        return ''
      }
      return this.user.lastName
    },

    getSubscribed() {
      if (this.user == null) {
        return false
      }
      if (this.user.lastName == null) {
        return false
      }
      return this.user.subscribed
    },
  },
}
</script>

<style>

</style>
