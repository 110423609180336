<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium pb-1">
            Projects
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-data-table
            :headers="projectTableHeaders"
            :items="projects"
            class="elevation-1 mx-4 mt-4 text-body-1 black--text"
            hide-default-footer
            :loading="projectsLoading"
            loading-text="Loading..."
            @click:row="onProjectTableRowClick"
            no-data-text="You haven't created any projects yet."
            show-select
            v-model="selectedProjects"
            disable-pagination
          >
            <!-- name column -->
            <template v-slot:item.name="{ item }">
              <span class="font-weight-medium">{{ item.name }}</span>
            </template>
            <!-- Description column -->
            <template v-slot:item.description="{ item }">
              {{ shorten(item.description || '&ndash;') }}
            </template>
            <!-- Actions column -->
            <template v-slot:item.actions="{ item }">
              <v-icon dense class="mr-4" @click.stop="onEditProject(item)">
                mdi-pencil
              </v-icon>
              <v-icon dense class="mr-4" @click.stop="onDuplicateProject(item)">
                mdi-content-copy
              </v-icon>
              <v-icon dense @click.stop="onDeleteProject(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-card-actions class="mt-4 mb-10 mx-2">
            <v-btn
              small
              v-show="selectedProjects.length > 0"
              @click="onDeleteSelectedProjects"
              >Delete selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-tooltip bottom v-model="showNewProjectTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  fab
                  dark
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="onNewProject"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Create new Project</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ProjectDialog ref="projectDialog" />
    <ConfirmDialog ref="confirmationDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import utils from '@/global/utils.js'
import ProjectDialog from '@/components/ProjectDialog.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import MessageSnack from '@/components/MessageSnack.vue'
import { mapGetters } from 'vuex'
import settings from '@/json/settings.json'

export default {
  components: {
    ProjectDialog,
    ConfirmDialog,
    MessageSnack,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/

  data() {
    return {
      projects: [],
      reloadTimer: null,
      projectsLoading: false,
      selectedProjects: [],
      showNewProjectTooltip: false,

      message: {
        show: false,
        text: '',
      },

      projectTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '70%',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em', // make sure icons don't wrap
        },
      ],
    }
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {
    ...mapGetters(['allowProjects']),
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/

  created() {
    if (!this.allowProjects) {
      console.log(
        "Projects: user doesn't have access to projects; redirecting to dashboard..."
      )
      this.$router.push({ name: 'Dashboard' })
    } else {
      this.loadProjects(true)
      this.reloadTimer = setInterval(
        this.loadProjects,
        settings.autoReloadPeriodSec * 1000
      )
    }
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/

  methods: {
    shorten(description) {
      return utils.shorten(description, settings.maxDescriptionLength)
    },

    deleteProject(project) {
      return api.projects.deleteProject(project.id).catch((error) => {
        if (error.status === 403) {
          this.$store.dispatch('logout')
        }
        this.showErrorMessage(error.body.message)
      })
    },

    async onDeleteSelectedProjects() {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the selected project(s)? This action cannot be undone.`
        )
      ) {
        let results = []
        for (var i = 0; i < this.selectedProjects.length; i++) {
          results.push(this.deleteProject(this.selectedProjects[i]))
        }
        Promise.all(results)
          .then(() => {
            this.loadProjects(false)
          })
          .catch((error) => {
            this.showErrorMessage(error.body.message)
          })
        this.selectedProjects = []
      }
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    hideNewProjectTooltip() {
      setTimeout(() => {
        this.showNewProjectTooltip = false
      }, 0)
    },

    // User clicked the "New project" button
    onNewProject() {
      this.$refs.projectDialog
        .open(null)
        .then((result) => {
          this.hideNewProjectTooltip()
          api.projects
            .createProject(result)
            .then(() => {
              this.loadProjects(false)
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
          this.hideNewProjectTooltip()
        })
    },

    // User clicked the "Edit project" icon
    onEditProject(item) {
      this.$refs.projectDialog
        .open(item)
        .then((result) => {
          return api.projects
            .saveProject(item.id, result)
            .then(() => {
              this.loadProjects(false)
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    // User clicked the "Duplicate project" icon
    onDuplicateProject(item) {
      var newProject = Object.assign({}, item)
      newProject.name += ' - COPY'
      api.projects
        .createProject(newProject)
        .then(() => {
          this.loadProjects(false)
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    async onDeleteProject(item) {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the project <code>${item.name}</code>? This action cannot be undone.`
        )
      ) {
        api.projects
          .deleteProject(item.id)
          .then(() => {
            this.loadProjects(false)
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },

    loadProjects(showProgress) {
      //console.log('projects: load')
      if (showProgress) {
        this.projectsLoading = true
      }
      api.projects
        .getProjects()
        .then((response) => {
          this.projectsLoading = false
          this.projects = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.projectsLoading = false
          this.showErrorMessage(error.body.message)
        })
    },

    onProjectTableRowClick(item) {
      this.$router.push({
        name: 'Project',
        params: { projectId: item.id },
      })
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table-header {
  background-color: #f5f5f5;
}
</style>