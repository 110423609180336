<template>
  <div>
    <v-text-field
      :label="label"
      autocomplete="password"
      outlined
      dense
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      @click:append="show = !show"
      ref="textField"
      v-bind:value="value"
      v-on:input="onInput"
      :rules="rules"
    />
  </div>
</template>

<script>
import global from '@/global/index.js'

export default {
  props: {
    value: String,
    label: String,
    enableRules: Boolean
  },

  created() {},

  methods: {
    onInput(value) {
      this.$emit('input', value)
    },

    focus() {
      this.$refs.textField.focus()
    }
  },

  computed: {
    rules() {
      if (this.enableRules) {
        return global.passwordRules
      } else {
        return []
      }
    }
  },

  data() {
    return {
      show: false
    }
  }
}
</script>

<style></style>
