<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="1024" class="mx-auto" elevation="0">
          <v-card-title>
            <span class="text-h4 font-weight-medium">Your License</span>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text-body-1 black--text" v-if="!licenseString">
            <p>
              Please click the button below to generate your license file for
              the token <code>{{ token }}</code
              >.
            </p>
            <v-btn
              @click="onCreateLicense"
              color="#337AB7"
              class="white--text mb-4 mr-2"
              >Create License</v-btn
            >
          </v-card-text>
          <v-card-text class="text-body-1 black--text" v-if="licenseString">
            <p>
              Here is the license file for the token <code>{{ token }}</code
              >:
            </p>
            <v-sheet color="white" elevation="1" class="pa-2 my-4">
              <pre>{{ licenseString }}</pre>
            </v-sheet>
            <p>
              Please copy the text above and save it to a file named
              <code>license.xml</code>
            </p>
            <p>
              In case you have any questions about your license, please contact
              {{ config.support }}.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <LicenseDialog ref="licenseDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import config from '@/json/config.json'
/*
import products from '@/json/products.json'
*/

import LicenseDialog from '@/components/LicenseDialog.vue'
import MessageSnack from '@/components/MessageSnack.vue'
import api from '@/services/ApiService.js'
import global from '@/global/index.js'

const defaultLicense = {
  product: 'CL',
  hardwareId: '',
  description: '',
}

export default {
  components: {
    LicenseDialog,
    MessageSnack,
  },

  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      token: null,
      licenseString: '',
      license: defaultLicense,
      submitEnabled: false,
      message: {
        show: false,
        text: '',
      },
    }
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    validate() {
      if (this.$refs.licenseForm.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    },

    onCreateLicense() {
      this.$refs.licenseDialog
        .open(true)
        .then((result) => {
          api.license
            .createLicenseByToken(this.token, result)
            .then((response) => {
              console.log(response)
              this.licenseString = response
            })
            .catch((error) => {
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {
    this.token = this.$route.query.token
    console.log('token: ' + this.token)
    if (!this.token) {
      this.showErrorMessage('invalid license token')
      this.$router.push({ name: 'Home' })
    } else {
      api.license
        .getLicenseByToken(this.token)
        .then((response) => {
          console.log(response)
          this.licenseString = response
        })
        .catch((error) => {
          this.showErrorMessage(error.body.message)
        })
    }
  },

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {
    config() {
      return config
    },
    global() {
      return global
    },
  },
}
</script>

<style></style>
