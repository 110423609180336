<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">Release Notes</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <div
          v-html="notes"
          class="text-body-1 text--primary release-notes-markdown"
        ></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import strNotes from 'raw-loader!@/md/notes.md'
import pagedown from '@/libs/pagedown/Markdown.Converter.js'

export default {
  /************************************************************************************************/
  props: {},

  created() {
    var converter = new pagedown.Converter()
    this.notes = converter.makeHtml(strNotes)
  },

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      notes: '',
    }
  },

  /************************************************************************************************/
  methods: {
    open() {
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.user)
    },

    validate() {},
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {},
}
</script>

<style>
div.release-notes-markdown p {
  margin-top: 16px;
}
</style>
