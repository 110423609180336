"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _InlineResponse = _interopRequireDefault(require("../model/InlineResponse4002"));
var _PasswordDto = _interopRequireDefault(require("../model/PasswordDto"));
var _PaymentDto = _interopRequireDefault(require("../model/PaymentDto"));
var _UserDto = _interopRequireDefault(require("../model/UserDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Users service.
* @module api/UsersApi
* @version 1.0.0
*/
class UsersApi {
  /**
  * Constructs a new UsersApi. 
  * @alias module:api/UsersApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create new User
   * @param {module:model/UserDto} userDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createUserWithHttpInfo(userDto) {
    let postBody = userDto;
    // verify the required parameter 'userDto' is set
    if (userDto === undefined || userDto === null) {
      throw new Error("Missing the required parameter 'userDto' when calling createUser");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = null;
    return this.apiClient.callApi('/users', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create new User
   * @param {module:model/UserDto} userDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createUser(userDto) {
    return this.createUserWithHttpInfo(userDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete user
   * @param {Number} userId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteUserWithHttpInfo(userId) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling deleteUser");
    }
    let pathParams = {
      'userId': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/users/{userId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete user
   * @param {Number} userId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteUser(userId) {
    return this.deleteUserWithHttpInfo(userId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get the user's payment history
   * @param {Number} userId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PaymentDto>} and HTTP response
   */
  getPaymentsWithHttpInfo(userId) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling getPayments");
    }
    let pathParams = {
      'userId': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_PaymentDto.default];
    return this.apiClient.callApi('/users/{userId}/payments', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get the user's payment history
   * @param {Number} userId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PaymentDto>}
   */
  getPayments(userId) {
    return this.getPaymentsWithHttpInfo(userId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get user
   * @param {Number} userId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserDto} and HTTP response
   */
  getUserWithHttpInfo(userId) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling getUser");
    }
    let pathParams = {
      'userId': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _UserDto.default;
    return this.apiClient.callApi('/users/{userId}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get user
   * @param {Number} userId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserDto}
   */
  getUser(userId) {
    return this.getUserWithHttpInfo(userId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get list of all users
   * @param {Object} opts Optional parameters
   * @param {Number} opts.limit Maximum number of returned items
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/UserDto>} and HTTP response
   */
  getUsersWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'limit': opts['limit']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_UserDto.default];
    return this.apiClient.callApi('/users', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get list of all users
   * @param {Object} opts Optional parameters
   * @param {Number} opts.limit Maximum number of returned items
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/UserDto>}
   */
  getUsers(opts) {
    return this.getUsersWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Change user's password
   * @param {Number} userId 
   * @param {module:model/PasswordDto} passwordDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  savePasswordWithHttpInfo(userId, passwordDto) {
    let postBody = passwordDto;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling savePassword");
    }
    // verify the required parameter 'passwordDto' is set
    if (passwordDto === undefined || passwordDto === null) {
      throw new Error("Missing the required parameter 'passwordDto' when calling savePassword");
    }
    let pathParams = {
      'userId': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/users/{userId}/password', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Change user's password
   * @param {Number} userId 
   * @param {module:model/PasswordDto} passwordDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  savePassword(userId, passwordDto) {
    return this.savePasswordWithHttpInfo(userId, passwordDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update user
   * @param {Number} userId 
   * @param {module:model/UserDto} userDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  saveUserWithHttpInfo(userId, userDto) {
    let postBody = userDto;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling saveUser");
    }
    // verify the required parameter 'userDto' is set
    if (userDto === undefined || userDto === null) {
      throw new Error("Missing the required parameter 'userDto' when calling saveUser");
    }
    let pathParams = {
      'userId': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/users/{userId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update user
   * @param {Number} userId 
   * @param {module:model/UserDto} userDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  saveUser(userId, userDto) {
    return this.saveUserWithHttpInfo(userId, userDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update user's current subscription
   * @param {Number} userId 
   * @param {Object} opts Optional parameters
   * @param {String} opts.action The action to perform (pause, resume, cancel) on the user's subscription
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  updateSubscriptionWithHttpInfo(userId, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling updateSubscription");
    }
    let pathParams = {
      'userId': userId
    };
    let queryParams = {
      'action': opts['action']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/users/{userId}/subscription', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update user's current subscription
   * @param {Number} userId 
   * @param {Object} opts Optional parameters
   * @param {String} opts.action The action to perform (pause, resume, cancel) on the user's subscription
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  updateSubscription(userId, opts) {
    return this.updateSubscriptionWithHttpInfo(userId, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = UsersApi;