<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-breadcrumbs
            :items="breadcrumbs"
            divider="/"
            class="pl-4 py-2 my-4"
            large
          ></v-breadcrumbs>
          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h4 font-weight-medium pl-0">
              <!-- ******************************************************************************* -->
              <!-- Name -->
              <!-- ******************************************************************************* -->
              <div>
                <span class="mr-2">{{ project.name }}</span
                ><span class="text-h5">project</span>
              </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
              <!-- ******************************************************************************* -->
              <!-- Download button -->
              <!-- ******************************************************************************* -->
              <v-btn
                  color="#337AB7"
                  class="white--text mr-4"
                  medium
                  @click="onDownload"
                >
              <v-icon left> mdi-cloud-download </v-icon>
              Download
            </v-btn>
            <!-- ******************************************************************************* -->
            <!-- Settings menu -->
            <!-- ******************************************************************************* -->
            <v-menu offset-y close-on-click v-model="showSettingsMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="elevation-0" v-bind="attrs" v-on="on">
                  <v-icon>mdi-cog</v-icon>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <template v-for="(item, index) in settingsMenuItems">
                    <v-divider
                      v-if="index === 1"
                      :key="100 + index"
                    ></v-divider>
                    <v-list-item
                      :key="index"
                      :value="item"
                      @click.stop="item.action"
                    >
                      <template v-slot:default>
                        <v-list-item-content v-text="item.title">
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-app-bar>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="text-body-1 black--text mt-4">
            <!-- ******************************************************************************* -->
            <!-- Description -->
            <!-- ******************************************************************************* -->
            <v-row>
              <v-col cols="3" class="text-left font-weight-bold"
                >Description</v-col
              >
              <v-col cols="9" class="text-left"
                ><span>{{ project.description || '&ndash;' }}</span>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- ******************************************************************************* -->
          <!-- Register maps -->
          <!-- ******************************************************************************* -->
          <v-card-title class="text-h5 font-weight-medium pb-1">
            Register Maps
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-data-table
            :headers="registerMapTableHeaders"
            :items="project.registerMaps"
            class="elevation-1 mx-4 mt-4 text-body-1 black--text"
            hide-default-footer
            :loading="registerMapsLoading"
            loading-text="Loading..."
            @click:row="onRegisterMapTableRowClick"
            no-data-text="This project does not contain any register maps yet."
            disable-pagination
          >
            <!-- Name column -->
            <template v-slot:item.name="{ item }">
              <span class="font-weight-medium">{{ item.registerMap.name }}</span>
            </template>
            <!-- Description column -->
            <template v-slot:item.description="{ item }">
              {{ shorten(item.registerMap.description || '&ndash;') }}
            </template>
            <!-- Base address column -->
            <template v-slot:item.baseaddr="{ item }">
              <pre>{{ baseaddr(item) }}</pre>
            </template>
            <!-- Actions column -->
            <template v-slot:item.actions="{ item }">
              <v-icon dense class="mr-3" @click.stop="onEditRegisterMap(item)">
                mdi-pencil
              </v-icon>
              <v-icon dense @click.stop="onDeleteRegisterMap(item)">
                mdi-close-circle
              </v-icon>
            </template>
          </v-data-table>
          <v-card-actions class="mt-4">
            <v-spacer></v-spacer>
            <v-tooltip bottom v-model="showAddRegisterMapsTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="onAddRegisterMap"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Add register maps</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirmationDialog" />
    <ProjectDialog ref="projectDialog" />
    <AddRegisterMapDialog ref="addRegisterMapDialog" />
    <ProjectRegisterMapDialog ref="projectRegisterMapDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import axios from 'axios'
import utils from '@/global/utils.js'
import global from '@/global/index.js'
import api from '@/services/ApiService.js'
import settings from '@/json/settings.json'
import MessageSnack from '@/components/MessageSnack.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import ProjectDialog from '@/components/ProjectDialog.vue'
import AddRegisterMapDialog from '@/components/AddRegisterMapDialog.vue'
import ProjectRegisterMapDialog from '@/components/ProjectRegisterMapDialog.vue'

export default {
  /************************************************************************************************/
  components: {
    ConfirmDialog,
    ProjectDialog,
    AddRegisterMapDialog,
    MessageSnack,
    ProjectRegisterMapDialog
  },
  /************************************************************************************************/
  data() {
    return {
      projectId: 0,
      timer: null,
      registerMaps: [],
      reloadTimer: null,
      showSettingsMenu: false,
      registerMapsLoading: false,
      addRegisterMapDialog: true,
      showAddRegisterMapsTooltip: false,
      enableProjectRefresh: true, // enable/disable periodic project refresh

      message: {
        show: false,
        text: '',
      },

      settingsMenuItems: [
        { title: 'Edit Project', action: this.onEditProject },
        { title: 'Delete Project', action: this.deleteProject },
      ],

      project: {
        id: 0,
        name: '',
        description: '',
        registerMaps: [],
      },

      registerMapTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Base Address',
          align: 'start',
          sortable: false,
          value: 'baseaddr',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em', // make sure icons don't wrap
        },
      ],

      breadcrumbs: [
        {
          text: 'Projects',
          disabled: false,
          to: { name: 'Projects' },
        },
        {
          text: 'Project',
          disabled: false,
        },
      ],
    }
  },

  /************************************************************************************************/
  created() {
    this.projectId = this.$route.params.projectId
    this.loadProject()
    this.reloadTimer = setInterval(
      this.autoLoadProject,
      settings.autoReloadPeriodSec * 1000
    )
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************/
  watch: {
    project: function () {
      this.initBreadCrumbs()
    },
  },

  /************************************************************************************************/
  computed: {
    projectDescriptionRules() {
      return global.projectDescriptionRules
    },

    objectNameRules() {
      return global.objectNameRules
    },
  },

  /************************************************************************************************/
  methods: {

    // User clicked the "Edit register map" icon
    onEditRegisterMap(registerMapInstance) {
      this.$refs.projectRegisterMapDialog
      .open(registerMapInstance)
      .then((result) => {
          if (result !== null) {
            const registerMapInstanceDto = {
              id : registerMapInstance.id,
              baseAddress: result.baseAddress
            }
            return api.projects.setRegisterMapInstance(this.projectId, result.id, registerMapInstanceDto)
          }
        })
      .then(() => {
        this.loadProject()
      })
      .catch((error) => {
        if (error.status === 403) {
          this.$store.dispatch('logout')
        }
        this.showErrorMessage(error.body.message)
      })
    },

    // Returns the base address of the given register map instance, formatted as a hex string
    baseaddr(registerMapInstance) {
      let baseAddress
      let isModified
      if (registerMapInstance.baseAddress !== null) {
        isModified = ' (*)'
        baseAddress = registerMapInstance.baseAddress
      } else {
        isModified = ''
        baseAddress = registerMapInstance.registerMap.baseAddress
      }
      let hex = baseAddress.toString(16).toUpperCase().trim()
      hex = "0".repeat(8 - hex.length) + hex
      return "0x" + hex + isModified
    },

    // User clicked the "Download" button
    onDownload() {
      this.project.registerMaps.forEach(registerMapInstance => {
        const registerMap = registerMapInstance.registerMap

        // Use axios to download binary files as OpenAPI Generator client does not seem to work with them
        const token = api.getJWT()
        const basePath = api.getBasePath() // e.g. http://localhost:8082/api
        const config = {
          // Note: when adding application/json, the response.data becomes a string and the 
          // downloaded zip-file is corrupted. So we cannot use:
          // responseType: 'arraybuffer; application/json',
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 10000
        }

        // Optionally add the project-specific base address to the request path
        let path = `${basePath}/registermaps/${registerMap.id}/download?type=all`
        if(registerMapInstance.baseAddress !== null) {
          const baseaddr = registerMapInstance.baseAddress.toString(16)
          path += `&baseaddr=${baseaddr}`
        }

        axios
          .get(
            path,
            config
          )
          .then((response) => {
              // Extract filename from content-disposition header
              var contentDisposition = response.headers['content-disposition']
              // e.g. "attachment; filename=map4_regs_pkg.vhd"
              var pattern = /filename=([a-zA-Z0-9_.]+)/
              var match = pattern.exec(contentDisposition)
              var filename = match[1]
              // Note: blobs are file-like objects of immutable, raw data; they can be read as text or binary data.
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = filename
              link.click()
              URL.revokeObjectURL(link.href)
          })
          .catch((error) => {
            // Possible errors (see: https://stackoverflow.com/a/63743705/63730)
            // 1. Wrong request: something is wrong with the request                             -> error.message
            // 2. Bad network request: server does not respond at all (wrong URL or server down) -> error.request
            // 3. Error status: most common, with any request that does not return 200.          -> error.response.data, status, headers
            // ---
            if(error.response) {
              if(error.response.status === 500) { // 500 means one or more DRC error(s) occurred
                this.showErrorMessage(`the register map "${registerMap.name}" has failed DRCs`)
              } else {
                this.showErrorMessage(error.message)
              }
            } else if (error.request) {
              // Client never received a response, or request never left
              this.showErrorMessage(error.message)
            } else if (error.message) {
              // Bad request on the client side
              this.showErrorMessage(error.message)
            } else {
              // Anything other error
              this.showErrorMessage('download error')
            }
          })
      });
    },

    // Shorten a description string to a maximum number of characters
    shorten(description) {
      return utils.shorten(description, settings.maxDescriptionLength)
    },

    initBreadCrumbs() {
      this.breadcrumbs = [
        {
          text: 'Projects',
          disabled: false,
          to: { name: 'Projects' },
        },
        {
          text: this.project.name,
          disabled: false,
        },
      ]
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    // Save project to the database
    saveProject(project) {
      // Construct a project DTO with only the register maps IDs (instead of the whole register map objects)
      var projectDto = JSON.parse(JSON.stringify(project))
      projectDto.registerMaps = []
      for(var i = 0; i < project.registerMaps.length; i++) {
        const baseAddress = project.registerMaps[i].baseAddress
        var registerMapInstance = { baseAddress }
        const id = project.registerMaps[i].registerMap.id
        registerMapInstance.registerMap = { id }
        projectDto.registerMaps.push(registerMapInstance)
      }

      // Persist the updated project to the database
      api.projects
        .saveProject(project.id, projectDto)
        .then(() => {
          this.loadProject()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    hideAddRegisterMapsTooltip() {
      setTimeout(() => {
        this.showAddRegisterMapsTooltip = false
      }, 0)
    },

    // Clicked the "Add register map" button
    onAddRegisterMap() {
      // Get a list of all available register maps
      var allRegisterMaps = []
      api.registerMaps
        .getRegisterMaps()
        .then((response) => {
          allRegisterMaps = response
          // Remove the register maps that are already included in the project from
          // the list of register maps to show in the selection list
          for (var i = 0; i < this.project.registerMaps.length; i++) {
            for (var j = 0; j < allRegisterMaps.length; j++) {
              if (this.project.registerMaps[i].id === allRegisterMaps[j].id) {
                allRegisterMaps.splice(j, 1)
                break
              }
            }
          }
          // Show the "Add register map dialog"
          return this.$refs.addRegisterMapDialog.open(this.project.registerMaps, allRegisterMaps)
        })
        .then((selectedRegisterMaps) => {
          this.hideAddRegisterMapsTooltip()
          if (selectedRegisterMaps !== null) {
            var registerMapInstances = []
            selectedRegisterMaps.forEach((registerMap) => {
              const registerMapInstance = {
                baseAddress: null,
                registerMap: registerMap
              }
              registerMapInstances.push(registerMapInstance)
            })
            // Save the additional register map instances to the database
            return api.projects.addRegisterMapInstances(this.projectId, registerMapInstances)
          }
        })
        .then(() => {
          this.loadProject()
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked settings -> edit project
    onEditProject() {
      this.$refs.projectDialog
        .open(this.project)
        .then((result) => {
          api.projects
            .saveProject(this.projectId, result)
            .then(() => {
              this.loadProject()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    onRegisterMapTableRowClick(item) {
      this.$router.push({
        name: 'RegisterMap',
        params: { registerMapId: item.registerMap.id },
      })
    },

    // Clicked the "delete register map" icon
    onDeleteRegisterMap(item) {
      this.enableProjectRefresh = false
      // Immediately delete the selected register map instance from the local copy of the project
      const index = this.project.registerMaps.indexOf(item)
      if(index > -1) {
        this.project.registerMaps.splice(index, 1)
      }
      // Persist the change to the database
      api.projects
      .deleteRegisterMapInstance(this.projectId, item.id)
      .then(() => {
          this.loadProject()
        })
      .catch((error) => {
        if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
      })
      .finally(() => {
        this.enableProjectRefresh = true
      })
    },

    // Reload project (called by periodic timer)
    autoLoadProject() {
      if(this.enableProjectRefresh) {
        this.loadProject(false)
      }
    },

    compareRegisterMapInstances(a, b) {
      if ( a.registerMap.name < b.registerMap.name ){
        return -1;
      }
      if ( a.registerMap.name > b.registerMap.name ){
        return 1;
      }
      return 0;
    },

    // Load project from backend
    //  * The fetched project does not contain the list of registers, as those are not needed 
    //    in the context of the project.
    loadProject() {
      console.log("loadProject")
      api.projects
        .getProject(this.projectId)
        .then((response) => {
          // Sort register maps by name
          response.registerMaps.sort(this.compareRegisterMapInstances)
          this.project = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          } else if (error.status === 400) {
            // access denied
            this.$router.push({ name: 'Dashboard' })
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked the "delete project" menu item
    async deleteProject() {
      this.showSettingsMenu = false
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the project <code>${this.project.name}</code>? This action cannot be undone.`
        )
      ) {
        api.projects
          .deleteProject(this.project.id)
          .then(() => {
            this.$router.push({ name: 'Projects' })
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },
  },
}
</script>

<style>
.col {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>

<style scoped>
::v-deep .v-data-table-header {
  background-color: #f5f5f5;
}
</style>