<template>
  <v-container fluid fill-height>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4 mt-4 mx-auto" max-width="480">
          <v-sheet color="#337AB7" elevation="0" height="5" tile></v-sheet>
          <v-card-title class="text-h4 font-weight-medium">
            Login
          </v-card-title>
          <v-form ref="form" @submit.prevent="login" v-model="form.isValid">
            <v-card-text class="text-body-1">
              <v-text-field
                v-model="form.user.username"
                prepend-icon="mdi-account-circle"
                label="Email"
                autocomplete="username"
                ref="emailInput"
                :rules="emailRules"
                type="email"
              />
              <v-text-field
                v-model="form.user.password"
                prepend-icon="mdi-lock"
                :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="form.showPassword ? 'text' : 'password'"
                label="Password"
                @click:append="form.showPassword = !form.showPassword"
                autocomplete="current-password"
              />
              <p class="mt-0 text-right">
                <router-link :to="{ name: 'ForgotPassword' }"
                  >I forgot my password</router-link
                >
              </p>
            </v-card-text>
            <v-card-text v-if="retryPeriod > 0"
              ><span class="red--text"
                >Wrong password. Please wait for {{ retryPeriod }} sec before
                retrying.</span
              ></v-card-text
            >
            <v-card-actions>
              <v-btn
                class="white--text ml-2"
                type="submit"
                color="#337AB7"
                :loading="form.loading"
                large
                :disabled="!form.isValid || retryPeriod > 0"
                >Login</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-card-text class="text-body-1">
            <p>
              Need an account?
              <router-link :to="{ name: 'Signup' }">Sign up</router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <MessageSnack v-model="message.show" :text="message.text" />
  </v-container>
</template>

<script>
import global from '@/global/index.js'
import MessageSnack from '@/components/MessageSnack.vue'

export default {
  components: {
    MessageSnack,
  },

  data() {
    return {
      retryPeriod: 0, // retry delay in case of wrong password

      message: {
        show: false,
        text: '',
      },

      form: {
        loading: false,
        showPassword: false,
        disabled: true,
        isValid: false,
        user: {
          username: '',
          password: '',
        },
      },
    }
  },
  
  computed: {
    emailRules() {
      return global.emailRules
    },
  },
  methods: {
    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    onRetryPeriodTimerExpired() {
      this.retryPeriod = this.retryPeriod - 1
      if (this.retryPeriod > 0) {
        setTimeout(this.onRetryPeriodTimerExpired, 1000)
      }
    },

    // Clicked the "Login" button
    login() {
      this.form.loading = true
      //console.log('login: route params')
      //console.log(this.$route.params)
      this.$store
        .dispatch('login', this.form.user)
        .then(() => {
          this.form.loading = false
          if ('to' in this.$route.params) {
            this.$router.push({
              name: this.$route.params.to.name,
              params: this.$route.params.to.params,
            })
          } else {
            this.$router.push({ name: 'Dashboard' })
          }
        })
        .catch((error) => {
          // Save the password retry period in case of wrong password
          if (error.body && 'retryPeriod' in error.body) {
            this.retryPeriod = error.body.retryPeriod
            setTimeout(this.onRetryPeriodTimerExpired, 1000)
          }
          this.form.loading = false
          this.showErrorMessage(error.body.message)
        })
    },
  },
}
</script>
