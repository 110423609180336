<template>
  <v-snackbar :value="value" @input="onInput" timeout="10000">
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="onClick">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: Boolean,
    text: String
  },
  methods: {
    onClick() {
      this.$emit('input', false)
    },
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style></style>
