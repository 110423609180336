import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from 'jwt-decode'
import plans from '@/json/plans.json'
import api from '@/services/ApiService.js'

Vue.use(Vuex)

export default new Vuex.Store({
  /************************************************************************************************
   * The vuex state
   ************************************************************************************************/

  state: {
    userId: null,
    user: null,
    userRoles: [],
    features: [],
    token: '',
    backendReachable: false
  },

  /************************************************************************************************
   * Mutations (update the state)
   ************************************************************************************************/

  mutations: {
    SET_USER_DATA(state, data) {
      let user = data.user
      let token = data.token
      state.user = user
      let decodedToken = jwt_decode(token)
      state.userId = parseInt(decodedToken.sub)
      state.userRoles = decodedToken.roles
      state.features = decodedToken.features
      state.token = token
      api.setJWT(token) // set JWT in API client
    },

    SET_USER(state, user) {
      state.user = user
    },

    CLEAR_USER_DATA() {
      api.setJWT(null)
      location.reload() // reloads the current URL, like the Refresh button
    },

    SET_BACKEND_REACHABLE(state, backendReachable) {
      state.backendReachable = backendReachable
    }
  },

  /************************************************************************************************
   * Actions (call mutations)
   * - the context object contains all properties of the vuex store
   * - best practice: actions -> call mutations -> update the state
   ************************************************************************************************/

  actions: {
    setBackendReachable(context, backendReachable) {
      context.commit('SET_BACKEND_REACHABLE', backendReachable)
    },

    login(context, credentials) {
      // loginUser returns an object with properties: { user, token }
      return api.auth.loginUser(credentials).then(response => {
        context.commit('SET_USER_DATA', response)
      })
    },

    logout(context) {
      console.log('store: logout')
      context.commit('CLEAR_USER_DATA')
      /*
      api.auth.logoutUser().then(() => {
        // Note: logoutUser() clears the refresh token cookie
        context.commit('CLEAR_USER_DATA')
      })*/
    },
    
    // Reload the currently logged in user information
    // @note called periodically from App.vue to refresh the user information
    reloadUser(context) {
      console.log('store: reloadUser')
      return api.users
        .getUser(context.state.user.id)
        .then(response => {
          context.commit('SET_USER', response)
        })
        .catch(error => {
          if (error.status === 403 || error.status == 404) {
            context.dispatch('logout')
          }
        })
    }
  },

  /************************************************************************************************
   * Getters (like computed properties for state)
   ************************************************************************************************/

  getters: {
    isBackendReachable: state => {
      return state.backendReachable
    },

    // True if the user is logged in
    isLoggedIn: state => {
      return state.user != null
    },

    // True if the user is an administrator
    isAdmin: state => {
      return state.userRoles.includes('ADMIN')
    },

    username: state => {
      if (state.user == null) {
        return null
      }
      return state.user.username
    },

    user: state => {
      return state.user
    },

    features: state => {
      return state.features
    },

    /**
     * Returns true if the user has an active/trialing subscription
     *
     * @note used for displaying the "Your subscription has expired" alert
     */
    subscriptionActive: state => {
      if (state.user == null || state.user.plan == null) {
        return false
      }
      if (state.user.plan !== 'BASIC' && !state.user.subscriptionExpired) {
        return true
      }
      return false
    },

    /**
     * Returns the user's masked subscription plan
     * - Possible values: BASIC, PROFESSIONAL, PROFESSIONAL_CL
     * - Returns BASIC in case of expired professional plan
     */
    subscriptionPlanMasked: state => {
      if (state.user == null || state.user.plan == null) {
        return null
      }
      let plan = state.user.plan
      if (plan !== 'BASIC' && state.user.subscriptionExpired) {
        return 'BASIC'
      }
      return state.user.plan
    },

    /**
     * Returns the user's unmasked subscription plan
     * - Possible values: BASIC, PROFESSIONAL, PROFESSIONAL_CL
     */
    subscriptionPlanUnmasked: state => {
      if (state.user == null || state.user.plan == null) {
        return null
      }
      return state.user.plan
    },

    // Returns true if the user's (unmasked) plan is a professional one
    isProfessionalPlan: state => {
      if (state.user == null || state.user.plan == null) {
        return false
      }
      return state.user.plan !== 'BASIC'
    },

    planExpirationDate: state => {
      if (state.user == null || state.user.planExpirationDate == null) {
        return null
      }
      return state.user.planExpirationDate
    },

    // Returns the maximum number of allowed register maps for this user
    maxRegisterMaps: (state, getters) => {
      let userPlan = getters.subscriptionPlanMasked
      if (userPlan == null) {
        return 0
      }
      let plan = plans.find(p => p.internalName === userPlan)
      return plan.features.maxRegisterMaps
    },

    allowXmlDownload: (state, getters) => {
      let userPlan = getters.subscriptionPlanMasked
      if (userPlan == null) {
        return false
      }
      let plan = plans.find(p => p.internalName === userPlan)
      return plan.features.allowXmlDownload
    },

    allowTestbenchDownload: (state, getters) => {
      let userPlan = getters.subscriptionPlanMasked
      if (userPlan == null) {
        return false
      }
      let plan = plans.find(p => p.internalName === userPlan)
      return plan.features.allowTestbenchDownload
    },

    allowProjects: (state, getters) => {
      let userPlan = getters.subscriptionPlanMasked
      if (userPlan == null) {
        return false
      }
      let plan = plans.find(p => p.internalName === userPlan)
      return plan.features.allowProjects
    },

    allowJsonUpload: (state, getters) => {
      let userPlan = getters.subscriptionPlanMasked
      if (userPlan == null) {
        return false
      }
      let plan = plans.find(p => p.internalName === userPlan)
      return plan.features.allowJsonUpload
    },

    allowCommandLineGenerator: (state, getters) => {
      let userPlan = getters.subscriptionPlanMasked
      if (userPlan == null) {
        return false
      }
      let plan = plans.find(p => p.internalName === userPlan)
      return plan.features.allowCommandLineGenerator
    },

    allowShareRegisterMaps: (state, getters) => {
      let userPlan = getters.subscriptionPlanMasked
      if (userPlan == null) {
        return false
      }
      let plan = plans.find(p => p.internalName === userPlan)
      return plan.features.allowShareRegisterMaps
    }
  }
})
