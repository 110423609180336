<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ isNewPermissionDialog ? 'New' : 'Edit' }} Permission</span
        >
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            autocomplete="off"
            ref="fieldUserName"
            v-model="permission.userName"
            label="User's e-mail address"
            :rules="emailRules"
            outlined
            dense
            class="mb-4"
            @input="validate"
            :filled="!isNewPermissionDialog"
            :readonly="!isNewPermissionDialog"
          />
          <v-select
            v-model="permission.permission"
            :items="permissions"
            item-text="text"
            item-value="value"
            label="Permission"
            outlined
            dense
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel">
            Cancel
          </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text"
            type="submit"
            :disabled="!submitEnabled"
          >
            {{ isNewPermissionDialog ? 'Create Permission' : 'Save Permission' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      isNewPermissionDialog: true,
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,

      permissions: [
        { text: 'read-only', value: 'READ' },
        { text: 'read-write', value: 'READ_WRITE' }
      ],

      defaultPermission: {
        userName: '',
        permission: 'READ_WRITE'
      },

      permission: Object.assign({}, this.defaultPermission)
    }
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    open(permission) {
      if (permission == null) {
        this.isNewPermissionDialog = true
        this.permission = Object.assign({}, this.defaultPermission)
        this.submitEnabled = false
      } else {
        this.isNewPermissionDialog = false
        this.permission = Object.assign({}, permission)
        this.submitEnabled = true // assume that the permission data is valid and enable the "Save register" button
      }
      this.dialog = true

      setTimeout(() => {
        this.$refs.form.resetValidation()
        this.$refs.fieldUserName.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.permission)
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    }
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {
    emailRules() {
      return global.emailRules
    }
  }
}
</script>

<style></style>
