<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="1250" class="mx-auto" elevation="0">
          <v-card-title class="text-h4 font-weight-medium">
            Contact
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="body-1 text--primary">
            <span v-html="contact"></span>
          </v-card-text>
          <v-card-title class="text-h4 font-weight-medium">
            Legal Notice
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text class="body-1 text--primary">
            <span v-html="imprint"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import strContact from 'raw-loader!@/md/contact.md'
import strImprint from 'raw-loader!@/md/imprint.md'
import pagedown from '@/libs/pagedown/Markdown.Converter.js'

export default {
  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {
      contact: '',
      imprint: ''
    }
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {
    var converter = new pagedown.Converter()
    this.contact = converter.makeHtml(strContact)
    this.imprint = converter.makeHtml(strImprint)
  }
}
</script>

<style></style>

