<!-- Edit / Create field dialog -->
<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ isNewFieldDialog ? 'New' : 'Edit' }} Field</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-tabs v-model="tab">
        <v-tab>Main</v-tab>
        <v-tab>Enumerated Values</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- Main tab -->
        <v-tab-item>
          <v-card>
            <v-card-text class="mt-4">
              <v-form ref="fieldForm">
                <v-text-field autocomplete="off" ref="fieldNameField" v-model="field.name" label="Name" :rules="objectNameRules" outlined
                  dense class="mb-2" @input="validate" :hint="fieldNameHint" />
                <v-textarea v-model="field.description" label="Description" value="" outlined dense class="mb-2"
                  :rules="fieldDescriptionRules" counter @input="validate" persistent-hint
                  hint="Markdown syntax allowed"></v-textarea>
                <v-text-field autocomplete="off" v-model="field.bitOffset" label="Bit offset" :rules="fieldBitOffsetRules" persistent-hint
                  outlined dense class="mb-2" type="number" @input="validate" />
                <v-text-field autocomplete="off" v-model="field.bitWidth" label="Bit width" :rules="fieldBitWidthRules" persistent-hint
                  outlined dense class="mb-2" type="number" @input="validate" />
                <v-text-field autocomplete="off" v-model="strReset" label="Reset value" :rules="fieldResetValueRules" persistent-hint
                  outlined prefix="0x" dense class="mb-0" @input="validate" />
                <v-checkbox v-model="field.selfClear" v-if="
                  parent && parent.type !== 'Memory' && (parent.access === 'READ_WRITE' ||
                    parent.access === 'WRITE_ONLY')
                " label="Self-clearing" dense hide-details class="mt-0"></v-checkbox>
                <v-checkbox v-if="
                  parent && parent.type !== 'Memory' && parent.access === 'READ_WRITE' && features.includes('volatile-fields')
                " v-model="field.volatile" label="Volatile (separate read and write ports)" dense hide-details
                  class="mt-0"></v-checkbox> </v-form></v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Enumerated Values tab -->
        <v-tab-item>
          <v-card>
            <v-card-text class="mt-4 pb-0">
              <v-form ref="enumValueForm" @submit.prevent="submit">
                <v-row>
                  <v-col cols="6">
                    <v-text-field autocomplete="off" ref="fieldEnumValueName" v-model="enumValueName" label="Name" :rules="objectNameRules"
                      outlined dense class="mb-4" @input="validateEnumValueForm" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field autocomplete="off" ref="fieldEnumValueValue" v-model="enumValueValue" label="Value"
                      :rules="enumValueRules" outlined dense class="mb-4" @input="validateEnumValueForm" />
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click="onAddEnumValue" color="#337AB7" class="white--text mr-4"
                      :disabled="!addEnumValueEnabled">Add</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-text class="pt-0">
              <v-data-table :headers="enumValueTableHeaders" :items="field.enumValues"
                class="elevation-1 mx-0 mt-0 text-body-1 black--text" hide-default-footer disable-pagination>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click.stop="onDeleteEnumValue(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" class="mb-4"> Cancel </v-btn>
        <v-btn color="#337AB7" class="white--text mb-4" @click="submit" :disabled="!submitEnabled">
          {{ isNewFieldDialog ? 'Create Field' : 'Save Field' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'
import { mapGetters } from 'vuex'

const defaultField = {
  name: '',
}

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      strReset: 0,
      field: defaultField,
      reject: null,
      dialog: false,
      resolve: null,
      enumValueName: '',
      enumValueValue: '',
      submitEnabled: false,
      isNewFieldDialog: true,
      addEnumValueEnabled: false,
      tab: null,
      registerWidthBits: 0,
      parent: null, // the parent register

      defaultField: {
        name: '',
        description: '',
        bitWidth: 1,
        bitOffset: 0,
        reset: 0,
        selfClear: false,
        enumValues: [],
        volatile: false,
      },

      enumValueTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Value',
          align: 'start',
          sortable: false,
          value: 'value',
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
    }
  },

  /************************************************************************************************/
  created() { },

  /************************************************************************************************/
  methods: {
    // Init the properties and show the dialog
    open(defaultBitOffset, field, parent, registerWidthBits = 32) {
      this.enumValueName = ''
      this.enumValueValue = ''
      this.addEnumValueEnabled = false
      this.tab = null
      this.registerWidthBits = registerWidthBits
      this.parent = parent

      if (field == null) {
        this.isNewFieldDialog = true
        this.strReset = 0x0
        this.field = Object.assign({}, this.defaultField)
        this.field.bitOffset = defaultBitOffset
        this.submitEnabled = false
      } else {
        this.isNewFieldDialog = false
        this.strReset = field.reset.toString(16)
        this.field = JSON.parse(JSON.stringify(field)) // deep copy, including enum values
        this.submitEnabled = true
      }

      this.dialog = true

      // Reset the validation and focus the first text field
      setTimeout(() => {
        this.$refs.fieldForm.resetValidation()
        if (this.$refs.enumValueForm) {
          this.$refs.enumValueForm.resetValidation()
        }
        this.$refs.fieldNameField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.field.reset = parseInt(this.strReset, 16)
      this.dialog = false
      this.resolve(this.field)
    },

    validate() {
      if (this.$refs.fieldForm.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    },

    validateEnumValueForm() {
      console.log('validateEnumValueForm')
      if (this.$refs.enumValueForm.validate()) {
        this.addEnumValueEnabled = true
      } else {
        this.addEnumValueEnabled = false
      }
    },

    // Clicked the "Add enum value" button
    onAddEnumValue() {
      var enumValue = {
        name: this.enumValueName,
        value: global.intFromString(this.enumValueValue),
      }
      this.field.enumValues.push(enumValue)
      this.enumValueName = ''
      this.enumValueValue = ''
      this.$refs.enumValueForm.resetValidation()
    },

    // Clicked the "Delete enum value" icon
    onDeleteEnumValue(item) {
      const idx = this.field.enumValues.indexOf(item)
      if (idx >= 0) {
        this.field.enumValues.splice(idx, 1)
      }
    },
  },

  /************************************************************************************************/
  watch: {
    strReset(newValue) {
      this.field.reset = parseInt(newValue, 16)
    },
  },

  /************************************************************************************************/
  computed: {

    ...mapGetters([
      'features',
    ]),

    fieldNameHint() {
      if (this.isNewFieldDialog) {
        return 'Must be a valid identifier (e.g. done), with an optional replication range (e.g. done[0:7]).'
      } else {
        return 'Must be a valid identifier'
      }
    },

    objectNameRules() {
      if (this.isNewFieldDialog) {
        return global.newFieldNameRules
      } else {
        return global.objectNameRules
      }
    },

    enumValueRules() {
      return global.enumValueRules
    },

    fieldDescriptionRules() {
      return global.fieldDescriptionRules
    },

    fieldBitOffsetRules() {
      return global.fieldBitOffsetRules
    },

    fieldBitWidthRules() {
      return global.fieldBitWidthRules
    },

    // Field reset value: must be a hex number in range [0, 2**bitWidth-1]
    fieldResetValueRules() {
      var maxValue = 2 ** this.field.bitWidth - 1
      return [
        (v) => global.isHexNumber(v) || 'Must be a hexadecimal number',
        (v) =>
          (parseInt(v, 16) >= 0 && parseInt(v, 16) <= maxValue) ||
          'Must be in range [0x0, 0x' +
          maxValue.toString(16).toUpperCase() +
          ']',
      ]
    },
  },
}
</script>

<style>

</style>
