<template>
  <v-container fluid>
    <v-alert
      dense
      type="info"
      dismissible
      outlined
      v-if="ownRegisterMapsCount >= maxRegisterMaps"
      class="mx-4"
    >
      You have reached the maximum number of register maps for the
      {{ subscriptionPlanMasked }} plan. Please upgrade your subscription to
      create additional register maps.
    </v-alert>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto" elevation="0">
          <v-app-bar flat color="white">
            <v-toolbar-title class="text-h4 font-weight-medium pl-0">
              Register Maps
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              autocomplete="off"
              class="mr-4 shrink"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  medium
                  v-bind="attrs"
                  v-on="on"
                  @click="onUpload"
                  :disabled="!(allowNewRegisterMap && allowJsonUpload)"
                  ><v-icon left> mdi-cloud-upload </v-icon>Upload</v-btn
                >
              </template>
              <span>Upload a new register map</span>
            </v-tooltip>
          </v-app-bar>
          <v-divider class="mx-4 mb-4"></v-divider>
          <v-data-table
            :headers="registerMapTableHeaders"
            :items="registerMaps"
            :search="search"
            class="elevation-1 mx-4 mt-4 text-body-1 black--text"
            hide-default-footer
            :loading="registersMapsLoading"
            loading-text="Loading..."
            @click:row="onRegisterMapTableRowClick"
            no-data-text="You haven't created any register maps yet."
            show-select
            v-model="selectedRegisterMaps"
            disable-pagination
          >
            <!-- name column -->
            <template v-slot:item.name="{ item }">
              <span class="font-weight-medium">{{ item.name }}</span>
              <template v-if="isSharedRegisterMap(item)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="ml-4">
                      mdi-account-multiple-outline
                    </v-icon>
                  </template>
                  <span>{{ sharingMessage(item) }}</span>
                </v-tooltip>
              </template>
              <!--
              <v-chip class="ml-4" color="#f5f5f5" small v-show="showIcons"
                >rev. {{ item.revision }}</v-chip
              >
              -->
            </template>

            <template v-slot:item.description="{ item }">
              {{ shorten(item.description) }}
            </template>

            <!--
            <template v-slot:item.description="{ item }">
              <div class="text-truncate" style="max-width: 30em">
                {{ item.description }}
              </div>
            </template>
            -->

            <!-- owner column -->
            <template v-slot:item.owner="{ item }">
              {{ formatOwner(item) }}
            </template>

            <!-- lastModified column -->
            <template v-slot:item.lastModified="{ item }">
              <span v-html="formatDate(item.lastModified)"></span>
            </template>

            <!-- Actions column -->
            <template v-slot:item.actions="{ item }">
              <v-icon
                dense
                class="mr-4"
                @click.stop="onEditRegisterMap(item)"
                :disabled="isReadOnly(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                dense
                class="mr-4"
                @click.stop="onDuplicateRegisterMap(item)"
                :disabled="!allowNewRegisterMap"
              >
                mdi-content-copy
              </v-icon>
              <v-icon dense @click.stop="onDeleteRegisterMap(item)">
                {{ isOwnRegisterMap(item) ? 'mdi-delete' : 'mdi-close-circle' }}
              </v-icon>
            </template>
          </v-data-table>

          <v-card-actions class="mt-4 mb-10 mx-2">
            <v-btn
              small
              v-show="selectedRegisterMaps.length > 0"
              @click="onDeleteSelectedRegisterMaps"
              >Delete selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-tooltip v-model="showNewRegisterMapTooltip" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#337AB7"
                  dark
                  fab
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="onNewRegisterMap"
                  :disabled="!allowNewRegisterMap"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Create new Register Map</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDialog ref="confirmationDialog" />
    <RegisterMapDialog ref="registerMapDialog" />
    <MessageSnack v-model="message.show" :text="message.text" />
    <UploadDialog ref="uploadDialog" />
  </v-container>
</template>

<script>
import api from '@/services/ApiService.js'
import global from '@/global/index.js'
import utils from '@/global/utils.js'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import RegisterMapDialog from '@/components/RegisterMapDialog.vue'
import MessageSnack from '@/components/MessageSnack.vue'
import UploadDialog from '@/components/UploadDialog.vue'
import settings from '@/json/settings.json'
import { mapGetters } from 'vuex'

export default {
  /************************************************************************************************/
  components: {
    ConfirmDialog,
    RegisterMapDialog,
    MessageSnack,
    UploadDialog,
  },
  /************************************************************************************************/
  data() {
    return {
      search: '',
      registerMaps: [],
      reloadTimer: null,
      registersMapsLoading: false,
      selectedRegisterMaps: [],
      showNewRegisterMapTooltip: false,

      registerMapTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '15em'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '40%',
        },
        {
          text: 'Owned by',
          align: 'start',
          sortable: false,
          value: 'owner',
        },
        {
          text: 'Last modified',
          align: 'start',
          sortable: false,
          value: 'lastModified',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '8em', // make sure icons don't wrap
        },
      ],

      message: {
        show: false,
        text: '',
      },

      defaultRegisterMap: {
        name: '',
        description: '',
        width: 32,
        baseAddress: 0,
        revision: 0,
        generateRecordPorts: false,
      },

      form: {
        disabled: true,
        registerMap: Object.assign({}, this.defaultRegisterMap),
      },
    }
  },
  /************************************************************************************************/
  created() {
    this.loadRegisterMaps(true)
    this.reloadTimer = setInterval(
      this.loadRegisterMaps,
      settings.autoReloadPeriodSec * 1000
    )
  },

  beforeDestroy() {
    clearInterval(this.reloadTimer)
  },

  /************************************************************************************************/
  methods: {
    shorten(description) {
      return utils.shorten(description, settings.maxDescriptionLength)
    },

    isSharedRegisterMap(registerMap) {
      return global.isSharedRegisterMap(registerMap)
    },

    sharingMessage(registerMap) {
      return global.sharingMessage(registerMap)
    },

    deleteRegisterMap(registerMap) {
      return api.registerMaps
        .deleteRegisterMap(registerMap.id)
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    async onDeleteSelectedRegisterMaps() {
      if (
        await this.$refs.confirmationDialog.open(
          'Confirm',
          `Are you sure you want to delete the selected register map(s)? This action cannot be undone.`
        )
      ) {
        let results = []
        for (var i = 0; i < this.selectedRegisterMaps.length; i++) {
          results.push(this.deleteRegisterMap(this.selectedRegisterMaps[i]))
        }
        Promise.all(results)
          .then(() => {
            this.loadRegisterMaps(false)
          })
          .catch((error) => {
            this.showErrorMessage(error.body.message)
          })
        this.selectedRegisterMaps = []
      }
    },

    showErrorMessage(message) {
      this.message.text = 'Error: ' + message
      this.message.show = true
    },

    // Clicked "upload" button
    onUpload() {
      this.$refs.uploadDialog
        .open()
        .then((file) => {
          var opts = {
            body: file,
          }
          api.registerMaps
            .uploadRegisterMap(opts)
            .then(() => {
              this.loadRegisterMaps(false)
            })
            // upload error
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        // dialog closed with 'Cancel'
        .catch(() => {})
    },

    isReadOnly(registerMap) {
      return registerMap.permission === 'READ'
    },

    // Return 'me' in case the register map is owned by the logged in user, or the e-mail address or
    // the owner's user name in case the register map is owned by someone else.
    formatOwner(registerMap) {
      if (this.isOwnRegisterMap(registerMap)) {
        return 'me'
      }
      return registerMap.owner
    },

    // Returns true if the register map is owned by the logged in user
    isOwnRegisterMap(registerMap) {
      var ownerUserName = registerMap.owner.toLowerCase()
      var myUserName = this.$store.state.user.username.toLowerCase()
      return ownerUserName === myUserName
    },

    onRegisterMapTableRowClick(item) {
      this.$router.push({
        name: 'RegisterMap',
        params: { registerMapId: item.id },
      })
    },

    formatDate(timestamp) {
      if (timestamp == null) {
        return '&ndash;'
      }
      var d = new Date(timestamp)
      var months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
    },

    loadRegisterMaps(showProgress) {
      //console.log('registermaps: load')
      if (showProgress) {
        this.registersMapsLoading = true
      }
      api.registerMaps
        .getRegisterMaps()
        .then((response) => {
          this.registersMapsLoading = false
          this.registerMaps = response
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.registersMapsLoading = false
          this.showErrorMessage(error.body.message)
        })
    },

    hideNewRegisterMapTooltip() {
      setTimeout(() => {
        this.showNewRegisterMapTooltip = false
      }, 0)
    },

    // Clicked the "New register map" dialog
    onNewRegisterMap() {
      this.$refs.registerMapDialog
        .open(null)
        .then((result) => {
          this.hideNewRegisterMapTooltip()
          api.registerMaps
            .createRegisterMap(result)
            .then(() => {
              this.loadRegisterMaps(false)
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
          this.hideNewRegisterMapTooltip()
        })
    },

    // Clicked the "edit register map" icon
    onEditRegisterMap(item) {
      this.$refs.registerMapDialog
        .open(item)
        .then((registerMap) => {
          // Transmit only the properties that can be modified in the dialog
          // NOTE: update onEditRegisterMap in RegisterMap.vue if changing
          // anything in the DTO.
          var registerMapDto = {
            id: registerMap.id,
            name: registerMap.name,
            description: registerMap.description,
            baseAddress: registerMap.baseAddress,
            addrWidthBits: registerMap.addrWidthBits,
            generateRecordPorts: registerMap.generateRecordPorts,
          }
          api.registerMaps
            .saveRegisterMap(item.id, registerMapDto)
            .then(() => {
              this.loadRegisterMaps()
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch(() => {
          /* Cancel */
        })
    },

    // Clicked the "Duplicate register map icon"
    onDuplicateRegisterMap(item) {
      // Get the full register map object (including registers and fields)
      var opts = { includeRegisters: true, includeFields: true }
      api.registerMaps
        .getRegisterMap(item.id, opts)
        .then((response) => {
          var registerMapDto = response
          registerMapDto.name += ' - COPY'
          registerMapDto.permissions = [] // don't take over the permissions of the source register map
          api.registerMaps
            .createRegisterMap(registerMapDto)
            .then(() => {
              this.loadRegisterMaps(false)
            })
            .catch((error) => {
              if (error.status === 403) {
                this.$store.dispatch('logout')
              }
              this.showErrorMessage(error.body.message)
            })
        })
        .catch((error) => {
          if (error.status === 403) {
            this.$store.dispatch('logout')
          }
          this.showErrorMessage(error.body.message)
        })
    },

    // Clicked on the "Delete register map" icon
    async onDeleteRegisterMap(item) {
      console.log('onDeleteRegisteMap')
      var message
      if (this.isOwnRegisterMap(item)) {
        message = `Are you sure you want to delete the register map <code>${item.name}</code>? This action cannot be undone.`
      } else {
        message =
          'Are you sure you want to remove access to this shared register map? This action cannot be undone.'
      }
      if (await this.$refs.confirmationDialog.open('Confirm', message)) {
        api.registerMaps
          .deleteRegisterMap(item.id)
          .then(() => {
            this.loadRegisterMaps(false)
          })
          .catch((error) => {
            if (error.status === 403) {
              this.$store.dispatch('logout')
            }
            this.showErrorMessage(error.body.message)
          })
      }
    },
  },
  /************************************************************************************************/
  computed: {
    ...mapGetters([
      'maxRegisterMaps',
      'subscriptionPlanMasked',
      'allowJsonUpload',
    ]),

    // Whether or not to show the register map, project and LFSR icons in the dashboard tables
    showIcons() {
      return this.$vuetify.breakpoint.width >= settings.showIconsBreakpoint;
    },

    objectNameRules() {
      return global.objectNameRules
    },

    baseAddressRules() {
      return global.baseAddressRules
    },

    registerMapDescriptionRules() {
      return global.registerMapDescriptionRules
    },

    allowNewRegisterMap() {
      return this.ownRegisterMapsCount < this.maxRegisterMaps
    },

    ownRegisterMapsCount() {
      var result = 0
      for (var i = 0; i < this.registerMaps.length; i++) {
        var registerMap = this.registerMaps[i]
        if (registerMap.permission === 'ADMIN') {
          result += 1
        }
      }
      return result
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table-header {
  background-color: #f5f5f5;
}
</style>
