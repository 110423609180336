"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PasswordTokenDto model module.
 * @module model/PasswordTokenDto
 * @version 1.0.0
 */
class PasswordTokenDto {
  /**
   * Constructs a new <code>PasswordTokenDto</code>.
   * @alias module:model/PasswordTokenDto
   */
  constructor() {
    PasswordTokenDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PasswordTokenDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PasswordTokenDto} obj Optional instance to populate.
   * @return {module:model/PasswordTokenDto} The populated <code>PasswordTokenDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PasswordTokenDto();
      if (data.hasOwnProperty('token')) {
        obj['token'] = _ApiClient.default.convertToType(data['token'], 'String');
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = _ApiClient.default.convertToType(data['password'], 'String');
      }
    }
    return obj;
  }
}

/**
 * @member {String} token
 */
PasswordTokenDto.prototype['token'] = undefined;

/**
 * @member {String} password
 */
PasswordTokenDto.prototype['password'] = undefined;
var _default = PasswordTokenDto;
exports.default = _default;