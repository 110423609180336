import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import NotFound from '../views/NotFound.vue'
import ConfirmRegistration from '../views/ConfirmRegistration.vue'
import Dashboard from '../views/Dashboard.vue'
import VerificationLinkSent from '../views/VerificationLinkSent.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import PasswordResetCompleted from '../views/PasswordResetCompleted.vue'
import PasswordResetLinkSent from '../views/PasswordResetLinkSent.vue'
import Account from '../views/Account.vue'
import RegisterMaps from '../views/RegisterMaps.vue'
import LFSRs from '../views/LFSRs.vue'
import Lfsr from '../views/Lfsr.vue'
import RegisterMap from '../views/RegisterMap.vue'
import Register from '../views/Register.vue'
import Project from '../views/Project.vue'
import Projects from '../views/Projects.vue'
import Admin from '../views/Admin.vue'
import Generator from '../views/Generator.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import Contact from '../views/Contact.vue'
import Credits from '../views/Credits.vue'
import Plans from '../views/Plans.vue'
import Products from '../views/Products.vue'
import License from '../views/License.vue'
import Downloads from '../views/Downloads.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
      title: 'Web-Based AXI4 VHDL/SystemVerilog Register Generator'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      requiresAuth: false,
      title: 'Sign up'
    }
  },
  {
    path: '/confirmRegistration',
    name: 'ConfirmRegistration',
    component: ConfirmRegistration,
    meta: {
      requiresAuth: false,
      title: 'Confirm registration'
    }
  },
  {
    path: '/verificationLinkSent',
    name: 'VerificationLinkSent',
    component: VerificationLinkSent,
    meta: {
      requiresAuth: false,
      title: 'Verification link sent'
    }
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
      title: 'Forgot password'
    }
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      title: 'Reset password'
    }
  },
  {
    path: '/passwordResetCompleted',
    name: 'PasswordResetCompleted',
    component: PasswordResetCompleted,
    meta: {
      requiresAuth: false,
      title: 'Reset password completed'
    }
  },
  {
    path: '/passwordResetLinkSent',
    name: 'PasswordResetLinkSent',
    component: PasswordResetLinkSent,
    meta: {
      requiresAuth: false,
      title: 'Reset password link sent'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      requiresAuth: false,
      title: 'Terms and conditions'
    }
  },
  {
    path: '/credits',
    name: 'Credits',
    component: Credits,
    meta: {
      requiresAuth: false,
      title: 'Credits'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      requiresAuth: false,
      title: 'Privacy Policy'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: false,
      title: 'Contact'
    }
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Plans,
    meta: {
      requiresAuth: false,
      title: 'Plans'
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      requiresAuth: false,
      title: 'Products'
    }
  },
  {
    path: '/license',
    name: 'License',
    component: License,
    meta: {
      requiresAuth: false,
      title: 'License'
    }
  },
  {
    path: '/download',
    name: 'Downloads',
    component: Downloads,
    meta: {
      requiresAuth: false,
      title: 'Downloads'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: 'Dashboard'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
      title: 'Account'
    }
  },
  {
    path: '/registerMaps',
    name: 'RegisterMaps',
    component: RegisterMaps,
    meta: {
      requiresAuth: true,
      title: 'Register maps'
    }
  },
  {
    path: '/lfsrs',
    name: 'LFSRs',
    component: LFSRs,
    meta: {
      requiresAuth: true,
      title: 'LFSRs'
    }
  },
  {
    path: '/registerMap/:registerMapId',
    name: 'RegisterMap',
    component: RegisterMap,
    meta: {
      requiresAuth: true,
      title: 'Register map'
    }
  },
  {
    path: '/lfsr/:lfsrId',
    name: 'Lfsr',
    component: Lfsr,
    meta: {
      requiresAuth: true,
      title: 'Linear-feedback shift register'
    }
  },  
  {
    path: '/project/:projectId',
    name: 'Project',
    component: Project,
    meta: {
      requiresAuth: true,
      title: 'Project'
    }
  },
  {
    path: '/registerMap/:registerMapId/register/:registerId',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: true,
      title: 'Register'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      requiresAuth: true,
      title: 'Projects'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      title: 'Admin'
    }
  },
  {
    path: '/generator',
    name: 'Generator',
    component: Generator,
    meta: {
      requiresAuth: true,
      title: 'Command line generator'
    }
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn
  const isAdmin = store.getters.isAdmin

  //console.log('Router: router.beforeEach')
  //console.log('Router: isLoggedIn: ' + isLoggedIn)
  //console.log('Router: isAdmin: ' + isAdmin)

  // Redirect protected routes to '/' when user is not logged in
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      console.log('router: user not logged in, directing to /login')
      // console.log(to)
      next({ name: 'Login', params: { to: to } })
    }
  }

  // Redirect '/admin' to '/' when user is not an admin
  if (to.matched.some(record => record.name === 'Admin')) {
    if (!isAdmin) {
      next('/dashboard')
    }
  }

  // Redirect '/' to '/dashboard' when user is logged in
  if (to.matched.some(record => record.name === 'Home')) {
    if (isLoggedIn) {
      console.log('router: redirecting to dashboard...')
      next('/dashboard')
    }
  }

  // console.log('Router: next route: ' + to.name)
  next()
})

router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | airhdl'
  }
})

export default router
