<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >Edit Register Map Instance</span
        >
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <!-- ******************************************************************************* -->
          <!-- Base address -->
          <!-- ******************************************************************************* -->
          <v-text-field
            autocomplete="off"
            ref="baseAddressField"
            label="Base address"
            v-model="strBaseAddress"
            :rules="baseAddressRules"
            outlined
            dense
            prefix="0x"
            class="mb-0 mx-2"
            :disabled="!useProjectSpecificBaseAddress"
            @input="validate"
          />
          <!-- ******************************************************************************* -->
          <!-- Checkbox -->
          <!-- ******************************************************************************* -->
          <v-checkbox label="Use project-specific base address" v-model="useProjectSpecificBaseAddress" dense hide-details class="ml-1 mt-0"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-4">
            Cancel
          </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mr-4 mb-4"
            type="submit"
            :disabled="!submitEnabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,
      useProjectSpecificBaseAddress: false,
      strBaseAddress: "",
      registerMapInstance: null
    }
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    open(registerMapInstance) {
        // Init form fields
        let baseAddress
        if(registerMapInstance.baseAddress !== null) {
            this.useProjectSpecificBaseAddress = true
            baseAddress = registerMapInstance.baseAddress
        } else {
            this.useProjectSpecificBaseAddress = false
            baseAddress = registerMapInstance.registerMap.baseAddress
        }
        const strBaseAddress = baseAddress.toString(16).toUpperCase()
        this.strBaseAddress = "0".repeat(8 - strBaseAddress.length) + strBaseAddress

        this.registerMapInstance = Object.assign({}, registerMapInstance)
        this.submitEnabled = true
        this.dialog = true

        // Run he form validation, set the focus on the base address field
        setTimeout(() => {
            this.validate() // force base address validation, so that the user immediately sees an error message in case of an invalid base address
            this.$refs.baseAddressField.focus()
        }, 0)

        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    },

    cancel() {
      this.dialog = false
      this.resolve(null)
    },

    submit() {
        this.dialog = false
        if(this.useProjectSpecificBaseAddress) {
            this.registerMapInstance.baseAddress = parseInt(this.strBaseAddress, 16)
        } else {
            this.registerMapInstance.baseAddress = null
        }
        this.resolve(this.registerMapInstance)
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    }
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {
    baseAddressRules() {
      let rules = global.baseAddressRules.slice()
      if(this.registerMapInstance) {
        const addrWidthBits = this.registerMapInstance.registerMap.addrWidthBits
        const maxValue = 2**addrWidthBits
        rules.push(value => 
          parseInt(value, 16) < maxValue || `The default base address must fit within the default address width range (${addrWidthBits} bits)`
        )
      }
      return rules
    }
  }
}
</script>

<style></style>
