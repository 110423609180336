<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card max-width="1024" class="mx-auto" elevation="0">
          <v-card-title>
            <span class="text-h4 font-weight-medium">Products</span>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>
            <span class="text-h5 font-weight-medium pb-1"
              >Command Line Generator</span
            >
          </v-card-title>
          <v-card-text class="text-body-1 black--text">
            <p>
              The command line generator, <code>airhdl-cl</code> is the
              field-proven and battle-tested code generator behind
              <a href="https://airhdl.com">airhdl.com</a>, which you can license
              as a standalone product. Given a register map specification in
              JSON format, <code>airhdl-cl</code> generates the same output
              files as the server version locally on your machine or build
              server.
            </p>
            <v-img
              alt="airhdl-cl"
              src="@/assets/airhdl-cl.jpg"
              max-width="800"
              contain
            />
            <p class="text-h6">Features</p>
            <ul>
              <li>Input is a JSON register map specification.</li>
              <li>
                Generates all the output file types available on
                <a href="https://airhdl.com">airhdl.com</a>.
              </li>
              <li>
                Platform independent (works on Microsoft Windows, OS X and Linux
                systems).
              </li>
              <li>
                Available with node-locked or floating (uses FlexNet Publisher) licenses.
              </li>
              <li>Does not require an internet connection.</li>
            </ul>
            <p class="text-h6 mt-4">System Requirements</p>
            <ul>
              <li>Java runtime version 8 or higher.</li>
            </ul>
            <p class="text-h6 mt-4">Pricing</p>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Node-locked, time-based license</td>
                    <td>US $990 per year</td>
                    <td>
                      <v-btn
                        color="#337AB7"
                        class="white--text"
                        min-width="13em"
                        @click="onOrderNodeLockedGenerator"
                        >Order Now</v-btn
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>Floating, time-based license</td>
                    <td>US $3490 per year</td>
                    <td>
                      <v-btn
                        color="#337AB7"
                        class="white--text"
                        min-width="13em"
                        href="mailto:sales@airhdl.com"
                        >Contact Sales</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from '@/json/config.json'
import products from '@/json/products.json'

export default {
  components: {},

  /************************************************************************************************
   * Data
   ************************************************************************************************/
  data() {
    return {}
  },

  /************************************************************************************************
   * Methods
   ************************************************************************************************/
  methods: {
    onOrderNodeLockedGenerator() {
      let product = products.find((p) => p.name === 'airhdl-cl')
      let productId = product.productId[config.profile] // 'dev' or 'prod'
      console.log('products: using product ID ' + productId)

      // See: https://developer.paddle.com/reference/paddle-js/parameters
      window.Paddle.Checkout.open({
        product: productId,
        locale: 'en',
        marketingConsent: '0',
        allowQuantity: false,
        disableLogout: true,
      })
    },
  },

  /************************************************************************************************
   * Created hook
   ************************************************************************************************/
  created() {},

  /************************************************************************************************
   * Computed properties
   ************************************************************************************************/
  computed: {},
}
</script>

<style></style>
