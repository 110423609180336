"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PasswordDto model module.
 * @module model/PasswordDto
 * @version 1.0.0
 */
class PasswordDto {
  /**
   * Constructs a new <code>PasswordDto</code>.
   * @alias module:model/PasswordDto
   */
  constructor() {
    PasswordDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PasswordDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PasswordDto} obj Optional instance to populate.
   * @return {module:model/PasswordDto} The populated <code>PasswordDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PasswordDto();
      if (data.hasOwnProperty('oldPassword')) {
        obj['oldPassword'] = _ApiClient.default.convertToType(data['oldPassword'], 'String');
      }
      if (data.hasOwnProperty('newPassword')) {
        obj['newPassword'] = _ApiClient.default.convertToType(data['newPassword'], 'String');
      }
    }
    return obj;
  }
}

/**
 * @member {String} oldPassword
 */
PasswordDto.prototype['oldPassword'] = undefined;

/**
 * @member {String} newPassword
 */
PasswordDto.prototype['newPassword'] = undefined;
var _default = PasswordDto;
exports.default = _default;