"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UserCredentialsDto model module.
 * @module model/UserCredentialsDto
 * @version 1.0.0
 */
class UserCredentialsDto {
  /**
   * Constructs a new <code>UserCredentialsDto</code>.
   * @alias module:model/UserCredentialsDto
   */
  constructor() {
    UserCredentialsDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UserCredentialsDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserCredentialsDto} obj Optional instance to populate.
   * @return {module:model/UserCredentialsDto} The populated <code>UserCredentialsDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserCredentialsDto();
      if (data.hasOwnProperty('username')) {
        obj['username'] = _ApiClient.default.convertToType(data['username'], 'String');
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = _ApiClient.default.convertToType(data['password'], 'String');
      }
      if (data.hasOwnProperty('recaptcha')) {
        obj['recaptcha'] = _ApiClient.default.convertToType(data['recaptcha'], 'String');
      }
      if (data.hasOwnProperty('termsAgreedVersion')) {
        obj['termsAgreedVersion'] = _ApiClient.default.convertToType(data['termsAgreedVersion'], 'Number');
      }
    }
    return obj;
  }
}

/**
 * @member {String} username
 */
UserCredentialsDto.prototype['username'] = undefined;

/**
 * @member {String} password
 */
UserCredentialsDto.prototype['password'] = undefined;

/**
 * @member {String} recaptcha
 */
UserCredentialsDto.prototype['recaptcha'] = undefined;

/**
 * @member {Number} termsAgreedVersion
 */
UserCredentialsDto.prototype['termsAgreedVersion'] = undefined;
var _default = UserCredentialsDto;
exports.default = _default;