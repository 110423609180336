<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Transfer Ownership</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit" v-model="form.isValid">
        <v-card-text>
          <p class="ml-2">
            Transfer ownership of register map
            <code>{{ registerMap ? registerMap.name : '' }}</code> to another
            airhdl user:
          </p>
          <v-text-field
            v-model="username"
            prepend-icon="mdi-account-circle"
            label="Email"
            autocomplete="username"
            ref="usernameInput"
            :rules="emailRules"
            type="email"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-2"> Cancel </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mb-2 mr-2"
            type="submit"
            :disabled="!form.isValid"
          >
            Transfer Ownership
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,
      username: '',
      registerMap: null,
      form: {
        isValid: false,
      },
    }
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    open(registerMap) {
      this.submitEnabled = false
      this.dialog = true
      this.registerMap = registerMap
      this.username = ''

      setTimeout(() => {
        this.$refs.usernameInput.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.username)
    },
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/

  computed: {
    emailRules() {
      return global.emailRules
    },
  },
}
</script>

<style></style>
