<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Upload Register Map</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <p class="ml-2">Please select a file to upload:</p>
          <v-file-input
            ref="fileInput"
            v-model="fileInputValue"
            label="File input"
            accept="application/json"
            truncate-length="256"
            show-size
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> Cancel </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text"
            type="submit"
            :disabled="!submitEnabled"
          >
            Upload register map
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,
      fileInputValue: null,
    }
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    open() {
      this.submitEnabled = false
      this.fileInputValue = null
      this.dialog = true

      setTimeout(() => {
        this.$refs.fileInput.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.fileInputValue)
    },
  },

  /************************************************************************************************/
  watch: {
    fileInputValue() {
      if (this.fileInputValue != null) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    },
  },

  /************************************************************************************************/
  computed: {},
}
</script>

<style></style>
