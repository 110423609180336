<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ isNewProjectDialog ? 'New' : 'Edit' }} Project</span
        >
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider class="mb-4"></v-divider>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            autocomplete="off"
            ref="projectNameField"
            v-model="project.name"
            label="Name"
            :rules="objectNameRules"
            outlined
            dense
            class="mb-4 mx-2"
            @input="validate"
          />
          <v-textarea
            v-model="project.description"
            label="Description"
            value=""
            outlined
            dense
            class="mb-4 mx-2"
            :rules="projectDescriptionRules"
            counter
            @input="validate"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel" class="mb-4">
            Cancel
          </v-btn>
          <v-btn
            color="#337AB7"
            class="white--text mr-4 mb-4"
            type="submit"
            :disabled="!submitEnabled"
          >
            {{ isNewProjectDialog ? 'Create Project' : 'Save Project' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import global from '@/global/index.js'

export default {
  /************************************************************************************************/
  props: {},

  /************************************************************************************************/
  data() {
    return {
      isNewProjectDialog: true,
      dialog: false,
      submitEnabled: false,
      resolve: null,
      reject: null,

      defaultProject: {
        name: '',
        description: '',
        registerMaps: []
      },

      project: Object.assign({}, this.defaultProject)
    }
  },

  /************************************************************************************************/
  methods: {
    // Open the dialog
    //  - if project is not null, show the "Edit Project" dialog
    //  - otherwise show the "New Project" dialog
    open(project) {
      if (project == null) {
        this.isNewProjectDialog = true
        this.project = Object.assign({}, this.defaultProject)
        this.submitEnabled = false
      } else {
        this.isNewProjectDialog = false
        this.project = Object.assign({}, project)
        this.submitEnabled = true // assume that the register data is valid and enable the "Save register" button
      }
      this.dialog = true

      setTimeout(() => {
        this.$refs.form.resetValidation()
        this.$refs.projectNameField.focus()
      }, 0)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    cancel() {
      this.dialog = false
      this.reject()
    },

    submit() {
      this.dialog = false
      this.resolve(this.project)
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.submitEnabled = true
      } else {
        this.submitEnabled = false
      }
    }
  },

  /************************************************************************************************/
  watch: {},

  /************************************************************************************************/
  computed: {
    objectNameRules() {
      return global.objectNameRules
    },

    projectDescriptionRules() {
      return global.projectDescriptionRules
    },

    baseAddressRules() {
      return global.baseAddressRules
    }
  }
}
</script>

<style></style>
