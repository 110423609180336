const airhdl = require('airhdl')
const client = airhdl.ApiClient.instance
const RegistermapsApi = new airhdl.RegistermapsApi()
const ProjectsApi = new airhdl.ProjectsApi()
const UsersApi = new airhdl.UsersApi()
const AuthApi = new airhdl.AuthApi()
const GeneratorApi = new airhdl.GeneratorApi()
const AdminApi = new airhdl.AdminApi()
const ActuatorApi = new airhdl.ActuatorApi()
const LicenseApi = new airhdl.LicenseApi()
const MessageApi = new airhdl.MessageApi()
const MessageDto = new airhdl.MessageDto()
const LFSRsApi = new airhdl.LFSRsApi()

// Remove the "User-Agent" header to prevent the 'Refused to set unsafe header "User-Agent"'
// error message in the browser.
client.defaultHeaders = {}

export default {
  setJWT(token) {
    // console.log('ApiService.setJWT: ' + token)
    var BearerAuth = client.authentications['BearerAuth']
    BearerAuth.accessToken = token
  },

  getJWT() {
    var BearerAuth = client.authentications['BearerAuth']
    return BearerAuth.accessToken
  },

  setBasePath(path) {
    client.basePath = path
  },

  getBasePath() {
    return client.basePath
  },

  registerMaps: RegistermapsApi,
  projects: ProjectsApi,
  users: UsersApi,
  auth: AuthApi,
  generator: GeneratorApi,
  admin: AdminApi,
  actuator: ActuatorApi,
  license: LicenseApi,
  message: MessageApi,
  lfsrs: LFSRsApi,
  MessageDto

}
