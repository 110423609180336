
// Shorten a string to a given maximum length
function shorten(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 1) + '\u2026'
  }
  return text
}

/************************************************************************************************
 * Exported properties
 ************************************************************************************************/

export default {
  shorten
}
