"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _LfsrDto = _interopRequireDefault(require("../model/LfsrDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* LFSRs service.
* @module api/LFSRsApi
* @version 1.0.0
*/
class LFSRsApi {
  /**
  * Constructs a new LFSRsApi. 
  * @alias module:api/LFSRsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Create a new LFSR
   * @param {module:model/LfsrDto} lfsrDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  createLfsrWithHttpInfo(lfsrDto) {
    let postBody = lfsrDto;
    // verify the required parameter 'lfsrDto' is set
    if (lfsrDto === undefined || lfsrDto === null) {
      throw new Error("Missing the required parameter 'lfsrDto' when calling createLfsr");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/lfsrs', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Create a new LFSR
   * @param {module:model/LfsrDto} lfsrDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  createLfsr(lfsrDto) {
    return this.createLfsrWithHttpInfo(lfsrDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Delete an LFSR
   * @param {Number} lfsrId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  deleteLfsrWithHttpInfo(lfsrId) {
    let postBody = null;
    // verify the required parameter 'lfsrId' is set
    if (lfsrId === undefined || lfsrId === null) {
      throw new Error("Missing the required parameter 'lfsrId' when calling deleteLfsr");
    }
    let pathParams = {
      'lfsrId': lfsrId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/lfsrs/{lfsrId}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Delete an LFSR
   * @param {Number} lfsrId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  deleteLfsr(lfsrId) {
    return this.deleteLfsrWithHttpInfo(lfsrId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Run the code generator for an LFSR
   * @param {Number} lfsrId 
   * @param {Object} opts Optional parameters
   * @param {String} opts.type the type of the output file to generate (pythonModel)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  downloadLfsrWithHttpInfo(lfsrId, opts) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'lfsrId' is set
    if (lfsrId === undefined || lfsrId === null) {
      throw new Error("Missing the required parameter 'lfsrId' when calling downloadLfsr");
    }
    let pathParams = {
      'lfsrId': lfsrId
    };
    let queryParams = {
      'type': opts['type']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/octet-stream'];
    let returnType = File;
    return this.apiClient.callApi('/lfsrs/{lfsrId}/download', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Run the code generator for an LFSR
   * @param {Number} lfsrId 
   * @param {Object} opts Optional parameters
   * @param {String} opts.type the type of the output file to generate (pythonModel)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  downloadLfsr(lfsrId, opts) {
    return this.downloadLfsrWithHttpInfo(lfsrId, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get an LFSR
   * @param {Number} lfsrId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/LfsrDto} and HTTP response
   */
  getLfsrWithHttpInfo(lfsrId) {
    let postBody = null;
    // verify the required parameter 'lfsrId' is set
    if (lfsrId === undefined || lfsrId === null) {
      throw new Error("Missing the required parameter 'lfsrId' when calling getLfsr");
    }
    let pathParams = {
      'lfsrId': lfsrId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = _LfsrDto.default;
    return this.apiClient.callApi('/lfsrs/{lfsrId}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get an LFSR
   * @param {Number} lfsrId 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/LfsrDto}
   */
  getLfsr(lfsrId) {
    return this.getLfsrWithHttpInfo(lfsrId).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get all LFSRs
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/LfsrDto>} and HTTP response
   */
  getLfsrsWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = [];
    let accepts = ['application/json; charset=utf-8'];
    let returnType = [_LfsrDto.default];
    return this.apiClient.callApi('/lfsrs', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get all LFSRs
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/LfsrDto>}
   */
  getLfsrs() {
    return this.getLfsrsWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Update an LFSR
   * @param {Number} lfsrId 
   * @param {module:model/LfsrDto} lfsrDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  saveLfsrWithHttpInfo(lfsrId, lfsrDto) {
    let postBody = lfsrDto;
    // verify the required parameter 'lfsrId' is set
    if (lfsrId === undefined || lfsrId === null) {
      throw new Error("Missing the required parameter 'lfsrId' when calling saveLfsr");
    }
    // verify the required parameter 'lfsrDto' is set
    if (lfsrDto === undefined || lfsrDto === null) {
      throw new Error("Missing the required parameter 'lfsrDto' when calling saveLfsr");
    }
    let pathParams = {
      'lfsrId': lfsrId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['BearerAuth'];
    let contentTypes = ['application/json; charset=utf-8'];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/lfsrs/{lfsrId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Update an LFSR
   * @param {Number} lfsrId 
   * @param {module:model/LfsrDto} lfsrDto 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  saveLfsr(lfsrId, lfsrDto) {
    return this.saveLfsrWithHttpInfo(lfsrId, lfsrDto).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = LFSRsApi;