"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _EnumValueDto = _interopRequireDefault(require("./EnumValueDto"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * airhdl
 * Web-based VHDL/SystemVerilog register generator
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@airhdl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The FieldDto model module.
 * @module model/FieldDto
 * @version 1.0.0
 */
class FieldDto {
  /**
   * Constructs a new <code>FieldDto</code>.
   * @alias module:model/FieldDto
   */
  constructor() {
    FieldDto.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>FieldDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FieldDto} obj Optional instance to populate.
   * @return {module:model/FieldDto} The populated <code>FieldDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FieldDto();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('bitWidth')) {
        obj['bitWidth'] = _ApiClient.default.convertToType(data['bitWidth'], 'Number');
      }
      if (data.hasOwnProperty('bitOffset')) {
        obj['bitOffset'] = _ApiClient.default.convertToType(data['bitOffset'], 'Number');
      }
      if (data.hasOwnProperty('reset')) {
        obj['reset'] = _ApiClient.default.convertToType(data['reset'], 'Number');
      }
      if (data.hasOwnProperty('selfClear')) {
        obj['selfClear'] = _ApiClient.default.convertToType(data['selfClear'], 'Boolean');
      }
      if (data.hasOwnProperty('enumValues')) {
        obj['enumValues'] = _ApiClient.default.convertToType(data['enumValues'], [_EnumValueDto.default]);
      }
      if (data.hasOwnProperty('volatile')) {
        obj['volatile'] = _ApiClient.default.convertToType(data['volatile'], 'Boolean');
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
FieldDto.prototype['id'] = undefined;

/**
 * @member {String} name
 */
FieldDto.prototype['name'] = undefined;

/**
 * @member {String} description
 */
FieldDto.prototype['description'] = undefined;

/**
 * @member {Number} bitWidth
 */
FieldDto.prototype['bitWidth'] = undefined;

/**
 * @member {Number} bitOffset
 */
FieldDto.prototype['bitOffset'] = undefined;

/**
 * @member {Number} reset
 */
FieldDto.prototype['reset'] = undefined;

/**
 * @member {Boolean} selfClear
 */
FieldDto.prototype['selfClear'] = undefined;

/**
 * @member {Array.<module:model/EnumValueDto>} enumValues
 */
FieldDto.prototype['enumValues'] = undefined;

/**
 * @member {Boolean} volatile
 */
FieldDto.prototype['volatile'] = undefined;
var _default = FieldDto;
exports.default = _default;